import React, { Component } from "react";
import { Row } from "design-react-kit";
import { MInput } from "components/forms";

class ValidatedForm extends Component {
  innerValidatedForms = [];
  onChangeEvents = {};
  emptyFields = {};
  validation = {};
  errors = {};

  componentDidMount() {
    this.loadRemote({ formActive: false });
    if (this.props.onValidatedForm) {
      this.props.onValidatedForm(this);
    }
  }

  loadRemote(additionalState = {}) {}

  saveRemote(additionalState = {}) {}

  resetForm = () => {
    const emptyValues = this.emptyFields;
    let value = null;

    for (const field in emptyValues) {
      // get default value
      value = emptyValues[field];
      this.setState({ [field]: value });
    }
  };

  checkValidation = () => {
    let result = true;
    const defaultValues = this.state.defaultValues;
    for (const field in defaultValues) {
      if (!this.validation[field]) {
        continue;
      }

      let isValid = this.checkValidationField(field);

      if (!isValid) {            
        result = false;
      }
    }

    // validate and submit inner validatedForm component
    this.innerValidatedForms.forEach((component) => {
      let isValid = component.checkValidation();
      if (!isValid) {
        console.log(component, "is not valid"); 
        result = false;
      }
    });

    return result;
  };

  getValueField = (field) => {
    let value = null;
    // if the user changed default value
    if (this.state[field] !== null && this.state[field] instanceof Object) {
      value = this.state[field].value;
    } else {
      // get default value
      value = this.state.defaultValues[field];
    }

    return value;
  };

  checkValidationField = (field) => {
    let value = null;
    // if the user changed default value
    if (this.state[field] !== null && this.state[field] instanceof Object) {      
      value = this.state[field].value;
    } else {
      // get default value
      value = this.state.defaultValues[field];
    }

    value = this.validateField(field, value);
    this.setState({ [field]: value });
    return value.isValid;
  };

  validateField(field, value) {
    const isValid = this.validation[field](value, this.state);

    return {
      value,
      isValid,
      message: this.ERROR_MESSAGES[field],
    };
  }

  toggleForm = () => {
    // validates defaults values if pass form inactive to active
    if (!this.state.formActive) {
      this.checkValidation();
      // validate and submit inner validatedForm component
      this.innerValidatedForms.forEach((component) => {
        component.checkValidation();
      });
    }

    // change state
    this.setState({ formActive: !this.state.formActive });
  };

  onChange = (field, value) => {
    // if it hasn't validator move from default to object value
    if (!this.validation[field]) {
      value = { value: value, isValid: true };
      this.setState({ [field]: value });
    } else {
      // validate field and move from default to object value
      value = this.validateField(field, value);
      this.setState({ [field]: value });
    }

    if (this.onChangeEvents[field]) {
      this.onChangeEvents[field](field, value.value);
    }
  };

  onSubmit = () => {
    let isValid = this.checkValidation();

    if (isValid) {
      // validate and submit inner validatedForm component
      this.innerValidatedForms.forEach((component) => {
        if (!component.onSubmit()) {
          isValid = false;
        }
      });
    }

    // if all components are valid submit this
    if (isValid) {
      this.saveRemote();
    }
    return isValid;
  };

  onCancel = () => {
    const additionalState = {
      ...this.emptyFields,
      ...this.errors,
      formActive: false,
    };

    this.loadRemote(additionalState);
  };

  isInnerValidatedForm = (component) => {
    this.innerValidatedForms.push(component);
  };

  resetFields = () => {
    this.innerValidatedForms = [];
  };

  renderFields = (
    fields_group,
    defaultValues,
    formActive,
    ignoreNew = false
  ) => {
    let formValid = true;
    let isNew = true;

    const code = fields_group.map((rowFields, i) => {
      const row = rowFields.map((field, j) => {
        let addAttr = {};
        const name = field.field;
        const id = field.id ? field.id : name;

        if (this.state[name] instanceof Object) {
          isNew = false;
        }

        const valid =
          this.state[name] instanceof Object && this.state[name].isValid
            ? "Valid"
            : "";
        const invalid =
          this.state[name] instanceof Object && !this.state[name].isValid
            ? "Invalid"
            : "";
        let value =
          this.state[name] instanceof Object
            ? this.state[name].value
            : defaultValues[name];
        const infoText = invalid ? this.state[name].message : field.infoText;      
        const classElement = field.classElement ? field.classElement : "";

        if (valid && formActive) addAttr.valid = "true";
        if (invalid && formActive) {
          addAttr.invalid = "true";
          formValid = false;
        }

        if (field.onChange) {
          this.onChangeEvents[name] = field.onChange;
        }

        addAttr.autoComplete = field.autoComplete;

        //--- FIX get value from value attribute field ---
        if (field.value !== "" && typeof value === "undefined") {
          value = field.value;
        }

        const Component = field.component || <MInput />;

        return (
          <div className={field.className} key={`field-${j}`}>
            <Component
              onValidatedForm={(component) =>
                this.isInnerValidatedForm(component)
              }
              id={id}
              name={name}
              label={field.label}
              type={field.type}
              {...addAttr}
              config={field.config}
              payload={field.payload}
              infoText={infoText}
              onChange={this.onChange}
              otherEvents={field.otherEvents}
              readOnly={!formActive || field.readOnly}
              disabled={!formActive || field.readOnly}
              value={value}
              rows={field.rows}
              classElement={classElement}
              onClick={field.onClick || ""}
              onKeyUp={field.onKeyUp || ""}
              notes={field.notes}
            />
          </div>
        );
      });

      return <Row key={`row-${i}`}> {row}</Row>;
    });

    // disable "submit" button in two cases:
    //  1. the form is invalid
    //  2.not yet any user interaction
    if (!ignoreNew) formValid = formValid && !isNew;
    return { formValid, code };
  };
}

export { ValidatedForm };
