import React, { Component } from "react";
import {
  BoxInfo,
  ErrorNotification,
  MnemoLoading,
  MSection,
} from "components/misc";
import { Button, Container, Row, Col, Icon } from "design-react-kit";
import { MSelect } from "components/forms";
import { courseService, dateHelper, networkErrorHelper } from "libs";
import { enrollmentEvaluationService } from "libs/api/enrollmentEvalutaionService";

export class Analysis60CFU extends Component {
  state = {
    loading: false,
    courseCompetitionClass: [],
    payloadCurrentCC: [],
    payloadCurrentRegion: [],
    currentList: [],

    currentCompany: "",
    currentCC: "",
    currentRegion: "",
  };

  optionsPayload = {
    company: [{ label: "filtro sull'ente", value: "" }],
    competitionClass: [{ label: "filtro sulla classe di concorso", value: "" }],
    region: [{ label: "filtro sulla regione", value: "" }],
  };

  searchField = [
    {
      field: "company",
      name: "company",
      label: "Ente",
      payload: {
        options: [
          { label: "filtro sull'ente", value: "" },
          { label: "Unicamillus", value: "unicamillus" },
          { label: "IUL", value: "iul" },
          { label: "Reggio", value: "reggio" },
        ],
      },
      component: MSelect,
      onChange: (_, value) => this.onChangeCompany(_, value),
    },
    {
      field: "competitionClass",
      name: "competitionClass",
      label: "Classe di Concorso",
      component: MSelect,
      onChange: (_, value) => this.onChangeCompetitionClass(_, value),
    },
    {
      field: "region",
      name: "region",
      label: "Regione",
      component: MSelect,
      onChange: (_, value) => this.onChangeRegion(_, value),
    },
  ];

  loadRemote = async () => {
    this.setState({ loading: true });
    courseService
      .listByCompetitionClass("ABILITAZIONI60CFU_BANDO")
      .then(({ data }) => {
        this.setState({
          loading: false,
          courseCompetitionClass: data.payload.courseCompetitionClass,
        });
      })
      .catch((error) => {
        console.log(error);
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }
      });
  };

  componentDidMount() {
    this.loadRemote();
  }

  onChangeCompany = (_, value) => {
    const { courseCompetitionClass } = this.state;
    const payloadCC = [];
    payloadCC.push({
      label: "filtro sulla classe di concorso",
      value: "",
    });

    // add dinstict competition class  of current company from courseCompetitionClass
    courseCompetitionClass.forEach((element) => {
      if (
        payloadCC.findIndex((x) => x.value === element.competitionClass) === -1
      ) {
        payloadCC.push({
          label: element.competitionClass + " - " + element.note,
          value: element.competitionClass,
        });
      }
    });

    this.setState({
      payloadCurrentCC: payloadCC,
      currentCompany: value,
      currentList: [],
    });
  };

  onChangeCompetitionClass = (_, value) => {
    const { courseCompetitionClass, currentCompany } = this.state;
    const payloadRegion = [];
    payloadRegion.push({
      label: "filtro sulla regione",
      value: "",
    });

    // add dinstict region of current competitionClass and currentcompany from courseCompetitionClass
    courseCompetitionClass.forEach((element) => {
      if (
        element.competitionClass === value &&
        element.company === currentCompany
      ) {
        if (payloadRegion.findIndex((x) => x.value === element.region) === -1) {
          payloadRegion.push({
            label: element.region,
            value: element.region,
          });
        }
      }
    });

    this.setState({
      payloadCurrentRegion: payloadRegion,
      currentCC: value,
      currentList: [],
    });
  };

  onChangeRegion = (_, value) => {
    this.setState({ currentRegion: value, currentList: [] });
  };

  onSearchForm = () => {
    this.loadStudents();
  };

  loadStudents = () => {
    this.setState({ loading: true, loadingError: "" });
    const { currentCC, currentCompany, currentRegion } = this.state;
    if (currentCompany && currentCC && currentRegion) {
      enrollmentEvaluationService
        .evaluationList(currentCompany, currentCC, currentRegion)
        .then(({ data }) => {
          const { enrollmentEvaluations } = data.payload;
          this.setState({ currentList: enrollmentEvaluations, loading: false });
        })
        .catch((error) => {
          console.log(error);
          if (!networkErrorHelper.is404(error)) {
            networkErrorHelper.notify(error);
          }

          this.setState({ loading: false });
        });
    } else {
      ErrorNotification.render(
        "Devi selezionare un ente per poter proseguire "
      );
    }
  };

  analyzeStudents = (e) => {
    e.preventDefault();
    this.setState({ loading: true, loadingError: "" });
    const { currentCC, currentCompany, currentRegion, currentList } =
      this.state;
    if (currentCompany && currentCC && currentRegion) {
      if (currentList.length === 0) {
        ErrorNotification.render("Nessuna domanda da analizzare");
        this.setState({ loading: false });
      } else {
        enrollmentEvaluationService
          .analyzeEvaluation(currentCompany, currentCC, currentRegion)
          .then(({ data }) => {
            this.loadStudents();
          })
          .catch((error) => {
            console.log(error);
            if (!networkErrorHelper.is404(error)) {
              networkErrorHelper.notify(error);
            }

            this.setState({ loading: false });
          });
      }
    } else {
      ErrorNotification.render(
        "Devi selezionare un ente per poter proseguire "
      );
    }
  };

  //--- ---
  render() {
    const {
      loading,
      payloadCurrentCC,
      payloadCurrentRegion,
      currentList,
      currentCompany,
    } = this.state;

    console.log(currentCompany);

    return (
      <>
        {loading ? (
          <MnemoLoading></MnemoLoading>
        ) : (
          <Container className="m-4">
            <h1>Analisi domande di ammissione - 60 CFU</h1>
            <MSection>
              <Row>
                <Col md={3}>
                  <MSelect {...this.searchField[0]} />
                </Col>
                <Col md={5}>
                  <MSelect
                    {...this.searchField[1]}
                    payload={{ options: payloadCurrentCC }}
                  />
                </Col>
                <Col md={2}>
                  <MSelect
                    {...this.searchField[2]}
                    payload={{ options: payloadCurrentRegion }}
                  />
                </Col>
                <Col md={2}>
                  <Button
                    color="primary bg-dark"
                    onClick={() => {
                      this.onSearchForm();
                    }}
                  >
                    Cerca
                  </Button>
                </Col>
              </Row>

              <hr />
            </MSection>
            <MSection>
              <h5>Domande di Ammissione</h5>
              {currentList.length === 0 ? (
                <BoxInfo>Non ci sono dati da mostrare in database.</BoxInfo>
              ) : (
                <>
                  <Row>
                    <Button
                      className="ml-2"
                      color="primary bg-dark"
                      onClick={(e) => {
                        this.analyzeStudents(e);
                      }}
                    >
                      <span className="d-button-label">Calcola punteggio</span>
                    </Button>
                  </Row>
                  <Row>
                    <table className="MTable table  table-striped table-hover">
                      <thead>
                        <tr>
                          <th colSpan={3}>Dati anagrafici</th>
                          <th colSpan={3}>Dati domanda</th>
                          <th colSpan={7}>Punteggio</th>
                        </tr>
                        <tr>
                          <th>Nome completo</th>
                          <th>data nascita</th>
                          <th>Contatti</th>

                          <th>codice</th>
                          <th>stato</th>
                          <th>data firma</th>

                          <th>TOTALE</th>
                          <th>Titolo di accesso</th>
                          <th>Ulteriori titoli</th>
                          <th>Master universitari</th>
                          <th>Diploma di specializzazione</th>
                          <th>Dottorato di ricerca</th>
                          <th>Certificazioni linguistiche</th>
                          <th>Certificazioni di frequenza</th>
                          <th>Azioni</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentList.map((item, index) => (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>{dateHelper.toITDate(item.birthDate)}</td>
                            <td>{item.contacts}</td>

                            <td>{item.code}</td>
                            <td>{item.status}</td>
                            <td>{item.signDate}</td>

                            <td>{item.voteSum}</td>
                            <td>{item.vote1}</td>
                            <td>{item.vote2}</td>
                            <td>{item.vote3}</td>
                            <td>{item.vote4}</td>
                            <td>{item.vote5}</td>
                            <td>{item.vote6}</td>
                            <td>{item.vote7}</td>
                            <td>
                              <div
                                onClick={(e) => {
                                  window.location.href = "../";
                                }}
                              >
                                <Icon
                                  icon="it-download"
                                  title="Download Documento Iscrizione"
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Row>
                </>
              )}
            </MSection>
          </Container>
        )}
      </>
    );
  }
}
