import React from "react";
import {
  MButton2,
  MCheckbox,
  MFile,
  MInput,
  MLabel,
  MSearchUniversity,
  ValidatedForm,
} from "components/forms";
import { payloadBuilder } from "libs";
import { Button } from "design-react-kit";
import { Insegnamenti24CFUMultiManager } from "./Insegnamenti24CFUMultiManager";
import { FormTitleStep2Configurator } from "./FormTitleStep2Configurator";

export class Dichiarazione24CFUon60CFU extends ValidatedForm {

  emptyFields = {
    istitution: "",
    city: "",    
    dataConseguimento: "",
  };

  _formsInsegnamenti24CFU = [];

  state = {
    checkCFU: false,
    showSearchUniversity: false,
    loading: true,
    uploadSuccess: false,
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields,
    },
    numInsegnamenti24CFUMultipli: [],
    validationError: ''
  };

  constructor(props) {
    super(props);

    // bind event handler to invoke it from the child component
    this.onChange = this.onChange.bind(this);
  }

  onChangecheckCFU = (_, value) => {
    this.setState({ checkCFU: value });
  };

  FIELDS_GROUP = [
    [
      {
        id: "searchUniversity",
        component: MButton2,
        onClick: (e) => {
          this.searchUniversity(e);
        },
        label: "Cerca Ateneo",
        className: "col-md-12 mb-3",
        classElement: "btn btn-primary bg-dark",
      },
    ],
    [
      {
        id: "istitution",
        field: "istitution",
        label: "Presso",
        component: MInput,
        type: "text",
        className: "col-md-6 mt-4",
        readOnly: true,
      },
      {
        id: "city",
        field: "city",
        label: "Città",
        component: MInput,
        type: "text",
        className: "col-md-6 mt-4",
        readOnly: true,
      },
    ],
    [
      {
        id: "dataConseguimento00",
        field: "dataConseguimento",
        label: "Data di Certificazione",
        component: MInput,
        type: "date",
        className: "col-md-6 pt-3",
        infoText: "Inserire la data compresa tra il 10/08/2017 e il 31/10/2022",
      },
    ],
    [
      {
        id: "document",
        field: "document",
        label: "Allegare il documento",
        infoText: "Caricare il documento",
        component: MFile,
        value:  this.props.enrollmentID,
        config: {
          endPoint: "/enrollment/attachment-file",
          fieldName: "document",
          maxFiles: 1,          
          accept: ".pdf,.doc,.docx,.jpg,.bmp,.jpeg",       
          onUploadDone: () => {
            this.onUpload(true);
          },
        },
        className: "col-md-12 mt-0",
      },
    ],
  ];

  ERROR_MESSAGES = {
    istitution: "Dato obbligatorio",
    city: "Dato obbligatorio",
    dataConseguimento:
      "Inserire la data compresa tra il 10/08/2017 e il 31/10/2022",
  };

  validation = {
    istitution: (value) => value !== "",
    city: (value) => value !== "",  
    dataConseguimento: (value) => this.validateDataConseguimento(value),
  };

  validateDataConseguimento = (value) => {
    if (value === "") {
      return false;
    }
    let regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!value.match(regex)) {
      return false;
    }
    const date = new Date(value);

    if (Number.isNaN(date.getTime())) {
      return false;
    }
    const [year, month, day] = value.split("-").map(Number);

    if (month > 12) {
      return false;
    }
    if (day > 31) {
      return false;
    }

    const currentYear = new Date().getFullYear();
    if (year < currentYear - 70 || year > currentYear) {
      return false;
    }

    //--- check range of date ---
    const minDate = new Date("2017-08-10");
    const maxDate = new Date("2022-10-31");

    if (date < minDate || date > maxDate) {
      return false;
    }

    return true;
  };

  searchUniversity = () => {
    this.setState({ showSearchUniversity: !this.state.showSearchUniversity });
  };

  onUpload = (value) => {
    console.log(value);
    this.setState({ uploadSuccess: value });
  };

  onChangeUniversity = (university) => {
    this.onChange("city", university.city);
    this.onChange("istitution", university.istitution);
    this.setState({
      showSearchUniversity: false,
      university: university,
    });
  };

  onSubmit = () => {

    const { checkCFU, uploadSuccess } = this.state;
    let isValid = true;
    let validationError = '';

    if (this.props.mandatory && !checkCFU) {
      validationError = "Dichiarazione obbligatoria";
      this.setState({ validationError });
      return false;
    }

    if (checkCFU) {
      isValid = this.checkValidation();

      if (isValid && !uploadSuccess) {
        isValid = false;
      }

      //--- insegnamenti 24 cfu ---

      /** controllo se almeno se ci sono insegnamenti aggiunti */
      if (this._formsInsegnamenti24CFU.length === 0) {
        this.setState({ validationError: "Almeno 3 ambiti diversi devono essere indicati" });
        return false;
      }

      /** validazione insegnamenti aggiunti */
      if (this._formsInsegnamenti24CFU.length > 0) {
        let sumCFU = 0;
        let arrAmbiti = [];

        this._formsInsegnamenti24CFU.forEach((form) => {
          if (form && form.current != null) {
            const checkValidation = form.current.onSubmit();
            if (checkValidation) {
              const payloadInsegnamento = form.current.getPayload();
              sumCFU += parseInt(payloadInsegnamento.cfucfa);
              arrAmbiti.push(payloadInsegnamento.ambitoCorsoStudi);
            } else {
              isValid = false;
            }
          }
        });

        /** controllo se almeno 3 ambiti diversi sono stati selezionati */
        const uniqueAmbiti = [...new Set(arrAmbiti)];
        if (uniqueAmbiti.length < 3) {
          this.setState({ validationError: "Almeno 3 ambiti diversi devono essere indicati" });
          return false;
        }

        /** controllo se gli ambiti indicati raggiungo 24 cfu */
        if (sumCFU < 24) {
          this.setState({ validationError: "Almeno 24 CFU devono essere stati acquisiti" });
          return false;
        }
      }

    }

    this.setState({ validationError });
    return isValid;
  };

  getPayload = () => {

    const payloadOther = [];

    let payload = { checkCFU: this.state.checkCFU };

    if (this.state.checkCFU) {
      payload = payloadBuilder(this.state);
      payload.qualificationCode = "title13_24CFU";
      payload.qualificationType = "title13";
      payload.template = "Title13_24CFU";
      payload.extCode = FormTitleStep2Configurator.getExtCode("title13_24CFU");
      payload.uniCode = this.state.university.uniCode;
      payloadOther.push(payload);

      this._formsInsegnamenti24CFU.forEach((form) => {
        if (form && form.current != null) {
          const payloadInsegnamento = form.current.getPayload();
          payloadOther.push(payloadInsegnamento);
        }
      });      
    }

    return payloadOther;
  };

  addInsegnamenti24CFU = () => {
    this.state.numInsegnamenti24CFUMultipli.push(
      this.state.numInsegnamenti24CFUMultipli.length + 1
    );
    this.setState({
      ...this.state,
      numInsegnamenti24CFUMultipli: this.state.numInsegnamenti24CFUMultipli,
    });
  };

  removeInsegnamenti24CFU = (idx) => {
    if (this.state.numInsegnamenti24CFUMultipli.length <= 0) {
      return true;
    }

    this.state.numInsegnamenti24CFUMultipli.splice(idx, 1);
    this._formsInsegnamenti24CFU.splice(idx, 1);

    this.setState({
      ...this.state,
      numInsegnamenti24CFUMultipli: this.state.numInsegnamenti24CFUMultipli,
    });
  };

  //--- ---

  render() {
    const { defaultValues, showSearchUniversity, checkCFU, validationError } = this.state;
    const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

    return (
      <>
        <MCheckbox
          onChange={this.onChangecheckCFU}
          id="checkCFU"
          name="checkCFU"
          readOnly={false}
          value={checkCFU}
          disabled={false}
        >
          <p>
            {" "}
            Dettaglio degli esami acquisiti per il requisito dei 24 Crediti
            Formativi Universitari o Accademici (ai sensi dell'art. 5 comma 1
            lettera a) e b) D.lgs.vo 59/2017). Cerca l'Ateneo che ha rilasciato
            l'ultima certificazione e carica la certificazione rilasciata.
          </p>
        </MCheckbox>
        {checkCFU && (
          <>
            {showSearchUniversity && (
              <>
                <MSearchUniversity
                  onChange={this.onChangeUniversity}
                  searchUniversity={this.searchUniversity}
                  isForeign={false}
                  isCFU={true}
                />
              </>
            )}

            {!showSearchUniversity && <form className="user mt-4">{code}</form>}

            <>
              <div className="mt-3 text-secondary">
                <MLabel
                  label="Almeno 3 dei 4 ambiti devono essere indicati ed almeno 24 CFU devono essere stati acquisiti."
                  className="col-md-12 pt-1"
                />
              </div>
              {(this.state.numInsegnamenti24CFUMultipli || []).map((_, idx) => {
                this._formsInsegnamenti24CFU[idx] = React.createRef();

                return (
                  <div
                    key={`insegnamenti24CFU=${idx}`}
                    className="m-0 text-secondary"
                  >
                    <h6>Insegnamento n. {idx + 1}</h6>
                    <Button
                      className="mb-3 btn btn-sm btn-danger"
                      onClick={() => {
                        this.removeInsegnamenti24CFU(idx);
                      }}
                    >
                      Rimuovi
                    </Button>
                    <div className="p-3 border border-secondary rounded-lg">
                      <Insegnamenti24CFUMultiManager
                        ref={this._formsInsegnamenti24CFU[idx]}
                        key={`insegnamenti24CFU=${idx}`}
                        competitionClass={this.props.competitionClass}
                        idx={idx}
                      />
                    </div>
                  </div>
                );
              })}
              <div className="mt-3 text-secondary">
                <Button
                  className="btn btn-sm btn-primary"
                  onClick={this.addInsegnamenti24CFU}
                >
                  aggiungi un insegnamento
                </Button>
                {validationError && <div className="text-danger mt-2">{validationError}</div>}
              </div>
            </>
          </>
        )}
      </>
    );
  }
}
