import React from "react";
import { MCheckbox, ValidatedForm } from "components/forms";
import { Button } from "design-react-kit";
import { TitoliProfessionali } from "./TitoliProfessionali";
import { VincitoreProvaBis } from "./VincitoreProvaBis";
import { dateHelper } from "libs";

export class TitolareContrattoVincitore extends ValidatedForm {
  _formsContrattoDocenza = [];
  _formVinvitoreProva = null;

  state = {
    checkA: false,
    checkB: false,
    numContrattoDocenza: [],
    validatedError: "",
  };

  componentDidMount() {
    this._formVinvitoreProva = React.createRef();
  }

  checkAnniEsperienza(payloadToCheck) {

    let validationError = "";

    /* 
          1. le esperienze  hanno aa (da 2020/2021 a 2024/2025)
          2. sono almeno 3 anni di esperinza
          3. almeno un anno di esperienza deve avere la classe corrente
      */
    if (payloadToCheck.length > 0) {

      //si ottiene la lista degli anni in cui si è svolto servizio
      let yearServices = [];
      for (let i = 0; i < payloadToCheck.length; i++) {
        const item = payloadToCheck[i];
        if (yearServices.includes(item.aa)) {
          continue;
        }
        if (item.aa) {
          yearServices.push(item.aa);
        }
      }

      // per ogni aa in cui si è svolto il servizio si calcolano i giorni 
      // lavorativi della stessa classe e di altre classi
      let yearResume = [];
      for (let i = 0; i < yearServices.length; i++) {

        const aa = yearServices[i];
        const aaItems = payloadToCheck.filter((t) => t.aa === aa);

        // order aaItems by dateFrom ASC
        aaItems.sort((a, b) => {
          return a.dateFrom - b.dateFrom;
        });

        let totalAADaysCCSame = 0;
        let totalAADaysCCOther = 0;

        for (let j = 0; j < aaItems.length; j++) {
          const aaItem = aaItems[j];

          // if lode add 180 days and skip this year
          if (aaItem.lode) {
            if (
              aaItem.classeConcorso === this.props.competitionClass
            ) {
              totalAADaysCCSame += 180;
            } else {
              totalAADaysCCOther += 180;
            }
            break;
          }

          // verifica se il periodo corrente ha delle intersezioni con il precedente
          if (j > 0) {
            const prevItem = aaItems[j - 1];
            if (aaItem.dateFrom <= prevItem.dateTo) {
              // rimuove l'intersezione dal calcolo dei giorni
              const intersecation = dateHelper.diffDays(prevItem.dateTo, aaItem.dateFrom);

              if (aaItem.classeConcorso === this.props.competitionClass) {
                totalAADaysCCSame -= intersecation;
              } else {
                totalAADaysCCOther -= intersecation;
              }
            }
          }

          if (aaItem.classeConcorso === this.props.competitionClass) {
            totalAADaysCCSame += parseInt(aaItem.durata);
          } else {
            totalAADaysCCOther += parseInt(aaItem.durata);
          }

        }

        // add the days of curent years
        yearResume.push({ aa: aa, totalAADaysCCSame, totalAADaysCCOther });
      }
      
      let countSame = 0;
      let countOther = 0;
      // calcola il numero di anni di servizio per la stessa classe e per altre classi
      for (let i = 0; i < yearResume.length; i++) {
        const year = yearResume[i];

        if (year.totalAADaysCCSame >= 180) {
          countSame++;
        } else if ((year.totalAADaysCCSame + year.totalAADaysCCOther) >= 180) {
          countOther++;
        }
      }

      if (countSame < 1) {
        validationError = " Almeno un anno di servizio deve essere stato svolto per la stessa classe di concorso per la quale si partecipa alla graduatoria.";
      }

      if ((countSame+countOther) < 3) {
        validationError += " Almeno tre anni di servizio devono essere stati svolti per partecipare alla graduatoria.";
      }

    } else {
      validationError += " E' necessario aggiungere i servizi prestati.";
    }

    return validationError;
  }

  onSubmit = () => {
    let validationError = "";
    let isValid = true;
    const { checkA, checkB } = this.state;

    if (!checkA && !checkB) {
      this.setState({ validationError: "Selezionare una delle due opzioni." });
      return false;
    }

    if (checkA && checkB) {
      this.setState({
        validationError: "Selezionare SOLAMENTE una delle due opzioni.",
      });
      return false;
    }

    if (checkA) {
      const payloadToCheck = [];

      if (this._formsContrattoDocenza.length > 0) {
        this._formsContrattoDocenza.forEach((form) => {
          if (form && form.current !== null) {
            isValid = form.current.onSubmit();
            if (!isValid) {
              validationError = "Inserire i dati richiesti.";
            } else {
              const payload = form.current.getPayload();
              payloadToCheck.push(payload);
            }
          } else {
            isValid = false;
            validationError = "Errore generico.";
          }
        });
      }

      if (isValid) {
        validationError = this.checkAnniEsperienza(payloadToCheck);
        if (validationError) {
          isValid = false;
        }
      }
    }

    if (checkB) {
      if (
        this._formVinvitoreProva &&
        this._formVinvitoreProva.current != null
      ) {
        isValid = this._formVinvitoreProva.current.checkValidation();
        if (!isValid) {
          validationError = "Inserire i dati richiesti.";
        }
      } else {
        isValid = false;
        validationError = "Errore generico.";
      }
    }

    this.setState({ validationError: validationError });
    return isValid;
  };

  addContrattoDocenza = () => {
    this.state.numContrattoDocenza.push(
      this.state.numContrattoDocenza.length + 1
    );
    this.setState({
      ...this.state,
      numContrattoDocenza: this.state.numContrattoDocenza,
    });
  };

  removeContrattoDocenza = (idx) => {
    if (this.state.numContrattoDocenza.length <= 0) {
      return true;
    }

    this.state.numContrattoDocenza.splice(idx, 1);
    this._formsContrattoDocenza.splice(idx, 1);

    this.setState({
      ...this.state,
      numContrattoDocenza: this.state.numContrattoDocenza,
    });
  };

  getPayload = () => {
    const payloadTitles = [];
    const { checkA, checkB } = this.state;

    if (checkA) {
      payloadTitles.push({
        qualificationCode: "title14",
        classeConcorso: this.props.competitionClass,
        qualificationType: "title14",
        template: "Title14_TITOLARE_CONTRATTO",
      });

      this._formsContrattoDocenza.forEach((form) => {
        if (form && form.current != null) {
          // get payload
          const payload = form.current.getPayload();
          payloadTitles.push(payload);
        }
      });
    } else if (checkB) {
      payloadTitles.push({
        qualificationCode: "title14",
        classeConcorso: this.props.competitionClass,
        qualificationType: "title14",
        template: "Title14_CONCORSUALE_BIS",
      });

      if (
        this._formVinvitoreProva &&
        this._formVinvitoreProva.current != null
      ) {
        const payload = this._formVinvitoreProva.current.getPayload();
        payloadTitles.push(payload);
      }
    }

    return payloadTitles;
  };

  onChecked = (idAccept, checked) => {
    if (idAccept === "checkA") {
      this.setState({ checkA: checked, numContrattoDocenza: [] });
    } else if (idAccept === "checkB") {
      this.setState({ checkB: checked });
    }
  };

  render() {
    const { checkA, checkB, validationError, numContrattoDocenza } = this.state;

    return (
      <>
        <p>Scegliere e compilare una sola delle due opzioni seguenti:</p>
        <MCheckbox
          name="checkA"
          id="checkA"
          onChange={this.onChecked}
          label="Dichiaro di aver prestato il seguente servizio presso le istituzioni scolastiche
                 statali o presso le scuole paritarie per almeno tre anni, anche non continuativi, di cui 
                 almeno uno nella specifica classe di concorso per la quale scelgo di conseguire 
                 l’abilitazione nei cinque anni precedenti, valutati ai sensi dell'art. 11, comma 14, 
                 della Legge 3 maggio 1999, n. 124"
        />

        {checkA && (
          <div>
            {(numContrattoDocenza || []).map((_, idx) => {
              this._formsContrattoDocenza[idx] = React.createRef();

              return (
                <div
                  key={`contrattoDocenza=${idx}`}
                  className="m-0 ml-5 mt-5 text-secondary"
                >
                  <h6>Servizio n. {idx + 1}</h6>
                  <Button
                    className="mb-3 btn btn-sm btn-danger"
                    onClick={() => {
                      this.removeContrattoDocenza(idx);
                    }}
                  >
                    Rimuovi
                  </Button>
                  <div className="p-3 border border-secondary rounded-lg">
                    <TitoliProfessionali
                      ref={this._formsContrattoDocenza[idx]}
                      key={`contrattoDocenza=${idx}`}
                      idx={idx}
                      checkAA={true}
                      competitionClass={this.props.competitionClass}
                      qualificationType="title14"
                      qualificationCode="title14Item"
                      template="Title14Item_TITOLARE_CONTRATTO"
                      extCode="TITOLARE_CONTRATTO"
                    />
                  </div>
                </div>
              );
            })}
            <div className="mt-3 ml-5 text-secondary">
              <Button
                className="btn btn-sm btn btn-secondary"
                onClick={this.addContrattoDocenza}
              >
                Aggiungi
              </Button>
            </div>
          </div>
        )}
        <MCheckbox
          name="checkB"
          id="checkB"
          onChange={this.onChecked}
          label="Dichiaro di aver sostenuto la prova concorsuale relativa alla procedura 
                straordinaria di cui all’art. 59, comma 9-bis del Decreto-legge 25 maggio 2021, n. 73,
                convertito, con modificazioni, dalla Legge 23 luglio 2021, n. 106, fermo restando il
                 possesso del titolo di studio necessario con riferimento alla classe di concorso."
        />

        {checkB && (
          <div>
            <VincitoreProvaBis
              qualificationCode="title14Item"
              competitionClass={this.props.competitionClass}
              qualificationType="title14"
              ref={this._formVinvitoreProva}
            />
          </div>
        )}
        {validationError && (
          <div className="text-danger">{validationError}</div>
        )}
      </>
    );
  }
}
