import React, { Component } from "react";
import { NavLink, withRouter } from 'react-router-dom';
import { BoxDanger, MnemoLoading, SupportoTecnico, Title } from "components/misc";
import { CourseHelper, dateHelper, learningService, networkErrorHelper } from "libs";
import { Icon } from "design-react-kit";
import { COMPETITION_CLASS_TITLE, ROUTES } from "const";
import { rc30ExamService } from "libs/api/rc30ExamService";

class RC30CertificationRequest extends Component {

    state = {
        loading: true,
        loadingError: false,
        course: 0,
        userCourse: 0,
        rCode: '',
        examDone: false
    };

    goBack() {
        window.history.back();
    }

    componentDidMount() {

        const { rCode } = this.props.match.params;
        this.loadRemote(rCode);
    }

    loadRemote(rCode) {

        learningService
            .courseDashboard(rCode)
            .then(({ data }) => {

                const { course, userCourse } = data.payload;
                this.setState({ course, userCourse, rCode });

                rc30ExamService.myExamDone(rCode)
                    .then(({ data }) => {

                        console.log(data);
                        const { examDone } = data.payload;
                        this.setState({ loading: false, examDone, loadingError: false });
                    })
                    .catch(error => {

                        if (!networkErrorHelper.is404(error)) {
                            networkErrorHelper.notify(error);
                        }

                        this.setState({ rCode, loading: false, loadingError: true });
                    });
            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false, loadingError: true });
            });
    }

    render() {

        const { loading, loadingError, rCode, userCourse, examDone } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);
        if (loadingError) {
            return (<BoxDanger className="mt-4">
                Si è verificato un errore durante il caricamento della pagina,
                <br /><SupportoTecnico />
            </BoxDanger>);
        }

        const dateExam = dateHelper.toITDate(examDone.date);
        const titleCC = COMPETITION_CLASS_TITLE[userCourse.pluginConfig.competitionClass];
        if (userCourse.courseCode === 'RC60CFUALL1' || userCourse.courseCode === 'RC30CFUALL2') {
            return (<>
                <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
                <Title >Consultazione esito finale</Title>
                <hr />
                <ul>
                    <li><b>Classe di Concorso</b>: {userCourse.pluginConfig.competitionClass} - {titleCC}</li>
                    <li><b>Voto Finale</b>: {examDone.voteFinal}/10</li>
                    <li><b>Data di Conseguimento</b>: {dateExam}</li>
                    <li><b>Istituzione</b>: Università per Stranieri Dante Alighieri di Reggio Calabria</li>
                </ul>
                <p>Il certificato finale sarà disponibile nelle prossime settimane.</p>
            </>)
        } else if (CourseHelper.isUnicamillus30CFU(userCourse.courseCode)) {
            return (<>
                <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
                <Title >Percorsi Abilitanti 30 CFU (ex art.13 DPCM 4 agosto 2023)</Title>
                <hr />

                <p>Facendo seguito al corso da Lei frequentato ed alla prova finale sostenuta,
                    nell'attesa che l'Università emetta la certificazione finale a tutti i corsisti (ad eccezione degli iscritti con riserva poiché in possesso
                    di abilitazione sul sostegno conseguita all'estero), si riportano i dati utili all'autocertificazione:</p>
                <ul>
                    <li><b>Classe di Concorso</b>: {userCourse.pluginConfig.competitionClass} - {titleCC}</li>
                    <li><b>Procedura abilitante</b>: Abilitazione conseguita attraverso la frequenza del percorso universitario e accademico di formazione iniziale di 30 CFU, di cui all'art. 13, comma 2, e all'art. 2-ter, commi 4 e 4-bis, del decreto Legislativo 13 aprile 2017, n. 59</li>
                    <li><b>Voto Finale</b>: {examDone.voteFinal}/10</li>
                    <li><b>Data di Conseguimento</b>: {dateExam}</li>
                    <li><b>Istituzione</b>: UniCamillus - Saint Camillus International University of Health Sciences</li>
                </ul>
            </>)
        } else if (CourseHelper.isTchaikovsky30CFU(userCourse.courseCode)) {
            return (<>
                <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
                <Title >Percorsi Abilitanti 30 CFU (ex art.13 DPCM 4 agosto 2023)</Title>
                <hr />

                <p>Facendo seguito al corso da Lei frequentato ed alla prova finale sostenuta,
                    nell'attesa che il Conservatorio emetta la certificazione finale a tutti i corsisti (ad eccezione degli iscritti con riserva poiché in possesso
                    di abilitazione sul sostegno conseguita all'estero), si riportano i dati utili all'autocertificazione:</p>
                <ul>
                    <li><b>Classe di Concorso</b>: {userCourse.pluginConfig.competitionClass} - {titleCC}</li>
                    <li><b>Procedura abilitante</b>: Abilitazione conseguita attraverso la frequenza del percorso universitario e accademico di formazione iniziale di 30 CFA, di cui all'art. 13, comma 2, e all'art. 2-ter, commi 4 e 4-bis, del decreto Legislativo 13 aprile 2017, n. 59</li>
                    <li><b>Voto Finale</b>: {examDone.voteFinal}/10</li>
                    <li><b>Data di Conseguimento</b>: {dateExam}</li>
                    <li><b>Istituzione</b>: Conservatorio Statale di Musica “P.I. Tchaikovksy”</li>
                </ul>
            </>)
        } else {
            return (<>
                <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
                <Title >Percorsi Abilitanti 30 CFU (ex art.13 DPCM 4 agosto 2023)</Title>
                <hr />

                <p>Facendo seguito al corso da Lei frequentato ed alla prova finale sostenuta,
                    nell'attesa che l'Università emetta la certificazione finale a tutti i corsisti (ad eccezione degli iscritti con riserva poiché in possesso
                    di abilitazione sul sostegno conseguita all'estero), si riportano i dati utili all'autocertificazione:</p>
                <ul>
                    <li><b>Classe di Concorso</b>: {userCourse.pluginConfig.competitionClass} - {titleCC}</li>
                    <li><b>Procedura abilitante</b>: Abilitazione conseguita attraverso la frequenza del percorso universitario e accademico di formazione iniziale di 30 CFU, di cui all'art. 13, comma 2, e all'art. 2-ter, commi 4 e 4-bis, del decreto Legislativo 13 aprile 2017, n. 59</li>
                    <li><b>Voto Finale</b>: {examDone.voteFinal}/10</li>
                    <li><b>Data di Conseguimento</b>: {dateExam}</li>
                    <li><b>Istituzione</b>: Università per Stranieri Dante Alighieri di Reggio Calabria</li>
                </ul>
            </>)
        }

    }
}

export default withRouter(RC30CertificationRequest);