import React from "react";
import { MCheckbox, ValidatedForm } from "components/forms";
//import { payloadBuilder } from "libs";
import { Button } from "design-react-kit";
import { ContrattoDocenza } from "./ContrattoDocenza";
import { payloadBuilder } from "libs";

export class DichiarazioneRiservisti extends ValidatedForm {
  _formsContrattoDocenza = [];

  emptyFields = {
    checkRiservisti: false,
  };

  state = {
    defaultValues: {},
    error: "",
    numDichiarazioneRiservistiMultipli: [],
    validationError:""
  };

  constructor(props) {
    super(props);

    // bind event handler to invoke it from the child component
    this.onChange = this.onChange.bind(this);
  }

  FIELDS_GROUP = [
    [
      {
        field: "checkRiservisti",
        label:
          "Dichiaro di essere titolare di contratti di docenza nell'ambito di percorsi di istruzione e formazione professionale delle Regioni nell'anno scolastico precedente all'avvio dei percorsi.",
        component: MCheckbox,
        className: "col-md-12 pt-3",
        onChange: (name, value) => {
          this.onClickCheckRiservisti(value);
        },
      },
    ],
  ];

  ERROR_MESSAGES = {};

  componentDidMount() {
    this.setState({ defaultValues: this.emptyFields, ...this.emptyFields });
  }

  onClickCheckRiservisti = (value) => {
    this.state.defaultValues.checkRiservisti = value;

    if (value === false) {
      this.state.numDichiarazioneRiservistiMultipli = [];
    }
  };

  onSubmit = () => {
    if (this.state.defaultValues.checkRiservisti === false) {
      return true;
    }
    return this.onSubmitRiservisti();
  };

  onSubmitRiservisti = () => {

    let isValid = false;
    let validationError = '';

    if (this._formsContrattoDocenza.length > 0) {
      this._formsContrattoDocenza.forEach((form) => {
        if (form && form.current != null) {
          isValid = form.current.checkValidation();
        } else {
          isValid = false;
        }
      });
    }else{
      isValid = false;
      validationError = "Aggiungere almeno un contratto di docenza o togliere la spunta di selezione";
    }

    this.setState({validationError});

    return isValid;
  };

  getPayload = () => {
    const payloadOther = [];
    this.payload = payloadBuilder(this.state);

    if (this.payload.checkRiservisti) {
      payloadOther.push({
        qualificationType: "title14",
        qualificationCode: "title14_RISERVISTI",
        classeConcorso: this.props.competitionClass,    
        template: "DichiarazioneRiservisti",
      });

      if (this._formsContrattoDocenza.length > 0) {
        this._formsContrattoDocenza.forEach((form) => {
          if (form && form.current != null) {
            // get payload
            const payloadRiservisti = form.current.getPayload();
            payloadOther.push(payloadRiservisti);
          }
        });
      }
    }

    return payloadOther;
  };

  addContrattoDocenza = () => {
    this.state.numDichiarazioneRiservistiMultipli.push(
      this.state.numDichiarazioneRiservistiMultipli.length + 1
    );
    this.setState({
      ...this.state,
      numDichiarazioneRiservistiMultipli:
        this.state.numDichiarazioneRiservistiMultipli,
    });
  };

  removeContrattoDocenza = (idx) => {
    if (this.state.numDichiarazioneRiservistiMultipli.length <= 0) {
      return true;
    }

    this.state.numDichiarazioneRiservistiMultipli.splice(idx, 1);
    this._formsContrattoDocenza.splice(idx, 1);

    this.setState({
      ...this.state,
      numDichiarazioneRiservistiMultipli:
        this.state.numDichiarazioneRiservistiMultipli,
    });
  };

  renderDichiarazioneRiservisti() {
    const { defaultValues, validationError } = this.state;
    const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

    return (
      <>
        {code}

        {(this.state.numDichiarazioneRiservistiMultipli || []).map((_, idx) => {
          this._formsContrattoDocenza[idx] = React.createRef();

          return (
            <div
              key={`contrattodocenza=${idx}`}
              className="m-0 ml-5 mt-5 text-secondary"
            >
              <h6>Contratto di Docenza n. {idx + 1}</h6>
              <Button
                className="mb-3 btn btn-sm btn-danger"
                onClick={() => {
                  this.removeContrattoDocenza(idx);
                }}
              >
                Rimuovi
              </Button>
              <div className="p-3 border border-secondary rounded-lg">
                <ContrattoDocenza
                  ref={this._formsContrattoDocenza[idx]}
                  key={`contrattodocenza=${idx}`}
                  idx={idx}
                />
              </div>
            </div>
          );
        })}

        {defaultValues.checkRiservisti && (
          <div className="mt-3 ml-5 text-secondary">
            <Button
              className="btn btn-sm btn-primary"
              onClick={this.addContrattoDocenza}
            >
              aggiungi un contratto di docenza
            </Button>
          </div>
        )}
        {(validationError) && (
          <div className="mt-3 ml-5 text-danger">
            {validationError}
          </div>
        )}
      </>
    );
  }

  render() {
    return this.renderDichiarazioneRiservisti();
  }
}
