import { MCheckbox, MSelect } from 'components/forms';
import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

export class FormIUL30CFU extends Component {

    state = {
        accept11: false,
        accept12: false,
        accept13: false,
        competitionClass: '',
        validationError: ''
    }

    PAYLOAD_CLASS =
        {
            options: [
                { label: 'SELEZIONARE LA CLASSE DI CONCORSO', value: '' },
                { label: 'A001 - Arte e immagine nella scuola secondaria di I grado', value: 'A001' },
                { label: 'A012 - Discipline Letterarie negli Istituti di Istruzione Secondaria di II Grado', value: 'A012' },
                { label: 'A017 - Disegno e storia dell’arte negli istituti di istruzione secondaria di II grado', value: 'A017' },
                { label: 'A018 - Filosofia e Scienze umane', value: 'A018' },
                { label: 'A019 - Filosofia e Storia', value: 'A019' },
                { label: 'A020 - Fisica', value: 'A020' },
                { label: 'A021 - Geografia', value: 'A021' },
                { label: 'A022 - Italiano, Storia, Geografia nella Scuola Secondaria di I Grado', value: 'A022' },
                { label: 'A026 - Matematica', value: 'A026' },
                { label: 'A027 - Matematica e Fisica', value: 'A027' },
                { label: 'A028 - Matematica e scienze', value: 'A028' },
                { label: 'A045 - Scienze economico-aziendali', value: 'A045' },
                { label: 'A046 - Scienze giuridico-economiche', value: 'A046' },
                { label: 'A048 - Scienze Motorie e Sportive negli Istituti di Istruzione Secondaria di II Grado', value: 'A048' },
                { label: 'AB24 - Lingue e Culture Straniere negli Istituti di Istruzione di II Grado (Inglese)', value: 'AB24' },
                { label: 'AB25 - Lingua Inglese e Seconda Lingua Comunitaria nella Scuola Secondaria I Grado (Inglese)', value: 'AB25' },
                { label: 'AA24 - Lingue e Culture Straniere negli Istituti di Istruzione di II Grado (Francese)', value: 'AA24' },
                { label: 'AA25 - Seconda Lingua Comunitaria nella Scuola Secondaria I Grado (Francese)', value: 'AA25' },

            ]
        }

    getValue() {

        const pluginValue = {
            competitionClass: this.state.competitionClass,
            accept11: this.state.accept11,
            accept12: this.state.accept12,
            accept13: this.state.accept13,

        };

        return pluginValue;
    }

    checkValidation() {

        let validationError = '';
        let isValid = true;
        const { accept11, accept12, accept13, competitionClass } = this.state;

        if (competitionClass === '') {
            validationError += '<li>Dovresti selezionare una classe di concorso</li>';
            isValid = false;
        }

        if (!accept11 || !accept12 || !accept13) {
            validationError += '<li>E\' necessario accettare tutte le dichiarazioni.</li>';
            isValid = false;
        }

        if (!isValid) validationError = '<ul>' + validationError + '</ul>';
        this.setState({ validationError })

        return (isValid);
    }

    onChangeCompletitionClass = (id, value) => {

        this.setState({ competitionClass: value });
    }

    onConditionClick = (id, value) => {

        this.setState({ [id]: value });
    }

    render() {
        return (
            <div>
                <h5>Classe di concorso</h5>
                <MSelect onChange={this.onChangeCompletitionClass} id="completitionClass" payload={this.PAYLOAD_CLASS}></MSelect>
                <hr />
                <h5>Dichiarazioni</h5>
                <MCheckbox onChange={this.onConditionClick} id="accept11" name="accept11" >Dichiaro di essere in possesso dei requisiti di accesso indicati all'art. 2 del Bando e che verranno dettagliatamente
                    autocertificati in fase di completamento dell'iscrizione dal sottoscritto (abilitazione o specializzazione sul sostegno)</MCheckbox>
                <MCheckbox onChange={this.onConditionClick} id="accept12" name="accept12" >Dichiaro di essere in possesso dei titoli di studio richiesti dalla normativa vigente per l'accesso alla classe di concorso per la quale si intende conseguire la nuova abilitazione e che verranno dettagliatamente autocertificati in fase di completamento dell'iscrizione dal sottoscritto (Laurea che consente l'accesso all'insegnamento)</MCheckbox>
                <MCheckbox onChange={this.onConditionClick} id="accept13" name="accept13" >Dichiaro di essere consapevole che i Percorsi di formazione iniziale 30 CFU/CFA ex Art. 13 DPCM 4 agosto 2023 sono corsi universitari a frequenza obbligatoria e che la loro frequenza è incompatibile con l’iscrizione a corsi universitari che richiedano anch’essi frequenza obbligatoria DICHIARO, ai sensi dell’art. 76 D.P.R. 28 dicembre 2000, n. 445 e successive modifiche, di non essere attualmente iscritto ad altri corsi universitari a frequenza obbligatoria (escludendo da questa casistica i corsi per i quali la frequenza obbligatoria è prevista solo per attività laboratoriali e di tirocinio)</MCheckbox>
                <hr />
                {this.state.validationError && (<><span className="form-text text-danger is-invalid">{ReactHtmlParser(this.state.validationError)}</span></>)}
            </div>
        )
    }
}