import React, { Component } from 'react';
import Select from 'react-select'

export class MSearch extends Component {

    _href = '';

    componentDidMount() {
        this._href = React.createRef();
    }

    clearValue() {
        if (this._href && this._href.current) {
           this._href.current.clearValue();
        }
    }

    /**
     * docs         : https://react-select.com
     *
     * PROPS: 
     * id           : identifier 
     * label        : example text (brief description of fields)     
     * infoText     : addtional text used when the input in invalid
     * disabled     : insert for readonly 
     * readOnly     : insert for readonly 
     * valid        : set  when the input is valid
     * invalid      : set  when the input is invalid
     * payload      : json of list of options (label and value)
     * defaultValue : array of json to list of options (label and value)
     */

    handleChange = (selectedOption) => {
        if (this.props.onChange) {
            this.props.onChange(this.props.id, selectedOption);
        }
    };

    render() {

        const {
            id, name,
            payload = {}, disabled = false, readOnly = false,
            placeHolder, className = '',
            isMulti = false, defaultOptionValues
        } = this.props;

        return (
            <div className={className} >
                <Select
                    className="basic-single"
                    placeholder={placeHolder}
                    closeMenuOnSelect={true}
                    isMulti={isMulti}
                    isSearchable
                    isClearable
                    id={id}
                    name={name}
                    options={payload}
                    disabled={disabled}
                    readOnly={readOnly}
                    onChange={this.handleChange}
                    defaultValue={defaultOptionValues}
                />
            </div>
        );
    }
}