import React, { Component } from "react";
import { adminService, networkErrorHelper } from "libs";
import { MTable } from "components/table";
import { Container } from 'design-react-kit';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'const';

import {
  MInput,
  MToggle,
  MSelect
} from 'components/forms';

import {
  BoxDanger,
  MnemoLoading,
  ModalHandler
} from "components/misc";

import ReactHtmlParser from 'react-html-parser';

import "./CourseManager.css";

class CourseManager extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      payload: [],
      payloadToRender: [],
      isArchived: false,
      aa: process.env.REACT_APP_AA_YEAR,
      searchFilter: {
        searchFilter: {
          deleted: false,
          aa: process.env.REACT_APP_AA_YEAR
        }
      },
      rndKey: 0,
      popolateAA: { options: [{ label: 'Seleziona', value: -1 }] }
    }
  }

  PAYLOADS = {
    aa: []
  }

  //--- ---
  tableConfig = {
    columns: [
      {
        id: 'id', // identifier of columns
        Header: 'Column 0',
        isVisible: false, // IMPORTANT: hidden columns
        accessor: 'id', // accessor is the "key" in the data

      },
      {
        id: 'aa',
        Header: 'A.A.',
        accessor: 'aa', // accessor is the "key" in the data

      },
      {
        id: 'code',
        Header: 'Codice',
        accessor: 'code', // accessor is the "key" in the data

      },
      {
        id: 'title',
        Header: 'Titolo',
        accessor: 'title',
      }
    ],
    actions: [
      {
        id: 'detail',
        accessor: 'id',
        label: 'Dettagli',
        icon: 'it-file',
        className: 'MTable-actions',
        onClick: (e, value) => {
          window.location.href = ROUTES.COURSE_FORM + '/' + value;
        }
      },
      {
        id: 'delete',
        accessor: 'id', // field value received in the onclick event
        label: 'Elimina',
        icon: 'it-delete',
        className: 'MTable-actions',
        onClick: (e, value) => { this.deleteRecord(e, value) }
      }
    ]
  }
  //--- ---
  tableConfigDelete = {
    columns: [
      {
        id: 'id', // identifier of columns
        Header: 'Column 0',
        isVisible: false, // IMPORTANT: hidden columns
        accessor: 'id', // accessor is the "key" in the data

      },
      {
        id: 'aa',
        Header: 'A.A.',
        accessor: 'aa', // accessor is the "key" in the data

      },
      {
        id: 'code',
        Header: 'Codice',
        accessor: 'code', // accessor is the "key" in the data

      },
      {
        id: 'title',
        Header: 'Titolo',
        accessor: 'title',
      }
    ],
    actions: [
      {
        id: 'detail',
        accessor: 'id',
        label: 'Dettagli',
        icon: 'it-file',
        className: 'MTable-actions',
        onClick: (e, value) => {
          window.location.href = ROUTES.COURSE_FORM + '/' + value;
        }
      },
      {
        id: 'revert',
        accessor: 'id', // field value received in the onclick event
        label: 'Ripristina',
        icon: 'it-check-circle',
        className: 'MTable-actions',
        onClick: (e, value) => { this.revertRecord(e, value) }
      },
      {
        id: 'delete',
        accessor: 'id', // field value received in the onclick event
        label: 'Elimina definitivamente',
        icon: 'it-delete',
        className: 'MTable-actions',
        onClick: (e, value) => { this.deletePermanentlyRecord(e, value) }
      }
    ]
  }
  //--- ---


  componentDidMount() {
    this.loadAllCourse(this.state.searchFilter);
  }

  //--- start functions to delete procedure ---
  deleteRecord(e, value) {
    ModalHandler.show({ value: value, status: true }, 'Eliminazione del dato',
      'vuoi davvero eliminare?', null, this.modalConfirmed);
  }
  deletePermanentlyRecord(e, value) {
    ModalHandler.show(value, 'Eliminazione definitiva del dato',
      'vuoi davvero eliminare?', null, this.deletePermanentlyConfirmed);
  }
  revertRecord(e, value) {
    ModalHandler.show({ value: value, status: false }, 'Ripristino del dato',
      'vuoi davvero ripristinare?', null, this.modalConfirmed);
  }


  modalConfirmed = (params) => {
    let payload = {
      id: params.value,
      deleted: params.status
    };

    adminService.willDeleted(payload).then(({ data }) => {
      this.setState({ loading: true });
      this.loadAllCourse(this.state.searchFilter);
    })
      .catch(error => {
        console.log(error);
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }
      });
  }

  deletePermanentlyConfirmed = (value) => {
    adminService.deleteCourse(value).then(({ data }) => {
      this.setState({ loading: true });
      this.loadAllCourse(this.state.searchFilter);
    })
      .catch(error => {
        console.log(error);
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }
      });
  }
  //--- end functions to delete procedure ---

  filterByAA = (_, aa) => {
    this.setState({
      aa: aa,
      searchFilter: {
        searchFilter: {
          deleted: this.state.isArchived,
          aa: aa
        }
      }
    }, () => {
      this.loadAllCourse(this.state.searchFilter);
    });
  }//filterByAA


  loadAllCourse(filter) {
    adminService
      .loadAllCourse(filter)
      .then(({ data }) => {

        //--- start: prepare reactTable ---
        const items = [];
        Object.entries(data.payload.courses).forEach(([key, val]) => {
          items.push(val);
        });
        //--- end: prepare reactTable ---

        //--- construct option array ---
        const aa = data.payload.distinctAA.map(({ aa }) => ({ label: aa, value: aa }));
        this.PAYLOADS.aa = [];
        this.PAYLOADS.aa.push(...aa);
        this.PAYLOADS.aa.unshift({ label: `Seleziona`, value: -1 });
        //this.PAYLOADS.aa.unshift({ label: aa[0].label, value: aa[0].value });
        //--- construct option array ---

        const parseItems = items.map((item) => {
          return { ...item, title: ReactHtmlParser(item.title) }
        })

        this.setState({
          loading: false,
          payload: items,
          payloadToRender: parseItems,
          popolateAA: { options: this.PAYLOADS.aa },
          rndKey: Math.floor((Math.random() * 1000) + 1)
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false });
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        } else {
          this.setState({
            payload: [],
            payloadToRender: [],
            rndKey: Math.floor((Math.random() * 1000) + 1)
          })
        }
      });
  }

  onChangeArchived = () => {
    let isArchived = !this.state.isArchived;

    this.setState({
      isArchived: isArchived,
      searchFilter: {
        searchFilter: {
          deleted: isArchived,
          aa: this.state.aa
        }
      }
    }, () => {
      this.loadAllCourse(this.state.searchFilter);
    });
  }//onChangeArchived


  onSearch = (keySearch) => {
    if (keySearch.length < 3 && this.state.payloadToRender !== this.state.payload) {
      this.setState({
        payloadToRender: this.state.payload,
        rndKey: Math.floor((Math.random() * 1000) + 1)
      })
      return false;
    }

    if (keySearch.length < 3) return false;

    const results = (this.state.payload).filter(function (item) {
      return (
        item.code.toLowerCase().includes(keySearch.toLowerCase()))
        || (item.title.toLowerCase().includes(keySearch.toLowerCase())
        )
    });

    this.setState({
      payloadToRender: results,
      rndKey: Math.floor((Math.random() * 1000) + 1)
    })
  }//onSearch


  //--- ---

  render() {

    const searchField = {
      id: 'searchCourse',
      name: 'searchCourse',
      label: 'cerca per codice o titolo',
      type: 'text',
      className: 'form-control',
      onKeyUp: (elm, value) => {
        this.onSearch(value)
      }
    }

    const { loading } = this.state;

    return (
      <>
        <div className="container mt-5 mb-4">
          <section className="m-0">
            <div className="form-row m-0 p-0">
              <div className="col-md-6">
                <MInput {...searchField} className="mb-0" />
              </div>

              <div className="col-md-3">
                <MSelect id="aa" label="Anno Accademico" payload={this.state.popolateAA} onChange={this.filterByAA} />
              </div>

              <div className="col-md-3">
                <MToggle
                  id='isArchived'
                  name='isArchived'
                  label="Corsi eliminati"
                  value={this.state.isArchived}
                  disabled={false}
                  onChange={this.onChangeArchived}
                />
              </div>
            </div>

            <div className="col-md-12 text-right">
              <NavLink to={ROUTES.COURSE_FORM} className="btn btn-primary bg-dark pb-2">Nuovo corso</NavLink>
            </div>
          </section>

          <hr />

          <section className="my-4">
            {loading ? (<MnemoLoading></MnemoLoading>) : (
              <Container>
                <MTable
                  key={this.state.rndKey}
                  tableData={this.state.payloadToRender}
                  tableConfig={this.state.searchFilter.searchFilter.deleted ? this.tableConfigDelete : this.tableConfig}
                />
              </Container>
            )}

            {(this.state.payload.length === 0) && (<BoxDanger key="nrp-1" className="my-3">
              Ricerca non valida.
              <div className="small text-info">
                Nessun dato per questa selezione, cambia i parametri di ricerca.
              </div>
            </BoxDanger>)}
          </section>
        </div>
      </>
    );
  }
}

export default CourseManager;
