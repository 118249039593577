import React from "react";
import {
  MInput,
  MSelect,
  ValidatedForm,
} from "components/forms";
import { payloadBuilder } from "libs";
import { FormTitleStep2Configurator } from "./FormTitleStep2Configurator";

export class Insegnamenti24CFUMultiManager extends ValidatedForm {
  emptyFields = {
    dataConseguimento: "",
    description: "",
    cfucfa: "",
    ambitoCorsoStudi: "",
  };

  state = {
    loading: true,
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields,
    },
  };

  constructor(props) {
    super(props);

    // bind event handler to invoke it from the child component
    this.onChange = this.onChange.bind(this);
  }

  FIELDS_GROUP = [
    [
      {
        id: "dataConseguimento7" + this.props.idx,
        field: "dataConseguimento",
        label: "Data di Conseguimento",
        component: MInput,
        type: "date",
        className: "col-md-6 pt-3",
      },
    ],
    [
      {
        id: "description7" + this.props.idx,
        field: "description",
        label: "Insegnamento",
        component: MInput,
        className: "col-md-12",
      },
    ],
    [
      {
        id: "cfucfa7" + this.props.idx,
        field: "cfucfa",
        label: "CFU",
        component: MSelect,
        payload: {
          options: [
            { label: "Seleziona", value: "" },
            { label: "1", value: "1" },
            { label: "2", value: "2" },
            { label: "3", value: "3" },
            { label: "4", value: "4" },
            { label: "5", value: "5" },
            { label: "6", value: "6" },
            { label: "7", value: "7" },
            { label: "8", value: "8" },
            { label: "9", value: "9" },
            { label: "10", value: "10" },
            { label: "11", value: "11" },
            { label: "12", value: "12" },
            { label: "13", value: "13" },
            { label: "14", value: "14" },
            { label: "15", value: "15" },
            { label: "16", value: "16" },
            { label: "17", value: "17" },
            { label: "18", value: "18" },
            { label: "19", value: "19" },
            { label: "20", value: "20" },
            { label: "21", value: "21" },
            { label: "22", value: "22" },
            { label: "23", value: "23" },
            { label: "24", value: "24" },
          ],
        },
        className: "col-md-3 pt-3",
      },
      {
        id: "ambitoCorsoStudi7" + this.props.idx,
        field: "ambitoCorsoStudi",
        label: "Ambito",
        component: MSelect,
        payload: {
          options: [
            { label: "Seleziona", value: "" },
            { label: "Ambito di Psicoloigia", value: "Ambito di Psicoloigia" },
            {
              label: "Ambito di Antropologia",
              value: "Ambito di Antropologia",
            },
            {
              label: "Ambito di Metologie e Tecnologie didattiche generali",
              value: "Ambito di Metologie e Tecnologie didattiche generali",
            },
            {
              label:
                "Ambito di Pedagogia, Pedagogia Speciale, e Didattica dell'inclusione",
              value: "Ambito di Pedagogia, Pedagogia Speciale, e Didattica dell'inclusione",
            },
          ],
        },
        className: "col-md-9 pt-3",
      },
    ],
  ];

  ERROR_MESSAGES = {
    dataConseguimento: "Data non valida.",
    description: "Dato obbligatorio",
    cfucfa: "Dato obbligatorio",
    ambitoCorsoStudi: "Dato obbligatorio",
  };

  validation = {
    dataConseguimento: (value) => this.validateDataConseguimento(value),
    description: (value) => value !== "",
    cfucfa: (value) => value !== "" && parseInt(value) > 0,
    ambitoCorsoStudi: (value) => value !== "",
  };

  validateDataConseguimento = (value) => {
    if (value === "") {
      return false;
    }
    let regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!value.match(regex)) {
      return false;
    }
    const date = new Date(value);

    if (Number.isNaN(date.getTime())) {
      return false;
    }
    const [year, month, day] = value.split("-").map(Number);

    if (month > 12) {
      return false;
    }
    if (day > 31) {
      return false;
    }

    const currentYear = new Date().getFullYear();
    if (year < currentYear - 70 || year > currentYear) {
      return false;
    }

    //--- check range of date ---
    //const minDate = new Date("2017-08-10");
    const maxDate = new Date("2022-10-31");

    if (date > maxDate) {
      return false;
    }

    return true;
  };

  getPayload = () => {
    this.payload = payloadBuilder(this.state);
    this.payload.qualificationCode = "title13_INSEGNAMENTI";
    this.payload.qualificationType = "title13";
    this.payload.classeConcorso = this.props.competitionClass;
    this.payload.template = "Title13_INSEGNAMENTI";
    this.payload.extCode= FormTitleStep2Configurator.getExtCode("title13_INSEGNAMENTI");

    return this.payload;
  };

  render() {
    const { defaultValues } = this.state;
    const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

    return <>{code}</>;
  }
}
