import { MCheckbox, MSelect } from 'components/forms';
import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

export class FormTFA extends Component {

    state = {
        schoolType: '',
        validationError: '',
        competitionClass: '',
        accept11: false, 
        accept12: false,    
    }

    PAYLOAD_SCHOOL =
        {
            options: [
                { label: 'SELEZIONARE ORDINE E GRADO DI SCUOLA', value: '' },
                { label: 'Scuola Secondaria di Primo Grado', value: 'Secondaria di Primo Grado' },
            ]
        }

    PAYLOAD_COMPETITION_CLASS =
        {
            'Secondaria di Primo Grado':
            {
                options: [
                    { label: 'SELEZIONARE LA CLASSE DI CONCORSO', value: '' },
                    { label: 'A001 ARTE E IMMAGINE NELLA SCUOLA SECONDARIA DI I GRADO', value: 'A001' },
                    { label: 'A022 ITALIANO, STORIA, GEOGRAFIA NELLA SCUOLA SECONDARIA DI I GRADO', value: 'A022' },
                    { label: 'A028 MATEMATICA E SCIENZE', value: 'A028' },
                    { label: 'A030 MUSICA NELLA SCUOLA SECONDARIA DI I GRADO', value: 'A030' },
                    { label: 'A049 SCIENZE MOTORIE E SPORTIVE NELLA SCUOLA SECONDARIA DI I GRADO', value: 'A049' },
                    { label: 'A056 STRUMENTO MUSICALE NELLA SCUOLA SECONDARIA DI I GRADO', value: 'A056' },
                    { label: 'A060 TECNOLOGIA NELLA SCUOLA SECONDARIA DI I GRADO', value: 'A060' },
                    { label: 'A070 ITALIANO, STORIA EDUC. CIVICA, GEOGRAFIA SCUOLA SEC. DI I GRADO SLOVENA O BILINGUE', value: 'A070' },
                    { label: 'A071 SLOVENO, STORIA EDUC. CIVICA, GEOGRAFIA SCUOLA SEC. DI I GRADO SLOVENA O BILINGUE', value: 'A071' },
                    { label: 'A077 LINGUA E CULTURA LADINA, STORIA EDUC. CIVICA, GEOGRAFIA SCUOLA SEC. DI I GRADO LADINA', value: 'A077' },
                    { label: 'A078 ITALIANO (SECONDA LINGUA), STORIA EDUC. CIVICA, GEOGRAFIA SCUOLA SEC. DI I GRADO TEDESCA', value: 'A078' },
                    { label: 'A084 TEDESCO (SECONDA LINGUA), STORIA EDUC. CIVICA, GEOGRAFIA SCUOLA SEC. DI I GRADO BOLZANO', value: 'A084' },
                    { label: 'A085 TEDESCO, STORIA EDUC. CIVICA, GEOGRAFIA SCUOLA SEC. DI I GRADO IN LING. TEDESCA', value: 'A085' },
                    { label: 'AA25 LINGUA INGLESE E SECONDA LINGUA COMUNITARIA NELLA SCUOLA SECONDARIA  I GRADO', value: 'AA25' },
                ]
            },
        }

    getValue() {

        const pluginValue = {
            schoolType: this.state.schoolType,
            accept11: this.state.accept11,          
            competitionClass: this.state.competitionClass,
            accept12: this.state.accept12,
        };

        return pluginValue;
    }

    checkValidation() {

        let validationError = '';
        let isValid = true;
        const { schoolType, accept11, competitionClass } = this.state;

        if (schoolType === '') {
            validationError += '<li>Dovresti selezionare un ordine e grado di scuola</li>';
            isValid = false;
        }

        if (competitionClass === '') {
            validationError += '<li>Dovresti selezionare una classe di concorso</li>';
            isValid = false;
        }

        if (!accept11) {
            validationError += '<li>E\' necessario dichiarare di essere risultato vincitore/idoneo.</li>';
            isValid = false;
        }
     
        if (!isValid) validationError = '<ul>' + validationError + '</ul>';
        this.setState({ validationError })
        return (isValid);
    }

    onChangeSchoolType = (id, value) => {

        this.setState({ schoolType: value });
    }

    onConditionClick = (id, value) => {

        this.setState({ [id]: value });
    }

    onChangeCompetitionClass = (id, value) => {

        this.setState({ competitionClass: value });
    }

    render() {

        let payloadClass = this.PAYLOAD_COMPETITION_CLASS[this.state.schoolType];
        if (!payloadClass) {
            payloadClass = { options: [] };
            payloadClass.options.push({ label: 'SELEZIONARE ORDINE E GRADO DI SCUOLA', value: '' });
        }
        return (
            <div>
                <div className='text-center'>
                    <h5>TFA SOSTEGNO SOPRANNUMERARI/IDONEI</h5>
                    <p><b>(soprannumerario/idoneo presso altri Atenei)</b></p>
                </div>
                <h5>Ordine e Grado di Scuola per cui si vuole conseguire la	Specializzazione per le attività di sostegno</h5>
                <MSelect onChange={this.onChangeSchoolType} id="schoolType" payload={this.PAYLOAD_SCHOOL}></MSelect>
                <hr />
                <h5>Posseggo tutti i requisiti previsti dalla normativa vigente per l’accesso alla seguente classe di concorso</h5>
                <MSelect onChange={this.onChangeCompetitionClass} id="completitionClass" payload={payloadClass}></MSelect>
                <hr />
                <h5>Dichiarazioni</h5>
                <MCheckbox
                    onChange={this.onConditionClick} id="accept11" name="accept11" >di essere risultato vincitore/idoneo ovvero collocato in posizione non utile nelle graduatorie di merito del concorso di ammissione al Percorso di formazione per il conseguimento della specializzazione per le attività di sostegno didattico agli alunni con disabilità per la scuola secondaria di I Grado presso altro Ateneo.</MCheckbox>                
                <hr />
                <h5>Eventuali necessità del Candidato</h5>
                <MCheckbox
                    onChange={this.onConditionClick} id="accept12" name="accept12">Candidato/a con disturbi previsti dalla Legge 170/2010 o con Necessità di trattamento individualizzato </MCheckbox>
                <hr />
                {this.state.validationError && (<><span className="form-text text-danger is-invalid">{ReactHtmlParser(this.state.validationError)}</span></>)}
            </div>
        )
    }
}