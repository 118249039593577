import React, { Component } from 'react';
import { MCheckbox } from 'components/forms';

export class FormINFO extends Component {

    //importToPay = ['', '', '148', '198', '248'];
    importToPay = ['', '', '80', '100', '120'];

    state = {
        courses: [],
        validationError: ''
    }

    getValue() {

        const newItems = this.state.courses.sort();
        const items = newItems.map(item => {
            return { code: item }
        });

        const pluginValue = {
            courses: items,
        };
                
        return pluginValue;
    }

    checkValidation() {

        let validationError = '';

        const isValid = (this.state.courses.length >= 2 && this.state.courses.length <= 4 );
        if (!isValid) {
            validationError = 'Dovresti selezionare da due a quattro corsi';
        }

        this.setState({ validationError })

        return (isValid);
    }

    onClick = (id, value) => {

        let length = this.state.courses.length;

        if (value) {
            this.setState({ courses: this.state.courses.concat([id]) });
            length++;
        } else {
            var array = [...this.state.courses]; // make a separate copy of the array
            var index = array.indexOf(id)
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({ courses: array });
            }
            length--;
        }

        const payload = [];
        const promoAmount = this.props.promoAmount ? this.props.promoAmount: 0;
        if (length > 1 && length<=4) {
            const importToPay = this.importToPay[length] -promoAmount;
            payload.push({ value: importToPay, label: 'Soluzione unica ' + importToPay })
        }

        if (this.props.onChangeImport)
            this.props.onChangeImport(payload);
    }

    render() {

        return (
            <>
                <h6>Corsi (seleziona almeno due) </h6>
                <hr />
                <MCheckbox onChange={this.onClick} id="INFOA98" name="INFOA98" >Corso A - Coding: pensiero computazionale e informatica</MCheckbox>
                <MCheckbox onChange={this.onClick} id="INFOB98" name="INFOB98" >Corso B - La L.I.M. e la didattica</MCheckbox>
                <MCheckbox onChange={this.onClick} id="INFOC98" name="INFOC98" >Corso C - Fondamenti di informatica</MCheckbox>
                <MCheckbox onChange={this.onClick} id="INFOD98" name="INFOD98" >Corso D - Didattica inclusiva ed ambienti di apprendimento informatici innovativi</MCheckbox>
                <MCheckbox onChange={this.onClick} id="INFOE98" name="INFOE98" >Corso E - Risorse digitali per la didattica e strategie informatiche per lezioni interattive</MCheckbox>
                {this.state.validationError && (<><span className="form-text text-danger is-invalid">{this.state.validationError}</span></>)}
                <hr />
            </>
        );
    }
}