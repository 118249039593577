import React, { Component } from 'react';
import { Input, Label, FormGroup, FormText } from 'design-react-kit';
import { formHelper } from 'libs/helpers';
import ReactHtmlParser from 'react-html-parser';

export class MCheckbox extends Component {

    state = {
        checked: null,
        userChanged: false
    }

    onClick = (event) => {

        const checked = event.target.checked;
        this.setState({ checked: checked, userChanged: true });

        const onChange = this.props.onChange;
        if (formHelper.isFunction(onChange)) {
            onChange(this.props.name, checked);
        }

    };

    componentDidMount() {
        this.setState({ checked: this.props.value });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            checked: nextProps.value
        })
    }

    /**
     * PROPS: 
     * id       : identifier 
     * label    : example text (brief description of fields)     
     * infoText : addtional text used when the input in invalid
     * disabled : insert for readonly      
     * readOnly : is readOnly
     * value    : the value
     * className: additional class name
     */
    render() {

        // get properties
        const { id, name, invalid, valid = null,
            disabled, readOnly,
            label, infoText, className = '' } = this.props;        

        const myProps = {
            disabled: disabled || readOnly,
            id, name
        };

        if (this.state.userChanged) {
            myProps.checked = this.state.checked;
        } else {
            myProps.checked = this.props.value;
        }

        let myClassName = (valid) ? 'is-valid' : '';
        myClassName += (invalid) ? 'is-invalid' : '';
        if (className) {
            myClassName += ' ' + className;
        }
        return (
            <FormGroup check >
                <Input type="checkbox"
                    className={myClassName}
                    {...myProps}
                    onClick={this.onClick}
                />
                <Label className={myClassName} for={id} check>{ReactHtmlParser(label)} {this.props.children}</Label>
                {invalid ? (<small className="form-text text-danger">{infoText}</small>) : (
                    <>{infoText && (<FormText color="">{infoText} </FormText>)}</>)
                }
            </FormGroup>
        );
    }
}