import React from "react";
import { MCheckbox, ValidatedForm } from "components/forms";
import { Button } from "design-react-kit";
import { payloadBuilder } from "libs";
import { DottoratoRicercaForm } from "./DottoratoRicercaForm";

export class DottoratoRicerca extends ValidatedForm {
  _formsDottoratoRicerca = [];

  emptyFields = {
    checkDottoratoRicerca: false,
  };

  guardParams = {
    maxForms: 2,
  };

  state = {
    defaultValues: {},
    numDottoratoRicercaMultipli: [],
    validationError: ""
  };

  constructor(props) {
    super(props);

    // bind event handler to invoke it from the child component
    this.onChange = this.onChange.bind(this);
  }

  FIELDS_GROUP = [
    [
      {
        field: "checkDottoratoRicerca",
        label: "<b>Dottorato di Ricerca.</b>",
        component: MCheckbox,
        className: "col-md-12",
        onChange: (name, value) => {
          this.onClickCheckDottoratoRicerca(value);
        },
      },
    ],
  ];

  ERROR_MESSAGES = {};

  componentDidMount() {
    this.setState({ defaultValues: this.emptyFields, ...this.emptyFields });
  }

  onClickCheckDottoratoRicerca = (value) => {
    this.state.defaultValues.checkDottoratoRicerca = value;

    if (value === false) {
      this.state.numDottoratoRicercaMultipli = [];
    }
  };

  onSubmit = () => {
    if (this.state.defaultValues.checkDottoratoRicerca === false) {
      return true;
    }
 
    let isValid = false;
    let validationError = '';

    if (this._formsDottoratoRicerca.length > 0) {
      this._formsDottoratoRicerca.forEach((form) => {
        if (form && form.current != null) {
          isValid = form.current.checkValidation();
        } else {
          isValid = false;
        }
      });
    } else {
      isValid = false;
      validationError = "Aggiungere almeno un dottorato di ricerca o togliere la spunta di selezione";
    }

    this.setState({ validationError });

    return isValid;
  };

  getPayload = () => {
    const payloadOther = [];
    this.payload = payloadBuilder(this.state);

    if (this.payload.checkDottoratoRicerca) {
      payloadOther.push({
        qualificationCode: "title21",
        classeConcorso: this.props.competitionClass,
        qualificationType: "Dottorato di ricerca",
        template: "DottoratoRicerca",
      });

      if (this._formsDottoratoRicerca.length > 0) {
        this._formsDottoratoRicerca.forEach((form) => {
          if (form && form.current != null) {
            // get payload
            const payloadDottRicerca = form.current.getPayload();
            payloadOther.push(payloadDottRicerca);
          }
        });
      }
    }

    return payloadOther;
  };

  addDottoratoRicerca = () => {
    if (
      this.state.numDottoratoRicercaMultipli.length >= this.guardParams.maxForms
    ) {
      return false; // max forms reached
    }

    this.state.numDottoratoRicercaMultipli.push(
      this.state.numDottoratoRicercaMultipli.length + 1
    );
    this.setState({
      ...this.state,
      numDottoratoRicercaMultipli: this.state.numDottoratoRicercaMultipli,
    });
  };

  removeDottoratoRicerca = (idx) => {
    if (this.state.numDottoratoRicercaMultipli.length <= 0) {
      return true;
    }

    this.state.numDottoratoRicercaMultipli.splice(idx, 1);
    this._formsDottoratoRicerca.splice(idx, 1);

    this.setState({
      ...this.state,
      numDottoratoRicercaMultipli: this.state.numDottoratoRicercaMultipli,
    });
  };

  render() {

    const { defaultValues, validationError } = this.state;
    const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

    return (
      <>
        {code}

        <p>3 punti per ciascun titolo di dottorato. <br /> <i>Massimo 6 punti complessivi.</i></p>
        {(this.state.numDottoratoRicercaMultipli || []).map((_, idx) => {
          this._formsDottoratoRicerca[idx] = React.createRef();

          return (
            <div
              key={`dottoratoRicerca=${idx}`}
              className="m-0 ml-5 mt-5 text-secondary"
            >
              <h6>Dottorato di Ricerca n. {idx + 1}</h6>
              <Button
                className="mb-3 btn btn-sm btn-danger"
                onClick={() => {
                  this.removeDottoratoRicerca(idx);
                }}
              >
                Rimuovi
              </Button>
              <div className="p-3 border border-secondary rounded-lg">
                <DottoratoRicercaForm
                  ref={this._formsDottoratoRicerca[idx]}
                  key={`dottoratoRicerca=${idx}`}
                  idx={idx}
                />
              </div>
            </div>
          );
        })}

        {defaultValues.checkDottoratoRicerca && (
          <div className="mt-3 ml-5 text-secondary">
            <Button
              className={`btn btn-sm btn ${this.state.numDottoratoRicercaMultipli.length >=
                  this.guardParams.maxForms
                  ? "btn-danger"
                  : "btn-secondary"
                }`}
              onClick={this.addDottoratoRicerca}
            >
              {this.state.numDottoratoRicercaMultipli.length >=
                this.guardParams.maxForms
                ? "puoi aggiungere Max: " +
                this.guardParams.maxForms +
                " Dottorati di Ricerca"
                : "Aggiungi"}
            </Button>
          </div>
        )}
        {(validationError) && (
          <div className="mt-3 ml-5 text-danger">
            {validationError}
          </div>
        )}
      </>
    );
  }
}
