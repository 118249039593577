import React from "react";

import { Row, Col, Button } from "design-react-kit";

import { ModalHandler } from "components/misc";

import "./PDFView.css";

export function PDFViewEmbeded(props) {
  const forceDownload = (e, value) => {
    e.stopPropagation();

    const { showAlert = true } = props;

    if (showAlert) {
      ModalHandler.show(
        value,
        "Download File",
        `il materiale modulo sarà salvato sul tuo pc. ricordiamo 
      che il tempo dedicato alla consultazione del materiale scaricato non 
      sarà conteggiato dalla piattaforma.?`,
        null,
        forceDownloadConfirmed
      );
    } else {
      forceDownloadConfirmed(value);
    }
  };

  const forceDownloadConfirmed = (file) => {
    window.open(file, "_blank");
  }; //forceDownloadConfirmed

  const { fileUrlDownload, fileUrlEmbeded, canDownload } = props;

  //--- ---
  return (
    <>
      <div>
        <iframe
          src={fileUrlEmbeded}
          style={{ width: "100%", height: "100vh" }}
          title="PDF Viewer Embeded"
        />

        {canDownload && (
          <Row>
            <Col className="text-center">
              <Button onClick={(e) => forceDownload(e, fileUrlDownload)}>
                Scarica File
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}
