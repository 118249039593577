import React from "react";
import { MCheckbox, ValidatedForm } from "components/forms";
import { Button } from "design-react-kit";
import { payloadBuilder } from "libs";
import { CertLingueStraniere2012Form } from "./CertLingueStraniere2012Form";

export class CertLingueStraniere2012 extends ValidatedForm {
  _formsCertLingueStraniere2012 = [];

  emptyFields = {
    checkCertLingueStraniere2012: false,
  };

  guardParams = {
    maxScore: 2,

  };

  state = {
    validationError: '',
    currentScore: 0,
    defaultValues: {},
    numCertLingueStraniere2012Multipli: [],
  };

  constructor(props) {
    super(props);

    // bind event handler to invoke it from the child component
    this.onChange = this.onChange.bind(this);
  }

  FIELDS_GROUP = [
    [
      {
        field: "checkCertLingueStraniere2012",
        label:
          "<b>Certificazioni Linguistiche di livello almeno C1 in lingua straniera.</b>",
        component: MCheckbox,
        className: "col-md-12",
        onChange: (name, value) => {
          this.onClickCheckCertLingueStraniere2012(value);
        },
      },
    ],
  ];

  componentDidMount() {
    this.setState({ defaultValues: this.emptyFields, ...this.emptyFields });
  }

  onClickCheckCertLingueStraniere2012 = (value) => {
    this.state.defaultValues.checkCertLingueStraniere2012 = value;

    if (value === false) {
      this.state.numCertLingueStraniere2012Multipli = [];
    }
  };

  checkBandoCompatibility = (payloads) => {

    // check about the BANDO compatibility
    let titles = [];
    let res = {
      isValid: true,
      validationError: ""
    }

    payloads.forEach((payload) => {
      if (titles.includes(payload.language)) {
        res.isValid = false;
        res.validationError = "Non è possibile inserire più certificazioni per la stessa lingua";
      }

      titles.push(payload.language);
    });    
    return res;
  };

  onSubmit = () => {

    if (this.state.defaultValues.checkCertLingueStraniere2012 === false) {
      return true;
    }

    const currentScore = this.updateCurentScore();
    if (currentScore > this.guardParams.maxScore) {
      this.setState({ validationError: "Limite massimo di punteggio superato. Punti attualmente calcolati: " + currentScore });
      return false;
    }

    let isValid = false;
    let validationError = '';
    let payloads = [];

    if (this._formsCertLingueStraniere2012.length > 0) {
      this._formsCertLingueStraniere2012.forEach((form) => {
        if (form && form.current != null) {
          isValid = form.current.checkValidation();
          payloads.push(form.current.getPayload());
        } else {
          isValid = false;
        }
      });

      // check about the BANDO compatibility
      if (isValid) {
        const res = this.checkBandoCompatibility(payloads);
        isValid = res.isValid;
        validationError = res.validationError;        
      }
    } else {
      isValid = false;
      validationError = "Aggiungere almeno una certificazione o togliere la spunta di selezione";
    }

    this.setState({ validationError });

    return isValid;
  };

  getPayload = () => {

    const payloadOther = [];
    this.payload = payloadBuilder(this.state);

    if (this.payload.checkCertLingueStraniere2012) {
      payloadOther.push({
        qualificationCode: "title21_CERT_LINGUISTICA",
        classeConcorso: this.props.competitionClass,
        qualificationType: "Certificazioni Linguistiche",
        template: "certLingueStraniere2012",
      });

      if (this._formsCertLingueStraniere2012.length > 0) {
        this._formsCertLingueStraniere2012.forEach((form) => {
          if (form && form.current != null) {
            // get payload
            const payloadCertLingua = form.current.getPayload();
            payloadOther.push(payloadCertLingua);
          }
        });
      }
    }

    return payloadOther;
  };

  updateCurentScore = () => {

    let currentScore = 0;
    if (this._formsCertLingueStraniere2012.length > 0) {
      this._formsCertLingueStraniere2012.forEach((form) => {
        if (form && form.current != null) {
          const paylaod = form.current.getPayload();
          if (paylaod.ambitoCorsoStudi === "C1") {
            currentScore += 0.5;
          } else if (paylaod.ambitoCorsoStudi === "C2") {
            currentScore += 1;
          }
        }
      });
    }

    console.log(currentScore);
    this.setState({ currentScore: currentScore });
    return currentScore;
  }

  addCertLingueStraniere2012 = () => {

    const currentScore = this.updateCurentScore();
    if (currentScore >= this.guardParams.maxScore) {
      return false; // max score reached
    }

    // TODO: calgoereo chiedere
    this.state.numCertLingueStraniere2012Multipli.push(
      this.state.numCertLingueStraniere2012Multipli.length + 1
    );
    this.setState({
      ...this.state,
      numCertLingueStraniere2012Multipli:
        this.state.numCertLingueStraniere2012Multipli,
    });
  };

  removeCertLingueStraniere2012 = (idx) => {
    if (this.state.numCertLingueStraniere2012Multipli.length <= 0) {
      return true;
    }

    this.state.numCertLingueStraniere2012Multipli.splice(idx, 1);
    this._formsCertLingueStraniere2012.splice(idx, 1);

    this.setState({
      ...this.state,
      numCertLingueStraniere2012Multipli:
        this.state.numCertLingueStraniere2012Multipli,
    });

    this.updateCurentScore();
  };

  render() {
    const { defaultValues, validationError, currentScore } = this.state;
    const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);    
    return (
      <>
        {code}
        <p>Certificazioni linguistiche di livello almeno C1 in lingua straniera conseguite ai sensi del decreto del Ministro dell'istruzione,
          dell'università e della ricerca 7 marzo 2012, prot. 3889, pubblicato sulla G.U. n. 79 del 3 aprile 2012 ed esclusivamente presso
          gli enti ricompresi nell'elenco degli Enti certificatori riconosciuti dal Ministero dell'istruzione e del merito ai sensi
          del predetto decreto. Livello C1 punti 0,5 Livello C2 punti 1.<br />  <i>Per ciascuna lingua straniera viene valutato un solo titolo. Massimo 2 punti complessivi</i>.</p>
        {(this.state.numCertLingueStraniere2012Multipli || []).map((_, idx) => {
          this._formsCertLingueStraniere2012[idx] = React.createRef();

          return (
            <div
              key={`certlinguestraniere2012=${idx}`}
              className="m-0 ml-5 mt-5 text-secondary"
            >
              <h6>Certificazione Linguistica n. {idx + 1}</h6>
              <Button
                className="mb-3 btn btn-sm btn-danger"
                onClick={() => {
                  this.removeCertLingueStraniere2012(idx);
                }}
              >
                Rimuovi
              </Button>
              <div className="p-3 border border-secondary rounded-lg">
                <CertLingueStraniere2012Form
                  ref={this._formsCertLingueStraniere2012[idx]}
                  key={`certlinguestraniere2012=${idx}`}
                  idx={idx}
                />
              </div>
            </div>
          );
        })}

        {defaultValues.checkCertLingueStraniere2012 && (
          <div className="mt-3 ml-5 text-secondary">
            <Button
              className={`btn btn-sm btn ${(currentScore >= this.guardParams.maxScore)
                ? "btn-danger"
                : "btn-secondary"
                }`}
              onClick={this.addCertLingueStraniere2012}
            >
              {(currentScore >= this.guardParams.maxScore)
                ? "limite  massimo di punteggio raggiunto"
                : "Aggiungi"}
            </Button>
          </div>
        )}
        {validationError && <div className="text-danger">{validationError}</div>}
      </>
    );
  }
}
