import { MCheckbox, ValidatedForm } from "components/forms";
import {
  dateHelper,
  enrollmentService,
  networkErrorHelper,
  payloadBuilder,
} from "libs";
import React from "react";
import { FormTitleStep1 } from "./enroll60CFU";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Col,
  Row,
} from "design-react-kit";
import { DichiarazioniUlteriori60CFU } from "./enroll60CFU/DichiarazioniUlteriori60CFU";
import ReactHtmlParser from "react-html-parser";
import { Dichiarazione24CFUon60CFU } from "./enroll60CFU/Dichiarazione24CFUon60CFU";
import { DichiarazioneTitoloEstero } from "./enroll60CFU/DichiarazioneTitoloEstero";
import { FormRef2TitleStep1 } from "./enroll60CFU/FormRef2TitleStep1";
import { FormTitleITPStep1 } from "./enroll60CFU/FormTitleITPStep1";
import { ENROLLMENT_STEPS } from "const";
import { TitolareContrattoVincitore } from "./enroll60CFU/TitolareContrattoVincitore";
import { FormTitleStep1270 } from "./enroll60CFU/FormTitleStep1270";

export class EnrollFillForm30CFUStep1 extends ValidatedForm {
  TITNONABILIT__EST = 0;

  constructor(props) {
    super(props);
    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }

  defaultState = {
    collapseOpenLAU_VO: false,
    collapseOpenLAU_270: false,
    collapseOpenLAU_02_509: false,
    collapseOpenAFAM_II_24: false,
    collapseOpenITP: false,
    collapseOpenTITNONABILIT__EST: false,
  };

  state = {
    _formRef_1_1: [],
    _formRef2_1_1: null,
    _formRef_1_2: null,
    _formRef_1_3: null,
    _formRef_1_4_lav: null,
    loading: true,
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields,
    },
    ...this.defaultState,
    titleStep1: "",
    titleStep1Active: [],
    globalCheckDisable: false,
    selectedCheckbox: null,
    validationSummary: "",
    currentCompetitionClass:
      this.props.enrollData.pluginConfig.competitionClass || "",
    paylodCurrentTitles: [],
    paylodLaureandoTitles: [],
  };

  toggle = (id) => {
    this.setState({
      ...this.defaultState,
      [`collapseOpen${id}`]: !this.state[`collapseOpen${id}`],
    });
  };

  PAYLOADS = {
    titleCheckBoxList: [],
  };

  onSubmit = () => {
    let validationList = [];
    let isValid = false;
    this.payload = payloadBuilder(this.state);
    let qualifications = [];

    // validate titleStep1
    const {
      _formRef_1_1,
      _formRef2_1_1,
      _formRef_1_2,
      _formRef_1_3,
      _formRef_1_4_lav,
    } = this.state;

    const currentForms = _formRef_1_1.filter((item) => item.current !== null);

    if (currentForms.length === 0) {
      validationList.push(
        "TITOLO DI ACCESSO: Specificare il Titolo di Accesso"
      );
      isValid = false;
    } else {
      const currentForm = currentForms[0];
      const checkValidation = currentForm.current.onSubmit();

      if (checkValidation === true) {
        const payload = currentForm.current.getPayload();
        // verify if the payload is an array or an object
        if (Array.isArray(payload)) {
          qualifications.push(...payload);
        } else {
          qualifications.push(payload);
        }
        isValid = true;
      } else {
        validationList.push(
          "TITOLO DI ACCESSO " +
          currentForm.current.getFormLabel() +
          " : Compilare tutti i campi obbligatori."
        );
        isValid = false;
      }
    }

    if (_formRef2_1_1 && _formRef2_1_1.current) {
      const checkValidation21 = _formRef2_1_1.current.checkValidation();

      if (checkValidation21 === true) {
        const payload21 = _formRef2_1_1.current.getPayload();
        qualifications.push(payload21);
        isValid = true;
      } else {
        validationList.push(
          "TITOLO TRIENNALE: Compilare tutti i campi obbligatori."
        );
        isValid = false;
      }
    }

    //--- ulteriori dichiarazioni per classe di concorso ---
    if (_formRef_1_2 && _formRef_1_2.current) {
      const checkValidation = _formRef_1_2.current.checkValidation();

      if (!checkValidation) {
        validationList.push(
          "ULTERIORI DICHIARAZIONI: verificare i dati inseriti."
        );
        isValid = false;
      } else {
        if (!_formRef_1_2.current.onSubmit()) {
          validationList.push(
            "ULTERIORI DICHIARAZIONI: verificare i dati inseriti.."
          );
          isValid = false;
        } else {
          const payload12 = _formRef_1_2.current.getPayload();
          qualifications.push(...payload12);
        }
      }
    }

    if (_formRef_1_3 && _formRef_1_3.current) {
      const checkValidation = _formRef_1_3.current.onSubmit();

      if (!checkValidation) {
        validationList.push("REQUISITO 24 CFU: verificare i dati inseriti");
        isValid = false;
      } else {
        const payload13 = _formRef_1_3.current.getPayload();
        qualifications.push(...payload13);
      }
    }

    // requisito professionale    
    if (_formRef_1_4_lav) {
      const checkValidation = _formRef_1_4_lav.current.onSubmit();
      const payload14 = _formRef_1_4_lav.current.getPayload();

      if (!checkValidation) {
        validationList.push(
          "REQUISITO PROFESSIONALE. Verificare i dati evidenziati in rosso."
        );
        isValid = false;
      } else {
        qualifications.push(...payload14);      
      }
    }

       if (isValid) {

      this.payload.qualifications = qualifications;

      const payloadQualification = {
        idEnrollment: this.props.enrollData.idEnrollment,
        currentStep: ENROLLMENT_STEPS.FILL,
        qualifications: qualifications,
      };

      //  invia al server (array di userEnrollmentQualification)
      function myFunction() {
        return new Promise((resolve, reject) => {
          enrollmentService
            .updateEnrollmentQualification(payloadQualification)
            .then((response) => {
              console.log("response", response);
              resolve();
            })
            .catch((errors) => {
              validationList.push(
                "Errore imprevisto nell'invio dei dati. Se il problema persiste contattare il supporto tecnico."
              );
              networkErrorHelper.notify(errors);
              isValid = false;
            });
        });
      }

      // Call the function and await the result
      myFunction()
        .then((response) => {
          console.log("response", response);
        })
        .catch((errors) => {
          validationList.push(
            "Errore imprevisto nell'invio dei dati. Se il problema persiste contattare il supporto tecnico."
          );
          networkErrorHelper.notify(errors);
          isValid = false;
        });
    }

    let validationSummary = "";

    if (validationList.length > 0) {
      validationSummary =
        "<ul><li>" + validationList.join("</li><li>") + "</li></ul>";
    }

    this.setState({ validationSummary });
    return isValid;
  };

  getPayload = () => {
    return this.payload;
  };

  loadRemote(additionalState = {}) {
    const { currentCompetitionClass } = this.state;

    /** get all remote qualificationTitleEntry by competitionClass */
    enrollmentService
      .getQualificationTitleEntry(currentCompetitionClass)
      .then(({ data }) => {
        const tmpArr = data.payload.titles;
        const paylodCurrentTitles = [];
        const paylodLaureandoTitles = [];

        for (let i = 0; i < tmpArr.length; i++) {
          let tmpTitle = tmpArr[i].title;
          let hasEnd = false;

          if (tmpArr[i].achievementDateStart !== null) {
            tmpTitle +=
              " - conseguita dopo il " +
              dateHelper.toITDate(tmpArr[i].achievementDateStart);
          }
          if (tmpArr[i].achievementDateEnd !== null) {
            hasEnd = true;
            tmpTitle +=
              " - conseguita entro il " +
              dateHelper.toITDate(tmpArr[i].achievementDateEnd);
          }
          if (tmpArr[i].aaStart !== null) {
            tmpTitle +=
              " - conseguita a partire dall'anno Accademico " +
              tmpArr[i].aaStart;
          }
          if (tmpArr[i].aaEnd !== null) {
            hasEnd = true;
            tmpTitle +=
              " - conseguita entro l'anno Accademico " + tmpArr[i].aaEnd;
          }

          if (!hasEnd) {
            paylodCurrentTitles.push({
              value: tmpArr[i].title,
              label: tmpArr[i].title,
            });

            if (
              tmpArr[i].extCode === "LAU_02_270" ||
              tmpArr[i].extCode === "LAU_CU_270"
            ) {
              paylodLaureandoTitles.push({
                value: tmpArr[i].title,
                label: tmpArr[i].title,
              });
            }
          }

          this.PAYLOADS.titleCheckBoxList.push({
            qualificationType: tmpArr[i].title,
            label: tmpTitle,
            value: tmpArr[i].id,
            achievementDateStart: tmpArr[i].achievementDateStart,
            achievementDateEnd: tmpArr[i].achievementDateEnd,
            aaStart: tmpArr[i].aaStart,
            aaEnd: tmpArr[i].aaEnd,
            extCode: tmpArr[i].extCode,
            note: tmpArr[i].note,
          });
        }

        // Prepare refs to form dynamically
        const formRefTitle = [];
        this.PAYLOADS.titleCheckBoxList.forEach((item) => {
          formRefTitle[item.value.toString()] = React.createRef();
        });

        formRefTitle[this.TITNONABILIT__EST] = React.createRef();

        const newState = {
          loading: false,
          ...additionalState,
          _formRef_1_1: formRefTitle,
          _formRef2_1_1: React.createRef(),
          _formRef_1_2: React.createRef(),
          _formRef_1_3: React.createRef(),
          _formRef_1_4_lav: React.createRef(),
          titleCheckBoxList: { options: this.PAYLOADS.titleCheckBoxList },
          paylodCurrentTitles,
          paylodLaureandoTitles,
        };

        this.setState(newState);
      })
      .catch((errors) => {
        console.log(errors);
        networkErrorHelper.notify(errors);
      });
  }

  onChangeStep1Active = (id, value) => {
    const { titleStep1Active } = this.state;
    titleStep1Active.length = 0;

    document.querySelectorAll('input[type="checkbox"]').forEach((el) => {
      if (el.id !== id) {
        el.checked = false;
      }
    });

    this.setState({ selectedCheckbox: null });

    if (value) {
      if (!titleStep1Active.includes(id)) {
        if (titleStep1Active.length === 0) {
          this.setState({ globalCheckDisable: true });
        }
        titleStep1Active.push(id);
        this.setState({ selectedCheckbox: id });
      }
    } else {
      const index = titleStep1Active.indexOf(id);
      if (index > -1) {
        titleStep1Active.splice(index, 1);
        this.setState({
          globalCheckDisable: false,
          selectedCheckbox: null,
        });
      }
    }

    this.setState({ titleStep1Active: titleStep1Active });
  };

  //--- ---
  render() {
    const {
      loading,
      _formRef_1_1,
      _formRef2_1_1,
      _formRef_1_2,
      _formRef_1_3,
      _formRef_1_4_lav,
      validationSummary,
      currentCompetitionClass,
    } = this.state;

    if (loading) return <></>;

    //--- ---
    // filter the this.PAYLOADS.titleCheckBoxList to show only the item with extCode=LAU_02
    const titles_LAU_VO = this.PAYLOADS.titleCheckBoxList.filter(
      (item) => item.extCode === "LAU_VO"
    );
    const titles_LAU_270 = this.PAYLOADS.titleCheckBoxList.filter(
      (item) => item.extCode === "LAU_02_270" || item.extCode === "LAU_CU_270"
    );
    const titles_LAU_02_509 = this.PAYLOADS.titleCheckBoxList.filter(
      (item) => item.extCode === "LAU_02_509"
    );
    const titles_AFAM_II_24 = this.PAYLOADS.titleCheckBoxList.filter(
      (item) => item.extCode === "AFAM_II_24"
    );
    const titles_ITP = this.PAYLOADS.titleCheckBoxList.filter(
      (item) => item.extCode === "ITP"
    );

    const {
      collapseOpenAFAM_II_24,
      collapseOpenITP,
      collapseOpenLAU_VO,
      collapseOpenLAU_270,
      collapseOpenLAU_02_509,
      collapseOpenTITNONABILIT__EST,
    } = this.state;

    return (
      <>
        <Row>
          <Col>
            <h6>TITOLO DI ACCESSO</h6>

            <Accordion>
              {titles_LAU_VO.length > 0 && (
                <>
                  <AccordionHeader
                    active={collapseOpenLAU_VO}
                    onToggle={() => this.toggle("LAU_VO")}
                  >
                    <h5>LAUREA VECCHIO ORDINAMENTO</h5>
                  </AccordionHeader>
                  <AccordionBody active={collapseOpenLAU_VO}>
                    {titles_LAU_VO.map((item, idx) => {
                      return (
                        <div key={`titleStep1=${item.value.toString()}`}>
                          <MCheckbox
                            onChange={this.onChangeStep1Active}
                            id={item.value.toString()}
                            name={item.value.toString()}
                            readOnly={false}
                          >
                            <p>
                              <b> {item.label}</b>
                            </p>
                          </MCheckbox>
                          {this.state.titleStep1Active.includes(
                            item.value.toString()
                          ) && (
                              <div className="m-4 p-3 border border-secondary rounded-lg">
                                <FormTitleStep1
                                  key={`titleStep1=${item.value.toString()}`}
                                  qualificationType={item.qualificationType}
                                  extCode={item.extCode}
                                  item={item}
                                  ref={_formRef_1_1[item.value.toString()]}
                                  idx={idx}
                                />
                              </div>
                            )}
                        </div>
                      );
                    })}
                  </AccordionBody>
                </>
              )}
              {titles_LAU_270.length > 0 && (
                <>
                  <AccordionHeader
                    active={collapseOpenLAU_270}
                    onToggle={() => this.toggle("LAU_270")}
                  >
                    <h5>LAUREA MAGISTRALE</h5>
                  </AccordionHeader>
                  <AccordionBody active={collapseOpenLAU_270}>
                    {titles_LAU_270.map((item, idx) => {
                      return (
                        <div key={`titleStep1=${item.value.toString()}`}>
                          <MCheckbox
                            onChange={this.onChangeStep1Active}
                            id={item.value.toString()}
                            name={item.value.toString()}
                            readOnly={false}
                          >
                            <p>
                              <b>{item.label}</b>
                            </p>
                          </MCheckbox>
                          {this.state.titleStep1Active.includes(
                            item.value.toString()
                          ) && (
                              <>
                                <div className="m-4 p-3 border border-secondary rounded-lg">
                                  <FormTitleStep1270
                                    key={`titleStep1=${item.value.toString()}`}
                                    qualificationType={item.qualificationType}
                                    item={item}
                                    ref={_formRef_1_1[item.value.toString()]}
                                    idx={idx}
                                  />
                                </div>
                              </>
                            )}
                        </div>
                      );
                    })}
                  </AccordionBody>
                </>
              )}
              {titles_LAU_02_509.length > 0 && (
                <>
                  <AccordionHeader
                    active={collapseOpenLAU_02_509}
                    onToggle={() => this.toggle("LAU_02_509")}
                  >
                    <h5>LAUREA SPECIALISTICA</h5>
                  </AccordionHeader>
                  <AccordionBody active={collapseOpenLAU_02_509}>
                    {titles_LAU_02_509.map((item, idx) => {
                      return (
                        <div key={`titleStep1=${item.value.toString()}`}>
                          <MCheckbox
                            onChange={this.onChangeStep1Active}
                            id={item.value.toString()}
                            name={item.value.toString()}
                            readOnly={false}
                          >
                            <p>
                              <b>{item.label}</b>
                            </p>
                          </MCheckbox>
                          {this.state.titleStep1Active.includes(
                            item.value.toString()
                          ) && (
                              <>
                                <div className="m-4 p-3 border border-secondary rounded-lg">
                                  <FormTitleStep1
                                    key={`titleStep1=${item.value.toString()}`}
                                    qualificationType={item.qualificationType}
                                    extCode={item.extCode}
                                    item={item}
                                    ref={_formRef_1_1[item.value.toString()]}
                                    idx={idx}
                                  />
                                </div>

                                <div className="m-4 p-3 border border-secondary rounded-lg">
                                  <h4>
                                    Informazioni sulla tua laurea triennale:
                                  </h4>
                                  <FormRef2TitleStep1
                                    key={`titleRef2Step1=${item.value.toString()}`}
                                    ref={_formRef2_1_1}
                                    idx={idx}
                                  />
                                </div>
                              </>
                            )}
                        </div>
                      );
                    })}
                  </AccordionBody>
                </>
              )}
              {titles_AFAM_II_24.length > 0 && (
                <>
                  <AccordionHeader
                    active={collapseOpenAFAM_II_24}
                    onToggle={() => this.toggle("AFAM_II_24")}
                  >
                    <h5>TITOLI AFAM</h5>
                  </AccordionHeader>
                  <AccordionBody active={collapseOpenAFAM_II_24}>
                    {titles_AFAM_II_24.map((item, idx) => {
                      return (
                        <div key={`titleStep1=${item.value.toString()}`}>
                          <MCheckbox
                            onChange={this.onChangeStep1Active}
                            id={item.value.toString()}
                            name={item.value.toString()}
                            readOnly={false}
                          >
                            <p>
                              <b>{item.label}</b>
                            </p>
                          </MCheckbox>
                          {this.state.titleStep1Active.includes(
                            item.value.toString()
                          ) && (
                              <div className="m-4 p-3 border border-secondary rounded-lg">
                                <FormTitleStep1
                                  key={`titleStep1=${item.value.toString()}`}
                                  qualificationType={item.qualificationType}
                                  extCode={item.extCode}
                                  item={item}
                                  ref={_formRef_1_1[item.value.toString()]}
                                  idx={idx}
                                />
                              </div>
                            )}
                        </div>
                      );
                    })}
                  </AccordionBody>
                </>
              )}
              {titles_ITP.length > 0 && (
                <>
                  <AccordionHeader
                    active={collapseOpenITP}
                    onToggle={() => this.toggle("ITP")}
                  >
                    <h5>DIPLOMA</h5>
                  </AccordionHeader>
                  <AccordionBody active={collapseOpenITP}>
                    {titles_ITP.map((item, idx) => {
                      return (
                        <div key={`titleStep1=${item.value.toString()}`}>
                          <MCheckbox
                            onChange={this.onChangeStep1Active}
                            id={item.value.toString()}
                            name={item.value.toString()}
                            readOnly={false}
                          >
                            <p>
                              <b> {item.label}</b>
                            </p>
                          </MCheckbox>
                          {this.state.titleStep1Active.includes(
                            item.value.toString()
                          ) && (
                              <div className="m-4 p-3 border border-secondary rounded-lg">
                                <FormTitleITPStep1
                                  key={`titleStep1=${item.value.toString()}`}
                                  qualificationType={item.qualificationType}
                                  extCode={item.extCode}
                                  item={item}
                                  ref={_formRef_1_1[item.value.toString()]}
                                  idx={idx}
                                />
                              </div>
                            )}
                        </div>
                      );
                    })}
                  </AccordionBody>
                </>
              )}
              {currentCompetitionClass !== "B012" && (
                <>
                  <AccordionHeader
                    active={collapseOpenTITNONABILIT__EST}
                    onToggle={() => this.toggle("TITNONABILIT__EST")}
                  >
                    <h5>TITOLO ESTERO</h5>
                  </AccordionHeader>
                  <AccordionBody active={collapseOpenTITNONABILIT__EST}>
                    {collapseOpenTITNONABILIT__EST && (
                      <>
                        <DichiarazioneTitoloEstero
                          qualificationType="title11"
                          extCode="TITNONABILIT__EST"
                          ref={_formRef_1_1[this.TITNONABILIT__EST]}
                          competitionClass={
                            this.props.enrollData.pluginConfig.competitionClass
                          }
                          payloadTitle={this.state.paylodCurrentTitles}
                        />
                      </>
                    )}
                  </AccordionBody>
                </>
              )}
            </Accordion>
          </Col>
        </Row>
        <hr />
        <Row className="mt-2">
          <Col>
            <h6>ULTERIORI DICHIARAZIONI PER LA CLASSE DI CONCORSO</h6>
            <DichiarazioniUlteriori60CFU
              ref={_formRef_1_2}
              competitionClass={
                this.props.enrollData.pluginConfig.competitionClass
              }
            />
          </Col>
        </Row>

        <hr />
        <Row className="mt-2">
          <Col>
            <h6>REQUISITO 24 CFU</h6>

            <Dichiarazione24CFUon60CFU
              ref={_formRef_1_3}
              competitionClass={
                this.props.enrollData.pluginConfig.competitionClass
              }
              enrollmentID={this.props.enrollData.idEnrollment}
            />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h6>REQUISITO PROFESSIONALE</h6>
            <TitolareContrattoVincitore
              ref={_formRef_1_4_lav}
              competitionClass={
                this.props.enrollData.pluginConfig.competitionClass
              }
            />
          </Col>
        </Row>

        <Row>
          <div className="w-100">
            {validationSummary && (
              <div className="alert alert-danger mt-3" role="alert">
                {ReactHtmlParser(validationSummary)}
              </div>
            )}
          </div>
        </Row>
      </>
    );
  }
}
