const { MButton2, MInput } = require("components/forms");

export class FormTitleStep2Configurator {

  static extCode = {  
    LaureaTriennale: "LAU_03",
    DiplomaAccademicoPrimolivello: "RIS_DIPL_PRIM_LIV",
    LaureaVO:"LAU_VO",   
    LaureaLS:"LAU_02_509",
    DiplomaAccademicoVecchioOrdinamentoO2lvl: "RIS_LAU_PRIM_LIV",
    LaureaLM_CU: "LAU_CU_270",
    LaureaLM_02:"LAU_02_270",
  

    DottoratoRicerca: "DOTT",
    MasterUniversitariAccademici: "MAST_RIS_II"   , 
    DiplomaSpecPerfStCecilia: "DIPLOMA_SPEC_PERF_ACCAD_SC",
    certLingueStraniere2012: "CERT_LINGUISTICA",
    DichiarazioneRiservisti: "TODO_AVETA",
    
    TitolareContratto: "TITOLARE_CONTRATTO",
    VincitoreProvaBis: "PROVA_CONCORSUALE_BIS",

    title13_24CFU: "TODO_AVETA",
    title13_INSEGNAMENTI: "TODO_AVETA",
  };

  static forms = [   
    {
      template: "LaureaTriennale",
      label: `Laurea Triennale`,
      extCode:"LAU_03"
    },
    {
      template: "DiplomaAccademicoPrimolivello",
      label: `Diploma Accademico di Primo livello`,
      extCode:"RIS_DIPL_PRIM_LIV"
    },
    {
      template: "LaureaVO",
      label: `Laurea Vecchio ordinamento`,
      extCode:"LAU_VO"
    },
    {
      template: "LaureaLS",
      label: `Laurea Specialistica`,
      extCode:"LAU_02_509"
    },
    {
      template: "LaureaLM_02",
      label: `Laurea Magistrale Biennale (DM 270/2004 )`,
      extCode:"LAU_02_270"
    },
    {
      template: "LaureaLM_CU",
      label: `Laurea Magistrale a ciclo unico (DM 270/2004)`,
        extCode:"LAU_CU_270"
    },
    {
      template: "DiplomaAccademicoVecchioOrdinamentoO2lvl",
      label: `Diploma Accademico di Vecchio Ordinamento o Diploma Accademico di secondo livello`,
      extCode:"RIS_LAU_PRIM_LIV"
    },   
  ];

  static LaureaTriennale = [
    [
      {
        id: "searchUniversity",
        component: MButton2,
        onClick: () => {
          console.error("errore: event unhandled");
        },
        label: "Cerca Ateneo",
        className: "col-md-12 mb-3",
        classElement: "btn btn-primary bg-dark",
      },
    ],
    [
      {
        id: "istitution",
        field: "istitution",
        label: "Presso",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
      {
        id: "city",
        field: "city",
        label: "Città",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
    ],
    [
      {
        id: "description003",
        field: "description",
        label: "Laurea triennale in",
        component: MInput,
        type: "text",
        className: "col-md-12",
      },
    ],
    [
      {
        id: "aa003",
        field: "aa",
        label: "Anno Accademico conseguimento Titolo",
        component: MInput,
        infoText: "inserire formato AAAA/AAAA",
        className: "col-md-4",
      },
      {
        id: "dataConseguimento003",
        field: "dataConseguimento",
        label: "Data conseguimento titolo",
        component: MInput,
        type: "date",
        className: "col-md-4",
      },
      {
        id: "votoTitolo003",
        field: "votoTitolo",
        label: "Votazione finale",
        component: MInput,
        type: "text",
        className: "col-md-4",
      },
    ],
  ];

  static dv_LaureaTriennale = {
    description: "",
    istitution: "",
    city: "",
    aa: "",
    dataConseguimento: "",
    votoTitolo: "",
  };

  static DiplomaAccademicoPrimolivello = [
    [
      {
        id: "searchUniversity",
        component: MButton2,
        onClick: () => {
          console.error("errore: event unhandled");
        },
        label: "Cerca Ateneo",
        className: "col-md-12 mb-3",
        classElement: "btn btn-primary bg-dark",
      },
    ],
    [
      {
        id: "istitution",
        field: "istitution",
        label: "Presso",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
      {
        id: "city",
        field: "city",
        label: "Città",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
    ],
    [
      {
        id: "description004",
        field: "description",
        label: "Denominazione",
        component: MInput,
        type: "text",
        className: "col-md-12",
      },
    ],
    [
      {
        id: "aa004",
        field: "aa",
        label: "Anno Accademico conseguimento Titolo",
        component: MInput,
        infoText: "inserire formato AAAA/AAAA",
        className: "col-md-4",
      },
      {
        id: "dataConseguimento004",
        field: "dataConseguimento",
        label: "Data conseguimento titolo",
        component: MInput,
        type: "date",
        className: "col-md-4",
      },
      {
        id: "votoTitolo004",
        field: "votoTitolo",
        label: "Votazione finale",
        component: MInput,
        type: "text",
        className: "col-md-4",
      },
    ],
  ];

  static dv_DiplomaAccademicoPrimolivello = {
    description: "",
    istitution: "",
    city: "",
    aa: "",
    dataConseguimento: "",
    votoTitolo: "",
  };

  static LaureaVO = [
    [
      {
        id: "searchUniversity",
        component: MButton2,
        onClick: () => {
          console.error("errore: event unhandled");
        },
        label: "Cerca Ateneo",
        className: "col-md-12 mb-3",
        classElement: "btn btn-primary bg-dark",
      },
    ],
    [
      {
        id: "istitution",
        field: "istitution",
        label: "Presso",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
      {
        id: "city",
        field: "city",
        label: "Città",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
    ],
    [
      {
        id: "description005",
        field: "description",
        label:
          "Laurea di vecchio ordinamento in:",
        component: MInput,
        type: "text",
        className: "col-md-12",
      },
    ],
    [
      {
        id: "aa005",
        field: "aa",
        label: "Anno Accademico conseguimento Titolo",
        component: MInput,
        infoText: "inserire formato AAAA/AAAA",
        className: "col-md-4",
      },
      {
        id: "dataConseguimento005",
        field: "dataConseguimento",
        label: "Data conseguimento titolo",
        component: MInput,
        type: "date",
        className: "col-md-4",
      },
      {
        id: "votoTitolo005",
        field: "votoTitolo",
        label: "Votazione finale",
        component: MInput,
        type: "text",
        className: "col-md-4",
      },
    ],
  ];

  static dv_LaureaVO = {
    description: "",
    istitution: "",
    city: "",
    aa: "",
    dataConseguimento: "",
    votoTitolo: "",
  };

  static LaureaLS = [
    [
      {
        id: "searchUniversity",
        component: MButton2,
        onClick: () => {
          console.error("errore: event unhandled");
        },
        label: "Cerca Ateneo",
        className: "col-md-12 mb-3",
        classElement: "btn btn-primary bg-dark",
      },
    ],
    [
      {
        id: "istiution",
        field: "istitution",
        label: "Presso",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
      {
        id: "city",
        field: "city",
        label: "Città",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
    ],
    [
      {
        id: "description006",
        field: "description",
        label:
          "Laurea specialistica in:",
        component: MInput,
        type: "text",
        className: "col-md-12",
      },
    ],
    [
      {
        id: "aa006",
        field: "aa",
        label: "Anno Accademico conseguimento Titolo",
        component: MInput,
        infoText: "inserire formato AAAA/AAAA",
        className: "col-md-4",
      },
      {
        id: "dataConseguimento006",
        field: "dataConseguimento",
        label: "Data conseguimento titolo",
        component: MInput,
        type: "date",
        className: "col-md-4",
      },
      {
        id: "votoTitolo006",
        field: "votoTitolo",
        label: "Votazione finale",
        component: MInput,
        type: "text",
        className: "col-md-4",
      },
    ],
  ];

  static dv_LaureaLS = {
    description: "",
    istitution: "",
    city: "",
    aa: "",
    dataConseguimento: "",
    votoTitolo: "",
  };

  static LaureaLM_02 = [
    [
      {
        id: "searchUniversity",
        component: MButton2,
        onClick: () => {
          console.error("errore: event unhandled");
        },
        label: "Cerca Ateneo",
        className: "col-md-12 mb-3",
        classElement: "btn btn-primary bg-dark",
      },
    ],
    [
      {
        id: "istitution",
        field: "istitution",
        label: "Presso",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
      {
        id: "city",
        field: "city",
        label: "Città",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
    ],
    [
      {
        id: "description007",
        field: "description",
        label:
          "Laurea magistrale in:",
        component: MInput,
        type: "text",
        className: "col-md-12",
      },
    ],
    [
      {
        id: "aa007",
        field: "aa",
        label: "Anno Accademico conseguimento Titolo",
        component: MInput,
        infoText: "inserire formato AAAA/AAAA",
        className: "col-md-4",
      },
      {
        id: "dataConseguimento007",
        field: "dataConseguimento",
        label: "Data conseguimento titolo",
        component: MInput,
        type: "date",
        className: "col-md-4",
      },
      {
        id: "votoTitolo007",
        field: "votoTitolo",
        label: "Votazione finale",
        component: MInput,
        type: "text",
        className: "col-md-4",
      },
    ],
  ];

  static dv_LaureaLM_02 = {
    description: "",
    istitution: "",
    city: "",
    aa: "",
    dataConseguimento: "",
    votoTitolo: "",
  };

  static LaureaLM_CU = [
    [
      {
        id: "searchUniversity",
        component: MButton2,
        onClick: () => {
          console.error("errore: event unhandled");
        },
        label: "Cerca Ateneo",
        className: "col-md-12 mb-3",
        classElement: "btn btn-primary bg-dark",
      },
    ],
    [
      {
        id: "istitution",
        field: "istitution",
        label: "Presso",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
      {
        id: "city",
        field: "city",
        label: "Città",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
    ],
    [
      {
        id: "description009",
        field: "description",
        label:
          "Laurea magistrale in:",
        component: MInput,
        type: "text",
        className: "col-md-12",
      },
    ],
    [
      {
        id: "aa009",
        field: "aa",
        label: "Anno Accademico conseguimento Titolo",
        component: MInput,
        infoText: "inserire formato AAAA/AAAA",
        className: "col-md-4",
      },
      {
        id: "dataConseguimento009",
        field: "dataConseguimento",
        label: "Data conseguimento titolo",
        component: MInput,
        type: "date",
        className: "col-md-4",
      },
      {
        id: "votoTitolo009",
        field: "votoTitolo",
        label: "Votazione finale",
        component: MInput,
        type: "text",
        className: "col-md-4",
      },
    ],
  ];

  static dv_LaureaLM_CU = {
    description: "",
    istitution: "",
    city: "",
    aa: "",
    dataConseguimento: "",
    votoTitolo: "",
  };

  static DiplomaAccademicoVecchioOrdinamentoO2lvl = [
    [
      {
        id: "searchUniversity",
        component: MButton2,
        onClick: () => {
          console.error("errore: event unhandled");
        },
        label: "Cerca Ateneo",
        className: "col-md-12 mb-3",
        classElement: "btn btn-primary bg-dark",
      },
    ],
    [
      {
        id: "istitution",
        field: "istitution",
        label: "Presso",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
      {
        id: "city",
        field: "city",
        label: "Città",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
    ],
    [
      {
        id: "description008",
        field: "description",
        label:
          "Diploma accademico di vecchio ordinamento in:",
        component: MInput,
        type: "text",
        className: "col-md-12",
      },
    ],
    [
      {
        id: "aa008",
        field: "aa",
        label: "Anno Accademico conseguimento Titolo",
        component: MInput,
        infoText: "inserire formato AAAA/AAAA",
        className: "col-md-4",
      },
      {
        id: "dataConseguimento008",
        field: "dataConseguimento",
        label: "Data conseguimento titolo",
        component: MInput,
        type: "date",
        className: "col-md-4",
      },
      {
        id: "votoTitolo008",
        field: "votoTitolo",
        label: "Votazione finale",
        component: MInput,
        type: "text",
        className: "col-md-4",
      },
    ],
  ];

  static dv_DiplomaAccademicoVecchioOrdinamentoO2lvl = {
    description: "",
    istitution: "",
    city: "",
    aa: "",
    dataConseguimento: "",
    votoTitolo:0,
  };

  //--- ---

  static getDefaultValues(template) {
    return this["dv_" + template];
  }

  static getFields(template, onSelectedUniversity, onSelectedSchool) {
    const fieldGroup = this[template];

    if (fieldGroup) {
      if (fieldGroup[0] && fieldGroup[0][0].id === "searchUniversity") {
        fieldGroup[0][0].onClick = onSelectedUniversity;
      } else if (fieldGroup[0] && fieldGroup[0][0].id === "searchSchool") {
        fieldGroup[0][0].onClick = onSelectedSchool;
      }
    } else {
      console.error(template + " not found");
    }
    return fieldGroup;
  }

  static getExtCode(template) {
    return this.extCode[template];
  }
}
