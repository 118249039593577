import React, { Component } from 'react';
import { MCheckbox } from 'components/forms';
import { courseService, stringHelper } from 'libs';
import { Button } from 'design-react-kit';

export class FormRCSU extends Component {

    importBollo = 16;
    importSegreteria = 50;
    importForCFU = 35;
    importToPay = 66;
    importToPayTotal = 0;

    state = {
        courses: [],
        coursesSubcribe: [],
        labelInfo: '',
        validationError: '',
    }

    loadRemote(courseCode) {

        courseService.getCourseModules(courseCode)
            .then(({ data }) => {
                this.setState({
                    courses: data.payload,
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    componentDidMount() {

        let { courseCode } = this.props;
        this.loadRemote(courseCode);
    }

    getValue() {

        const pluginValue = {
            courses: this.state.coursesSubcribe.join(','),
        };

        return pluginValue;
    }

    checkValidation() {

        let validationError = '';
        let isValid = true;

        if (this.state.coursesSubcribe.length === 0) {
            isValid = false;
            validationError = 'Devi iscriverti ad almeno un corso';
        } else {

            const cfu = this.getCFUSubscribed(this.state.coursesSubcribe);

            if (cfu > 60) {
                isValid = false;
                validationError = 'Puoi iscriverti al massimo a 60 CFU. La tua richiesta equivale a ' + cfu + ' CFU';
            }
        }

        this.setState({ validationError })

        return (isValid);
    }

    removeSubscribe = (courseId) => {

        //remove course
        const coursesSubcribe = [...this.state.coursesSubcribe]; // make a separate copy of the array
        const index = coursesSubcribe.indexOf(courseId)
        if (index !== -1) {
            coursesSubcribe.splice(index, 1);
            this.setState({ coursesSubcribe });
        }

        this.updateImportToPay(coursesSubcribe);
        this.updateInfo(coursesSubcribe);
    }

    onClick = (id, value) => {

        if (value) {
            // add course
            const newSubcribe = this.state.coursesSubcribe.concat([id]);
            this.setState({ coursesSubcribe: newSubcribe });
            this.updateImportToPay(newSubcribe);
            this.updateInfo(newSubcribe);
        } else {
            //remove course
            const coursesSubcribe = [...this.state.coursesSubcribe]; // make a separate copy of the array
            const index = coursesSubcribe.indexOf(id)
            if (index !== -1) {
                coursesSubcribe.splice(index, 1);
                this.setState({ coursesSubcribe });
            }

            this.updateImportToPay(coursesSubcribe);
            this.updateInfo(coursesSubcribe);
        }
    }

    getCFUSubscribed(coursesSubcribe) {
        // check max 60 CFU
        let cfu = 0;
        this.state.courses.forEach(course => {
            if (coursesSubcribe.includes(course.id)) {
                cfu += parseInt(course.cfu);
            }
        });

        return cfu;
    }

    updateInfo(coursesSubcribe) {

        let info = '';
        let cfu = 0;
        this.state.courses.forEach(course => {
            if (coursesSubcribe.includes(course.id)) {
                cfu += parseInt(course.cfu);
            }
        });

        if (cfu > 0) {
            const className = cfu > 60 ? 'text-danger' : 'text-green';
            info = '<p class="' + className + '">Hai scelto i seguenti corsi per un totale di ' + cfu + ' CFU</p>';
        }

        this.setState({ labelInfo: info });
    }

    updateImportToPay(coursesSubcribe) {

        // update import and change it
        const payload = [];
        const promoAmount = this.props.promoAmount ? this.props.promoAmount : 0;
        const cfu = this.getCFUSubscribed(coursesSubcribe);

        if (cfu > 0) {
            payload.push({ value: this.importToPay, label: 'Prima rata ' + stringHelper.formatEuro(this.importToPay) })

            this.importToPayTotal = (this.importForCFU * cfu) - promoAmount + this.importBollo + this.importSegreteria;
            this.props.onChangeImport(payload);
        }

    }

    render() {

        return (

            <>
                <h6>Corsi singoli universitari proposti (totali CFU acquisibili per a.a. massimo 60)</h6>
                <hr />
                <div className='card-wrapper -ml-10'>
                    <table className="MTable table  table-striped table-hover" role="table">
                        <thead>
                            <tr >
                                <th>Titolo</th>
                                <th>SSD</th>
                                <th >CFU</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.courses.map((course, index) => (
                                <tr key={index} >
                                    <td>
                                        <MCheckbox
                                            className='-ml-10'
                                            value={this.state.coursesSubcribe.includes(course.id)}
                                            onChange={this.onClick}
                                            id={course.id}
                                            name={course.id}>
                                            {course.title}
                                        </MCheckbox>

                                    </td>
                                    <td >{course.ssd}</td>
                                    <td>{course.cfu}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <hr />
                {this.state.labelInfo && (<div dangerouslySetInnerHTML={{ __html: this.state.labelInfo }} />)}
                {
                    /* show the list of the coursesSubcribe */
                    this.state.coursesSubcribe.length > 0 && (
                        <div >
                            <table>
                                {this.state.courses.map((course, index) => (
                                    this.state.coursesSubcribe.includes(course.id) && (
                                        <tr key={index}>
                                            <td> {course.title}</td>
                                            <td className='px-3'>{course.ssd}</td>
                                            <td >{course.cfu} CFU</td>
                                            <td><Button color="danger" className="mx-3 my-1" size="xs" outline onClick={e => this.removeSubscribe(course.id)}>Elimina</Button> </td>
                                        </tr>
                                    )
                                ))}
                            </table>
                            <div className="alert alert-success fade show" role="alert"><p>Totale da pagare {stringHelper.formatEuro(this.importToPayTotal)}</p></div>

                        </div>

                    )
                }
                <hr />
                {this.state.validationError && (<span className="form-text text-danger is-invalid">{this.state.validationError}</span>)}
            </>
        );
    }
}