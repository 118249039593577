import React from "react";
import { EnrollPayForm } from "./EnrollPayForm";
import { EnrollPayPagoPaForm } from "./EnrollPayPagoPaForm";
import { PAYMENT_TYPES, ENROLLMENT_STEPS, PAYMENT_STATUS, COMPANY } from 'const';
import { networkErrorHelper, enrollmentService, dateHelper, CourseHelper } from "libs";
import { creditCardHelper } from "libs/services";
import { EnrollPayPAgoPaOfflineForm } from "./EnrollPayPagoPaOfflineForm";

export class EnrollPay {

    title = 'Pagamento';
    iconName = 'it-card';
    userProfile = null;
    enrollment = null;
    payment = null;
    course = null;
    canNext = false;
    _formRef = null;

    constructor() {

        this._formRef = React.createRef();
    }

    setPayload(payload) {
        this.enrollment = payload.enrollment;
        this.course = payload.course;
        this.user = payload.user;
        this.userProfile = payload.userProfile;
        this.payment = payload.payment;
    }

    async onNextStep() {

        if (this.enrollment.company === COMPANY.TCHAIKOVSKY){
            this.canNext = this._formRef.current.canNext()
        } else if ((this.enrollment.company === COMPANY.UNICAMILLUS || this.enrollment.company === COMPANY.IUL ) 
            && this.enrollment.paymentType === PAYMENT_TYPES.PAGOPA) {
            this.canNext = false;
        } else {
            let isValid = true;
            if (this.enrollment.paymentStatus !== PAYMENT_STATUS.APPROVED) {

                isValid = this._formRef.current.onSubmit();

                if (isValid) {
                    this.payloadForm = this._formRef.current.getPayload();

                    const updated = await this.updateRemote();

                    // if credit card go to payment site
                    if (updated && this.enrollment.paymentType === PAYMENT_TYPES.CARTA_DI_CREDITO) {
                        this.canNext = false;
                        creditCardHelper.startPayment(this.user.email, this.enrollment.orderNumber
                            , this.enrollment.importToPay, 'userEnrollmentPayment', this.enrollment.company);
                    } else {
                        this.canNext = updated;
                    }
                }
            }
        }
        return this.canNext;
    }

    async updateRemote() {

        let retCode = false;

        this.enrollment.paymentType = this.payloadForm.paymentType;
        this.enrollment.datePayment = this.payloadForm.datePayment;
        this.enrollment.paymentBancSource = this.payloadForm.paymentBancSource;

        if (this.payloadForm.paymentType === PAYMENT_TYPES.CARTA_DI_CREDITO) {
            this.enrollment.paymentStatus = PAYMENT_STATUS.STARTED;

        } else {
            this.enrollment.paymentStatus = PAYMENT_STATUS.TOAPPROVE;
            if (CourseHelper.isDSII(this.enrollment.courseCode)) {
                this.enrollment.currentStep = ENROLLMENT_STEPS.SIGN;
            }
            else if (CourseHelper.isBUII(this.enrollment.courseCode)) {

                this.enrollment.currentStep = (this.payloadForm.paymentType === PAYMENT_TYPES.CARTA_DEL_DOCENTE) ? ENROLLMENT_STEPS.SIGN : ENROLLMENT_STEPS.END;

            }
            else {
                if ((this.enrollment.paymentType === PAYMENT_TYPES.CARTA_DEL_DOCENTE)
                    || (this.enrollment.paymentType === PAYMENT_TYPES.BONIFICO)
                    || this.enrollment.company === COMPANY.TCHAIKOVSKY
                ) {
                    this.enrollment.currentStep = (this.course.fillform && this.course.fillform.length > 0) ? ENROLLMENT_STEPS.FILL : ENROLLMENT_STEPS.SIGN;
                } else {
                    this.enrollment.currentStep = ENROLLMENT_STEPS.END
                }

            }
        }

        if (this.enrollment.company !== COMPANY.IUL  
            && this.enrollment.company !== COMPANY.UNICAMILLUS 
            && this.enrollment.company !== COMPANY.TCHAIKOVSKY) {

            this.enrollment.company = COMPANY.ASSOCIAZIONE;
            /**
             *   DAL 13.02.2023 TUTTE LE ISCRIZIONI VANNO SU ASSOCIAZIONE       
             * 
            if (this.payloadForm.paymentType === PAYMENT_TYPES.CARTA_DEL_DOCENTE) {
                this.enrollment.company = COMPANY.ASSOCIAZIONE;
            } else {
                this.enrollment.company = COMPANY.SRL;
            }
            */
        }

        await enrollmentService.updatePayment(this.enrollment)
            .then(() => {

                // if BU-II reload the wizard to update steps 
                if (CourseHelper.isBUII(this.enrollment.courseCode)) {
                    window.location.reload();
                }

                retCode = true;
            })
            .catch(errors => {
                console.log(errors);
                this.canNext = false;
                networkErrorHelper.notify(errors);
            });

        return retCode;
    };

    renderPaymentData() {

        const datePayment = dateHelper.toITDateTime(this.payment.datePaied);
        const importPaied = this.payment.import;

        return (<>
            <p>Il pagamento della tua domanda di iscrizione risulta regolarmnente confermato.
                Premi sul pulsante <b>AVANTI</b> per concludere la domanda di iscrizione:</p>
            <ul>
                <li><b>Modalità di pagamento: </b> {this.enrollment.paymentType}</li>
                <li><b>Importo pagato: </b> {importPaied}</li>
                <li><b>Data del pagamento:</b>  {datePayment}</li>
            </ul>
        </>);
    }

    render() {

        const isOK = (this.enrollment.paymentStatus === PAYMENT_STATUS.SELFAPPROVED || this.enrollment.paymentStatus === PAYMENT_STATUS.APPROVED) ? true : false;
        if (isOK) {
            this.renderPaymentData();
        } else {
            const enrollData =
            {
                courseCode: this.course.code,
                importToPay: this.enrollment.importToPay,
                paymentType: this.enrollment.paymentType,
                isPaymentReferred: this.enrollment.isPaymentReferred,
                document: this.payment.id,
                fiscalCode: this.userProfile.fiscalCode,
                paymentBancSource: this.payment.paymentBancSource,
                datePayment: this.payment.datePayment,
                idPayment: this.payment.id,
                orderNumber: this.enrollment.orderNumber,
                id: this.enrollment.id
            }

            return (
                <>
                    <div className="pt-5">
                        {(this.enrollment.company === COMPANY.TCHAIKOVSKY) ?
                            (<><EnrollPayPAgoPaOfflineForm
                                ref={this._formRef}
                                onSaveRemote={this.onSaveRemote}
                                enrollData={enrollData} />
                            </>) :
                            (<>
                                {(this.enrollment.paymentType === PAYMENT_TYPES.PAGOPA) ? (<>
                                    <EnrollPayPagoPaForm
                                        ref={this._formRef}
                                        onSaveRemote={this.onSaveRemote}
                                        enrollData={enrollData} />
                                </>) : (<>
                                    <EnrollPayForm
                                        ref={this._formRef}
                                        onSaveRemote={this.onSaveRemote}
                                        enrollData={enrollData} />
                                </>)}
                            </>
                            )

                        }

                    </div>

                </>);
        }
    }
}