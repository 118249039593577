import { ValidatedForm } from "components/forms/ValidatedForm";
import React from "react";

import {
    payloadBuilder,
    userService,
    invalidFieldsMapper,
    networkErrorHelper,
    notNullObjectBuilder
} from "libs";

import {
    MInput,
    MAddress
} from "components/forms/";

import { Button, Card, CardBody, Icon } from "design-react-kit";
import { FormEnabler } from "components/forms/shared/FormEnabler";
import { BoxInfo,  ModalHandler, MSection, Title } from 'components/misc';

export class UserInvoiceData extends ValidatedForm {

    ERROR_MESSAGES = {
        companyName: "Inserire un nome azienda",
        VATCode: "Inserire una partita iva valida",
        addressId: "indirizzo non valido",
        fiscalCode: 'Codice fiscale non valido',
        destinationCode: 'codice destinaraio non valido (7 caratteri)'
    };

    emptyFields = {
        companyName: '',
        companySurname: '',
        fiscalCode: '',
        destinationCode: '',
        VATCode: '',
        pec: '',
        addressId: 0,
    };

    state = {
        isForeignAddress: false,
        hasInvoiceAddress: false,
        loading: true,
        ...this.emptyFields,
        defaultValues: {
            ...this.emptyFields
        }
    };

    onChangeForeign = (name, value) => {
        if (name === 'isForeign') {
            this.setState({ isForeignAddress: value })
        }
    }

    validateVAT(value) {

        if (this.state.isForeignAddress) {
            return (value !== null && value.length > 5);
        }

        return /^(\d{11})$/.test(value)
    }

    FIELDS_GROUP = [
        [
            {
                id: "companyName",
                field: "companyName",
                label: "Nome Azienda o nome persona fisica",
                component: MInput,
                type: "text",
                className: "col-md-6"
            },
            {
                id: "companySurname",
                field: "companySurname",
                label: "Cognome (in caso di persona fisica)",
                component: MInput,
                type: "text",
                className: "col-md-6"
            }
        ],
        [
            {
                id: "VATCode",
                field: "VATCode",
                label: "Partita IVA o Codice Fiscale aziendale",
                component: MInput,
                type: "text",
                className: "col-md-6"
            },
            {
                id: "destinationCode",
                field: "destinationCode",
                label: "Codice Destinatario",
                component: MInput,
                type: "text",
                className: "col-md-6"
            }
        ],
        [
            {
                id: "pec",
                field: "pec",
                label: "PEC",
                component: MInput,
                type: "email",
                className: "col-md-12"
            }
        ],
        [
            {
                id: "addressId",
                name: "addressId",
                field: "addressId",
                label: "Indirizzo",
                component: MAddress,
                otherEvents: this.onChangeForeign,
                className: "col-md-12"
            }
        ]
    ];

    validation = {
        companyName: value => value !== null && value.length > 2,
        VATCode: value => this.validateVAT(value),
        //addressId: value => value > 0,
        pec: value => value === null || value.length === 0 || /\S+@\S+\.\S+/.test(value),
        //fiscalCode: value => value === null || value.length === 0 || /^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$|([0-9]{11})$/.test(value),
        destinationCode: value => value === null || value.length === 0 || value.length === 7,

    };

    constructor(props) {
        super(props);

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    loadRemote(additionalState = {}) {
        userService
            .invoiceData()
            .then(({ data }) => {

                // merge not null values and format dates
                const defaultValues = notNullObjectBuilder(
                    this.emptyFields,
                    data.payload.invoiceData,
                );

                const newState = {
                    hasInvoiceAddress: true,
                    defaultValues,
                    loading: false,
                    ...additionalState,
                };

                if (data.payload.invoiceAddress && data.payload.invoiceAddress.id) {
                    newState.isForeignAddress = data.payload.invoiceAddress.isForeign;
                }

                this.setState(newState);
            })
            .catch(error => {
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                    this.setState({ loading: false });
                } else {
                    this.setState({ loading: false, hasInvoiceAddress: false });
                }
            });
    }

    saveRemote = () => {

        const payload = payloadBuilder(this.state);

        userService.updateInvoiceData(payload)
            .then(() => {

                const validations = {
                    companyName: null,
                    VATCode: null,
                }

                this.setState({ formActive: false, ...validations });
            })
            .catch(errors => {

                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        this.ERROR_MESSAGES
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });
    };

    IAInsert = () => {

        userService.createInvoiceData()
            .then(({ data }) => {

                // merge not null values and format dates
                const defaultValues = notNullObjectBuilder(
                    this.emptyFields,
                    data.payload.invoiceData,
                );

                const newState = {
                    hasInvoiceAddress: true,
                    defaultValues,
                    formActive: true
                };

                this.setState(newState);
            })
            .catch(errors => {

                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        this.ERROR_MESSAGES
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });
    }

    onIARemove = () => {
        ModalHandler.show({ value: 1, status: true }, 'Eliminazione dati fatturazione',
            'vuoi davvero eliminare i dati di fatturazione?', null, this.IARemove);
    }

    IARemove = () => {

        this.setState({ hasInvoiceAddress: false });

        // delete from server
        userService
            .deleteInvoiceData()
            .then(() => {

                const newState = {
                    hasInvoiceAddress: false,
                    ...this.emptyFields,
                    loading: false,
                    defaultValues: {
                        ...this.emptyFields
                    }
                };

                this.setState(newState);
            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
                this.setState({ loading: false });
            });
    }

    renderNOInvoiceData() {

        return (
            <>
                <MSection className="border-bottom-card">
                    <p>
                        Le fattura emesse, come da normativa vigente, vengono trasmesse al
                        Sistema di Interscambio (SDI) che, successivamente, le rende disponibili
                        al consumatore finale nella propria area riservata del sito web
                        dell'Agenzia delle Entrate.</p>
                    <p>
                        Inoltre, le verrà inviata via e-mail una copia della fattura
                        (c.d. fattura di cortesia), in formato PDF, che non ha alcun valore fiscale.
                        Tale comunicazione ha l'esclusivo fine di notificarle la disponibilità della
                        fattura elettronica nella sua area riservata del sito dell'Agenzia delle Entrate.
                    </p>
                    <p>Attualmente, l'emissione delle fatture è collegata al suo codice fiscale.
                        Qualora volesse modificare queste impostazioni richiedendo l'emissione ad un soggetto
                        titolare di partita iva prema il pulsante "Inserire dati di fatturazione". <br /></p>
                    <BoxInfo> Tutte le modifiche avranno effetto sulle fatture ancora da emettere.</BoxInfo>
                    <Button color="primary bg-dark" tag="button" className="mb-3" onClick={this.IAInsert} >Inserire dati di fatturazione</Button>                    
                </MSection>
            </>
        );
    }

    renderInvoiceData() {

        const { formActive, defaultValues } = this.state;
        const { formValid, code } = this.renderFields(this.FIELDS_GROUP, defaultValues, formActive);

        return (
            <>
                <MSection className="mb-4 border-bottom-card">
                    <p>
                        Le fattura emesse, come da normativa vigente, vengono trasmesse al Sistema
                        di Interscambio (SDI) che, successivamente, le inoltra con le modalità da
                        lei prescelte (pec, codice destinatario, ecc.). </p>
                    <p>
                        Inoltre, le verrà inviata via e-mail una copia della fattura (c.d. fattura di
                        cortesia), in formato PDF, che non ha alcun valore fiscale. Tale comunicazione
                        ha l'esclusivo fine di notificarle l'invio della fattura elettronica.
                    </p>
                    <p>
                        Di seguito vengono riportati i suoi dati di fatturazione che possono essere
                        modificati premendo il tasto "Modifica". In alternativa, nel caso in cui
                        voglia che la fattura sia emessa col proprio codice fiscale come consumatore
                        finale, la invitiamo a rimuovere i seguenti dati di fatturazione premendo sul
                        pulsante "Rimuovi dati di fatturazione".<br />
                        <b> Tutte le modifiche avranno effetto sulle fatture ancora da emettere.</b>
                    </p>                    
                  
                    <Button color="danger" outline tag="button" onClick={this.onIARemove} >Rimuovi dati di fatturazione</Button>
                </MSection>
                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                    <div className="flag-icon" />
                    <div className="etichetta">
                        <Icon icon="it-card" padding={false} />
                        <span>Dati Fatturazione</span>
                    </div>
                    <CardBody tag="div">
                        <form className="user" id="userDocument_form">
                            {code}
                        </form>
                        <div >
                            <FormEnabler
                                onToggle={this.toggleForm}
                                isFormActive={formActive}
                                onSubmit={this.onSubmit}
                                onCancel={this.onCancel}
                                disableSave={!formValid}
                            />
                        </div>
                    </CardBody>
                </Card>
            </>
        );
    }

    addressChanged = (name, value) => {
        this.setState({ hasInvoiceAddress: value });
    }

    render() {

        const { loading, hasInvoiceAddress } = this.state;
        if (loading) return <></>;

        let formData = '';
        

        if (hasInvoiceAddress) {
            formData = this.renderInvoiceData();
        } else {
            formData = this.renderNOInvoiceData();
        }

        return (<>
        <Title>Dati di fatturazione per i corsi non universitari</Title>
        {formData}</>)
    }
}