import React from "react";
import {
  MButton2,
  MInput,
  MSearchUniversity,
  MSelect,
  ValidatedForm,
} from "components/forms";
import { payloadBuilder } from "libs";

export class InsegnamentiMultiManager60CFU extends ValidatedForm {
  
  PAYLOAD_tipoCorso = {
    options: [
      { value: "", label: "Selezionare" },
      { value: "master", label: "Master" },
      { value: "corsi singoli", label: "Corsi Singoli" },
      { value: "corsi di perfezionamento", label: "Corsi di Perfezionamento" },
      { value: "dottorato di ricerca", label: "Dottorato di Ricerca" },
      { value: "laurea", label: "Laurea" },
    ],
  };

  ssd = [
    "ABAV1",
    "ABAV10",
    "ABAV11",
    "ABAV12",
    "ABAV13",
    "ABAV2",
    "ABAV3",
    "ABAV4",
    "ABAV5",
    "ABAV6",
    "ABAV7",
    "ABAV8",
    "ABAV9",
    "ABLE69",
    "ABLE70",
    "ABLIN71",
    "ABPC65",
    "ABPC66",
    "ABPC67",
    "ABPC68",
    "ABPR14",
    "ABPR15",
    "ABPR16",
    "ABPR17",
    "ABPR18",
    "ABPR19",
    "ABPR20",
    "ABPR21",
    "ABPR22",
    "ABPR23",
    "ABPR24",
    "ABPR25",
    "ABPR26",
    "ABPR27",
    "ABPR28",
    "ABPR29",
    "ABPR30",
    "ABPR31",
    "ABPR32",
    "ABPR33",
    "ABPR34",
    "ABPR35",
    "ABPR36",
    "ABST45",
    "ABST46",
    "ABST47",
    "ABST48",
    "ABST49",
    "ABST50",
    "ABST51",
    "ABST52",
    "ABST53",
    "ABST54",
    "ABST55",
    "ABST56",
    "ABST57",
    "ABST58",
    "ABST59",
    "ABST60",
    "ABTEC37",
    "ABTEC38",
    "ABTEC39",
    "ABTEC40",
    "ABTEC41",
    "ABTEC42",
    "ABTEC43",
    "ABTEC44",
    "ABVPA61",
    "ABVPA62",
    "ABVPA63",
    "ABVPA64",
    "AGR/01",
    "AGR/02",
    "AGR/03",
    "AGR/04",
    "AGR/05",
    "AGR/06",
    "AGR/07",
    "AGR/08",
    "AGR/09",
    "AGR/10",
    "AGR/11",
    "AGR/12",
    "AGR/13",
    "AGR/14",
    "AGR/15",
    "AGR/16",
    "AGR/17",
    "AGR/18",
    "AGR/19",
    "AGR/20",
    "AGRI-01/A",
    "AGRI-02/A",
    "AGRI-03/A",
    "AGRI-03/B",
    "AGRI-03/C",
    "AGRI-04/A",
    "AGRI-04/B",
    "AGRI-04/C",
    "AGRI-05/A",
    "AGRI-05/B",
    "AGRI-06/A",
    "AGRI-06/B",
    "AGRI-06/C",
    "AGRI-07/A",
    "AGRI-08/A",
    "AGRI-09/A",
    "AGRI-09/B",
    "AGRI-09/C",
    "AGRI-09/D",
    "ANGL-01/A",
    "ANGL-01/B",
    "ANGL-01/C",
    "ARCH-01/A",
    "ARCH-01/B",
    "ARCH-01/C",
    "ARCH-01/D",
    "ARCH-01/E",
    "ARCH-01/F",
    "ARCH-01/G",
    "ARTE-01/A",
    "ARTE-01/B",
    "ARTE-01/C",
    "ARTE-01/D",
    "ASIA-01/A",
    "ASIA-01/B",
    "ASIA-01/C",
    "ASIA-01/D",
    "ASIA-01/E",
    "ASIA-01/F",
    "ASIA-01/G",
    "ASIA-01/H",
    "BIO/01",
    "BIO/02",
    "BIO/03",
    "BIO/04",
    "BIO/05",
    "BIO/06",
    "BIO/07",
    "BIO/08",
    "BIO/09",
    "BIO/10",
    "BIO/11",
    "BIO/12",
    "BIO/13",
    "BIO/14",
    "BIO/15",
    "BIO/16",
    "BIO/17",
    "BIO/18",
    "BIO/19",
    "BIOS-01/A",
    "BIOS-01/B",
    "BIOS-01/C",
    "BIOS-01/D",
    "BIOS-02/A",
    "BIOS-03/A",
    "BIOS-03/B",
    "BIOS-04/A",
    "BIOS-05/A",
    "BIOS-06/A",
    "BIOS-07/A",
    "BIOS-08/A",
    "BIOS-09/A",
    "BIOS-10/A",
    "BIOS-11/A",
    "BIOS-12/A",
    "BIOS-13/A",
    "BIOS-14/A",
    "BIOS-15/A",
    "CEAR-01/A",
    "CEAR-01/B",
    "CEAR-02/A",
    "CEAR-02/B",
    "CEAR-02/C",
    "CEAR-02/D",
    "CEAR-03/A",
    "CEAR-03/B",
    "CEAR-03/C",
    "CEAR-04/A",
    "CEAR-05/A",
    "CEAR-06/A",
    "CEAR-07/A",
    "CEAR-08/A",
    "CEAR-08/B",
    "CEAR-08/C",
    "CEAR-08/D",
    "CEAR-09/A",
    "CEAR-09/B",
    "CEAR-09/C",
    "CEAR-10/A",
    "CEAR-11/A",
    "CEAR-11/B",
    "CEAR-12/A",
    "CEAR-12/B",
    "CHEM-01/A",
    "CHEM-01/B",
    "CHEM-02/A",
    "CHEM-03/A",
    "CHEM-04/A",
    "CHEM-05/A",
    "CHEM-06/A",
    "CHEM-07/A",
    "CHEM-07/B",
    "CHEM-07/C",
    "CHEM-08/A",
    "CHIM/01",
    "CHIM/02",
    "CHIM/03",
    "CHIM/04",
    "CHIM/05",
    "CHIM/06",
    "CHIM/07",
    "CHIM/08",
    "CHIM/09",
    "CHIM/10",
    "CHIM/11",
    "CHIM/12",
    "COMP-01/A",
    "ECON-01/A",
    "ECON-02/A",
    "ECON-03/A",
    "ECON-04/A",
    "ECON-05/A",
    "ECON-06/A",
    "ECON-07/A",
    "ECON-08/A",
    "ECON-09/A",
    "ECON-09/B",
    "ECON-10/A",
    "FICP 01/B",
    "FICP-01/A",
    "FICP-01/C",
    "FIS/01",
    "FIS/02",
    "FIS/03",
    "FIS/04",
    "FIS/05",
    "FIS/06",
    "FIS/07",
    "FIS/08",
    "FLMR-01/A",
    "FLMR-01/B",
    "FLMR-01/C",
    "FLMR-01/D",
    "FLMR-01/E",
    "FRAN-01/A",
    "FRAN-01/B",
    "GEO/01",
    "GEO/02",
    "GEO/03",
    "GEO/04",
    "GEO/05",
    "GEO/06",
    "GEO/07",
    "GEO/08",
    "GEO/09",
    "GEO/10",
    "GEO/11",
    "GEO/12",
    "GEOG-01/A",
    "GEOG-01/B",
    "GEOS-01/A",
    "GEOS-01/B",
    "GEOS-01/C",
    "GEOS-01/D",
    "GEOS-02/A",
    "GEOS-02/B",
    "GEOS-02/C",
    "GEOS-03/A",
    "GEOS-03/B",
    "GEOS-04/A",
    "GEOS-04/B",
    "GEOS-04/C",
    "GERM-01/A",
    "GERM-01/B",
    "GERM-01/C",
    "GERM-01/D",
    "GERM-01/E",
    "GIUR-01/A",
    "GIUR-02/A",
    "GIUR-02/B",
    "GIUR-03/A",
    "GIUR-03/B",
    "GIUR-04/A",
    "GIUR-05/A",
    "GIUR-06/A",
    "GIUR-07/A",
    "GIUR-08/A",
    "GIUR-09/A",
    "GIUR-10/A",
    "GIUR-11/A",
    "GIUR-11/B",
    "GIUR-12/A",
    "GIUR-13/A",
    "GIUR-14/A",
    "GIUR-15/A",
    "GIUR-16/A",
    "GIUR-17/A",
    "GLOT-01/A",
    "GLOT-01/B",
    "GLOT-01/C",
    "GLOT-01/D",
    "GSPS-01/A",
    "GSPS-02/A",
    "GSPS-03/A",
    "GSPS-03/B",
    "GSPS-04/A",
    "GSPS-04/B",
    "GSPS-04/C",
    "GSPS-04/D",
    "GSPS-05/A",
    "GSPS-06/A",
    "GSPS-07/A",
    "GSPS-07/B",
    "GSPS-08/A",
    "GSPS-08/B",
    "HELL-01/A",
    "HELL-01/B",
    "HELL-01/C",
    "HELL-01/D",
    "HIST-01/A",
    "HIST-02/A",
    "HIST-03/A",
    "HIST-03/B",
    "HIST-04/A",
    "HIST-04/B",
    "HIST-04/C",
    "HIST-04/D",
    "IBIO-01/A",
    "ICAR/01",
    "ICAR/02",
    "ICAR/03",
    "ICAR/04",
    "ICAR/05",
    "ICAR/06",
    "ICAR/07",
    "ICAR/08",
    "ICAR/09",
    "ICAR/10",
    "ICAR/11",
    "ICAR/12",
    "ICAR/13",
    "ICAR/14",
    "ICAR/15",
    "ICAR/16",
    "ICAR/17",
    "ICAR/18",
    "ICAR/19",
    "ICAR/20",
    "ICAR/21",
    "ICAR/22",
    "ICHI-01/A",
    "ICHI-01/B",
    "ICHI-01/C",
    "ICHI-02/A ",
    "ICHI-02/B",
    "IEGE-01/A",
    "IIET-01/A",
    "IIND-01/A",
    "IIND-01/B",
    "IIND-01/C",
    "IIND-01/D",
    "IIND-01/E",
    "IIND-01/F",
    "IIND-01/G",
    "IIND-02/A",
    "IIND-03/A",
    "IIND-03/B",
    "IIND-03/C",
    "IIND-04/A",
    "IIND-05/A",
    "IIND-06/A",
    "IIND-06/B",
    "IIND-07/A",
    "IIND-07/B",
    "IIND-07/C",
    "IIND-07/D",
    "IIND-07/E",
    "IIND-08/A",
    "IIND-08/B",
    "IINF-01/A",
    "IINF-02/A",
    "IINF-03/A",
    "IINF-04/A",
    "IINF-05/A",
    "IMAT-01/A",
    "IMIS-01/A",
    "IMIS-01/B",
    "INF/01",
    "INFO-01/A",
    "ING-IND/01",
    "ING-IND/02",
    "ING-IND/03",
    "ING-IND/04",
    "ING-IND/05",
    "ING-IND/06",
    "ING-IND/07",
    "ING-IND/08",
    "ING-IND/09",
    "ING-IND/10",
    "ING-IND/11",
    "ING-IND/12",
    "ING-IND/13",
    "ING-IND/14",
    "ING-IND/15",
    "ING-IND/16",
    "ING-IND/17",
    "ING-IND/18",
    "ING-IND/19",
    "ING-IND/20",
    "ING-IND/21",
    "ING-IND/22",
    "ING-IND/23",
    "ING-IND/24",
    "ING-IND/25",
    "ING-IND/26",
    "ING-IND/27",
    "ING-IND/28",
    "ING-IND/29",
    "ING-IND/30",
    "ING-IND/31",
    "ING-IND/32",
    "ING-IND/33",
    "ING-IND/34",
    "ING-IND/35",
    "ING-INF/01",
    "ING-INF/02",
    "ING-INF/03",
    "ING-INF/04",
    "ING-INF/05",
    "ING-INF/06",
    "ING-INF/07",
    "ITAL-01/A",
    "IUS/01",
    "IUS/02",
    "IUS/03",
    "IUS/04",
    "IUS/05",
    "IUS/06",
    "IUS/07",
    "IUS/08",
    "IUS/09",
    "IUS/10",
    "IUS/11",
    "IUS/12",
    "IUS/13",
    "IUS/14",
    "IUS/15",
    "IUS/16",
    "IUS/17",
    "IUS/18",
    "IUS/19",
    "IUS/20",
    "IUS/21",
    "L-ANT/01",
    "L-ANT/02",
    "L-ANT/03",
    "L-ANT/04",
    "L-ANT/05",
    "L-ANT/06",
    "L-ANT/07",
    "L-ANT/08",
    "L-ANT/09",
    "L-ANT/10",
    "L-ART/01",
    "L-ART/02",
    "L-ART/03",
    "L-ART/04",
    "L-ART/05",
    "L-ART/06",
    "L-ART/07",
    "L-ART/08",
    "LATI-01/A",
    "L-FIL-LET/01",
    "L-FIL-LET/02",
    "L-FIL-LET/03",
    "L-FIL-LET/04",
    "L-FIL-LET/05",
    "L-FIL-LET/06",
    "L-FIL-LET/07",
    "L-FIL-LET/08",
    "L-FIL-LET/09",
    "L-FIL-LET/10",
    "L-FIL-LET/11",
    "L-FIL-LET/12",
    "L-FIL-LET/13",
    "L-FIL-LET/14",
    "L-FIL-LET/15",
    "LICO-01/A",
    "LIFI-01/A",
    "LIFI-01/B",
    "L-LIN/01",
    "L-LIN/02",
    "L-LIN/03",
    "L-LIN/04",
    "L-LIN/05",
    "L-LIN/06",
    "L-LIN/07",
    "L-LIN/08",
    "L-LIN/09",
    "L-LIN/10",
    "L-LIN/11",
    "L-LIN/12",
    "L-LIN/13",
    "L-LIN/14",
    "L-LIN/15",
    "L-LIN/16",
    "L-LIN/17",
    "L-LIN/18",
    "L-LIN/19",
    "L-LIN/20",
    "L-LIN/21",
    "L-OR/01",
    "L-OR/02",
    "L-OR/03",
    "L-OR/04",
    "L-OR/05",
    "L-OR/06",
    "L-OR/07",
    "L-OR/08",
    "L-OR/09",
    "L-OR/10",
    "L-OR/11",
    "L-OR/12",
    "L-OR/13",
    "L-OR/14",
    "L-OR/15",
    "L-OR/16",
    "L-OR/17",
    "L-OR/18",
    "L-OR/19",
    "L-OR/20",
    "L-OR/21",
    "L-OR/22",
    "L-OR/23",
    "MAT/01",
    "MAT/02",
    "MAT/03",
    "MAT/04",
    "MAT/05",
    "MAT/06",
    "MAT/07",
    "MAT/08",
    "MAT/09",
    "MATH-01/A",
    "MATH-01/B",
    "MATH-02/A",
    "MATH-02/B",
    "MATH-03/A",
    "MATH-03/B",
    "MATH-04/A",
    "MATH-05/A",
    "MATH-06/A",
    "M-DEA/01",
    "MED/01",
    "MED/02",
    "MED/03",
    "MED/04",
    "MED/05",
    "MED/06",
    "MED/07",
    "MED/08",
    "MED/09",
    "MED/10",
    "MED/11",
    "MED/12",
    "MED/13",
    "MED/14",
    "MED/15",
    "MED/16",
    "MED/17",
    "MED/18",
    "MED/19",
    "MED/20",
    "MED/21",
    "MED/22",
    "MED/23",
    "MED/24",
    "MED/25",
    "MED/26",
    "MED/27",
    "MED/28",
    "MED/29",
    "MED/30",
    "MED/31",
    "MED/32",
    "MED/33",
    "MED/34",
    "MED/35",
    "MED/36",
    "MED/37",
    "MED/38",
    "MED/39",
    "MED/40",
    "MED/41",
    "MED/42",
    "MED/43",
    "MED/44",
    "MED/45",
    "MED/46",
    "MED/47",
    "MED/48",
    "MED/49",
    "MED/50",
    "M-EDF/01",
    "M-EDF/02",
    "MEDF-01/A",
    "MEDF-01/B",
    "MEDS-01/A",
    "MEDS-02/A",
    "MEDS-02/B",
    "MEDS-02/C",
    "MEDS-03/A",
    "MEDS-04/A",
    "MEDS-05/A",
    "MEDS-06/A",
    "MEDS-07/A",
    "MEDS-07/B",
    "MEDS-08/A",
    "MEDS-08/B",
    "MEDS-08/C",
    "MEDS-09/A",
    "MEDS-09/B",
    "MEDS-09/C",
    "MEDS-10/A",
    "MEDS-10/B",
    "MEDS-10/C",
    "MEDS-11/A",
    "MEDS-12/A",
    "MEDS-13/A",
    "MEDS-13/B",
    "MEDS-13/C",
    "MEDS-14/A",
    "MEDS-14/B",
    "MEDS-14/C",
    "MEDS-15/A",
    "MEDS-15/B",
    "MEDS-16/A",
    "MEDS-17/A",
    "MEDS-18/A",
    "MEDS-18/B",
    "MEDS-19/A",
    "MEDS-19/B",
    "MEDS-20/A",
    "MEDS-20/B",
    "MEDS-21/A",
    "MEDS-22/A",
    "MEDS-22/B",
    "MEDS-23/A",
    "MEDS-24/A",
    "MEDS-24/B",
    "MEDS-24/C",
    "MEDS-25/A",
    "MEDS-25/B",
    "MEDS-26/A",
    "MEDS-26/B",
    "MEDS-26/C",
    "MEDS-26/D",
    "M-FIL/01",
    "M-FIL/02",
    "M-FIL/03",
    "M-FIL/04",
    "M-FIL/05",
    "M-FIL/06",
    "M-FIL/07",
    "M-FIL/08",
    "M-GGR/01",
    "M-GGR/02",
    "M-PED/01",
    "M-PED/02",
    "M-PED/03",
    "M-PED/04",
    "M-PSI/01",
    "M-PSI/02",
    "M-PSI/03",
    "M-PSI/04",
    "M-PSI/05",
    "M-PSI/06",
    "M-PSI/07",
    "M-PSI/08",
    "M-STO/01",
    "M-STO/02",
    "M-STO/03",
    "M-STO/04",
    "M-STO/05",
    "M-STO/06",
    "M-STO/07",
    "M-STO/08",
    "M-STO/09",
    "MVET-01/A",
    "MVET-01/B",
    "MVET-02/A",
    "MVET-02/B",
    "MVET-03/A",
    "MVET-03/B",
    "MVET-04/A",
    "MVET-04/B",
    "MVET-05/A",
    "MVET-05/B",
    "PAED-01/A",
    "PAED-01/B",
    "PAED-02/A",
    "PAED-02/B",
    "PEMM-01/A",
    "PEMM-01/B",
    "PEMM-01/C",
    "PEMM-01/D",
    "PHIL-01/A",
    "PHIL-02/A",
    "PHIL-02/B",
    "PHIL-03/A",
    "PHIL-04/A",
    "PHIL-04/B",
    "PHIL-05/A",
    "PHIL-05/B",
    "PHIL-05/C",
    "PHYS-01/A",
    "PHYS-02/A",
    "PHYS-03/A",
    "PHYS-04/A",
    "PHYS-05/A",
    "PHYS-05/B",
    "PHYS-06/A",
    "PHYS-06/B",
    "PSIC-01/A",
    "PSIC-01/B",
    "PSIC-01/C",
    "PSIC-02/A",
    "PSIC-03/A",
    "PSIC-03/B",
    "PSIC-04/A",
    "PSIC-04/B",
    "SDEA-01/A",
    "SECS-P/01",
    "SECS-P/02",
    "SECS-P/03",
    "SECS-P/04",
    "SECS-P/05",
    "SECS-P/06",
    "SECS-P/07",
    "SECS-P/08",
    "SECS-P/09",
    "SECS-P/10",
    "SECS-P/11",
    "SECS-P/12",
    "SECS-P/13",
    "SECS-S/01",
    "SECS-S/02",
    "SECS-S/03",
    "SECS-S/04",
    "SECS-S/05",
    "SECS-S/06",
    "SLAV-01/A",
    "SPAN-01/A",
    "SPAN-01/B",
    "SPAN-01/C",
    "SPS/01",
    "SPS/02",
    "SPS/03",
    "SPS/04",
    "SPS/05",
    "SPS/06",
    "SPS/07",
    "SPS/08",
    "SPS/09",
    "SPS/10",
    "SPS/11",
    "SPS/12",
    "SPS/13",
    "SPS/14",
    "STAA-01/A",
    "STAA-01/B",
    "STAA-01/C",
    "STAA-01/D",
    "STAA-01/E",
    "STAA-01/F",
    "STAA-01/G",
    "STAA-01/H",
    "STAA-01/I",
    "STAA-01/J",
    "STAA-01/K",
    "STAA-01/L",
    "STAA-01/M",
    "STAA-01/N",
    "STAA-01/O",
    "STAN-01/A",
    "STAN-01/B",
    "STAT-01/A",
    "STAT-01/B",
    "STAT-02/A",
    "STAT-03/A",
    "STAT-03/B",
    "STAT-04/A",
    "STEC-01/A",
    "STEC-01/B",
    "VET/01",
    "VET/02",
    "VET/03",
    "VET/04",
    "VET/05",
    "VET/06",
    "VET/07",
    "VET/08",
    "VET/09",
    "VET/10",
  ];

  FIELDS_GROUP = [
    [
      {
        id: "searchUniversity" + this.props.idx,
        component: MButton2,
        onClick: () => {
          this.searchUniversity();
        },
        label: "Cerca Ateneo",
        className: "col-md-12 mb-3",
        classElement: "btn btn-primary bg-dark",
      },
    ],
    [
      {
        id: "istitution9" + this.props.idx,
        field: "istitution",
        label: "Istituzione universitaria o accademica",
        component: MInput,
        className: "col-md-7",
        readOnly: true,
      },
      {
        id: "city9" + this.props.idx,
        field: "city",
        label: "Città",
        component: MInput,
        className: "col-md-5",
        readOnly: true,
      },
    ],
    [
      {
        id: "dataConseguimento9" + this.props.idx,
        field: "dataConseguimento",
        label: "Data Conseguimento",
        component: MInput,
        type: "date",
        className: "col-md-4",
      },
      {
        id: "tipoCorso9" + this.props.idx,
        field: "tipoCorso",
        label: "Tipo di Corso",
        component: MSelect,
        payload: this.PAYLOAD_tipoCorso,
        className: "col-md-4",
      },
      {
        id: "cfucfa9" + this.props.idx,
        field: "cfucfa",
        label: "CFU",
        component: MSelect,
        payload: {
          options: [
            { label: "Seleziona", value: "" },
            { label: "1", value: "1" },
            { label: "2", value: "2" },
            { label: "3", value: "3" },
            { label: "4", value: "4" },
            { label: "5", value: "5" },
            { label: "6", value: "6" },
            { label: "7", value: "7" },
            { label: "8", value: "8" },
            { label: "9", value: "9" },
            { label: "10", value: "10" },
            { label: "11", value: "11" },
            { label: "12", value: "12" },
            { label: "13", value: "13" },
            { label: "14", value: "14" },
            { label: "15", value: "15" },
            { label: "16", value: "16" },
            { label: "17", value: "17" },
            { label: "18", value: "18" },
            { label: "19", value: "19" },
            { label: "20", value: "20" },
            { label: "21", value: "21" },
            { label: "22", value: "22" },
            { label: "23", value: "23" },
            { label: "24", value: "24" },
          ],
        },
        className: "col-md-4",
      },
    ],
    [
      {
        id: "ssd9" + this.props.idx,
        field: "ssd",
        label: "SSD/SAD",
        component: MSelect,
        payload: {
          options: [
            { label: "Seleziona un SSD", values: "" },
            ...this.ssd.map((val) => ({ label: val, values: val })),
          ],
        },
        className: "col-md-3",
      },{
        id: "description9" + this.props.idx,
        field: "description",
        label: "Insegnamento",
        component: MInput,
        className: "col-md-9",
      }
    ],
  ];

  ERROR_MESSAGES = {
    istitution: "Inserire l'Istituzione universitaria o accademica",
    city: "Inserire l'Istituzione universitaria o accademica",
    dataConseguimento: "Inserire la data di svolgimento dell'esame",
    tipoCorso: "Selezionare il tipo di corso",
    cfucfa: "Selezionare i CFU",
    ssd: "Selezionare il SSD/SAD",
    description: "Inserire l'insegnamento",
  };

  validation = {
    istitution: (value) => value !== "",
    city: (value) => value !== "",
    dataConseguimento: (value) => this.validateDataConseguimento(value),
    tipoCorso: (value) => value !== "",
    cfucfa: (value) => value !== "" && parseInt(value) > 0,
    ssd: (value) => value !== "",
    description: (value) => value !== ""
  };

  emptyFields = {
    istitution: "",
    city: "",
    dataConseguimento: "",
    tipoCorso: "",
    cfucfa: "",
    ssd: "",
    description: ""
  };

  state = {
    showSearchUniversity: false,
    university: {},
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields,
    },
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  validateDataConseguimento = (value) => {
    if (value === "") {
      return false;
    }
    let regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!value.match(regex)) {
      return false;
    }

    //--- --- not in the past
    const today = new Date();
    const formDate = new Date(value);

    if (formDate > today) {
      return false;
    }

    return true;
  };

  searchUniversity = () => {
    this.setState({ showSearchUniversity: !this.state.showSearchUniversity });
  };

  onChangeUniversity = (university) => {
    this.onChange("city", university.city);
    this.onChange("istitution", university.istitution);
    this.setState({ showSearchUniversity: false, university: university });
  };

  getPayload = () => {
    this.payload = payloadBuilder(this.state);
    this.payload.qualificationType = this.props.qualificationType;
    this.payload.qualificationCode = "title12CFUItem";
    this.payload.nation = "Italia";
    this.payload.template = "Title12CCFUItem";

    if (this.state.university) {
      this.payload.uniCode = this.state.university.uniCode;
    }

    return this.payload;
  };

  render() {
    const { defaultValues, showSearchUniversity } = this.state;
    const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

    return (
      <>
        {showSearchUniversity ? (
          <>
            <MSearchUniversity
              onChange={this.onChangeUniversity}
              searchUniversity={this.searchUniversity}
              isForeign={false}
              isCFU={true}
            />
          </>
        ) : (
          <>{code}</>
        )}
      </>
    );
  }
}
