import React from "react";
import { MInput, MSelect, ValidatedForm } from "components/forms";
import { enrollmentService, networkErrorHelper, payloadBuilder } from "libs";
import { FormTitleStep2Configurator } from "./FormTitleStep2Configurator";

export class CertLingueStraniere2012Form extends ValidatedForm {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  PAYLOADS = {
    languages: [],
    certifiers: [],
  };

  emptyFields = {
    description: "",
    language:"",
    istitution: "",
    dataConseguimento: "",
    ambitoCorsoStudi: "",
    numProtocollo: "",
  };


  state = {
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields,
    },
    languages: this.PAYLOADS.languages,
    certifiers: this.PAYLOADS.certifiers,
    certifiersData: null,
    certifiersDataFiltered: null,
  };

  FIELDS_GROUP = [
    [
      {
        id: "description" + this.props.idx,
        field: "description",
        label: "Denominazione Certificazione Linguistica",
        component: MInput,
        type: "text",
        className: "col-md-12 pt-3",
      },
    ],
    [
      {
        id: "dataConseguimento" + this.props.idx,
        field: "dataConseguimento",
        label: "Data conseguimento titolo",
        component: MInput,
        onChange: (_, value) => this.onChangeCertifiersFilter(_, value),
        type: "date",
        className: "col-md-4",
        infoText:
          "Inserire la data di conseguimento del titolo per poter selezionare la lingua e l'ente erogatore",
      },
      {
        id: "language" + this.props.idx,
        field: "language",
        label: "Lingua",
        payload: { options: this.PAYLOADS.languages },
        component: MSelect,
        onChange: (_, value) => this.onChangeLanguage(_, value),
        className: "col-md-4",
      },
      {
        id: "istitution" + this.PAYLOADS.idx,
        field: "istitution",
        label: "Ente erogatore",
        payload: { options: this.state.certifiers },
        component: MSelect,
        className: "col-md-4",
      },
    ],
    [
      {
        id: "ambitoCorsoStudi" + this.props.idx,
        field: "ambitoCorsoStudi",
        label: "Livello (indicare C1 o C2)",
        component: MSelect,
        payload: {
          options: [
            { value: "", label: "Seleziona" },
            { value: "C1", label: "livello C1" },
            { value: "C2", label: "livello C2" },
          ],
        },
        className: "col-md-6",
      },
      {
        id: "numProtocollo" + this.props.idx,
        field: "numProtocollo",
        label: "Numero certificazione",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
    ],
  ];

  ERROR_MESSAGES = {
    description: "dato obbligatorio",
    istitution: "dato obbligatorio",   
    dataConseguimento: "dato obbligatorio",
    ambitoCorsoStudi: "dato obbligatorio",
    numProtocollo: "dato obbligatorio",
    language: "dato obbligatorio",
  };

  validation = {
    description: (value) => value !== "",
    istitution: (value) => value !== "",
    dataConseguimento: (value) => this.validateDataConseguimento(value),
    ambitoCorsoStudi: (value) => value !== "",
    numProtocollo: (value) => value,
    language: (value) => value,
  };

  validateDataConseguimento = (value) => {
    if (value === "") {
      return false;
    }
    let regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!value.match(regex)) {
      return false;
    }

    //--- ---
    const today = new Date();
    const date = new Date(value);

    if (date > today) {
      return false;
    }

    if (Number.isNaN(date.getTime())) {
      return false;
    }
    const [year, month, day] = value.split("-").map(Number);

    if (month > 12) {
      return false;
    }
    if (day > 31) {
      return false;
    }

    const currentYear = new Date().getFullYear();
    if (year < currentYear - 90 || year > currentYear) {
      return false;
    }
    return true;
  };  
  
  componentDidMount() {
    this.loadRemote();
  }

  loadRemote = () => {
    enrollmentService
      .getLanguageCertifiers()
      .then(({ data }) => {
        this.setState({
          certifiersData: data.payload.certifiers,
        });
      })
      .catch((errors) => {
        console.log(errors);
        networkErrorHelper.notify(errors);
      });
  };

  onChangeLanguage = (_, value) => {
    const list = this.state.certifiersDataFiltered
      .filter((certifier) => certifier.languages === value)
      .map(({ istitution }) => ({
        label: istitution,
        value: istitution,
      }));      

    this.PAYLOADS.certifiers.splice(0, this.PAYLOADS.certifiers.length);
    this.PAYLOADS.certifiers.push(...list);
    this.PAYLOADS.certifiers.unshift({ label: 'Seleziona un ente erogatore ('+list.length+')', value: '' });
  };

  onChangeCertifiersFilter = (_, value) => {
    let result = [];

    if (value && new Date(value) < new Date("2024-08-31")) {
      result = this.state.certifiersData.filter((certifier) => {
        return certifier.dateTo === "2024-08-31";
      });
    }

    if (value && new Date(value) > new Date("2024-08-31")) {
      result = this.state.certifiersData.filter((certifier) => {
        return certifier.dateFrom === "2024-08-31";
      });
    }

    //--- ---

    const list = result.map(({ languages }) => ({
      label: languages,
      value: languages,
    }));

    const uniqueList = list.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.value === item.value)
    );

    this.PAYLOADS.languages.splice(0, this.PAYLOADS.languages.length);
    this.PAYLOADS.languages.push(...uniqueList);
    this.PAYLOADS.languages.unshift({ label: 'Seleziona una lingua ('+uniqueList.length+')', value: '' });
    this.setState({ certifiersDataFiltered: result });
  };

  getPayload = () => {
    this.payload = payloadBuilder(this.state);
    this.payload.qualificationType = "Certificazioni Linguistiche";
    this.payload.qualificationCode =  "title21_CERT_LINGUISTICA"; ;
    this.payload.template = "certLingueStraniere2012Item";
    this.payload.extCode= FormTitleStep2Configurator.getExtCode("certLingueStraniere2012");

    return this.payload;
  };

  render() {
    const { defaultValues, forceRender } = this.state;
    const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

    return <div key={forceRender}>{code}</div>;
  }
}
