import React, { Component } from 'react';
import { Title } from 'components/misc';
import { Helmet } from 'react-helmet';

export class PageSupportoTecnico extends Component {

    render() {

        return (
            <>
                <Helmet >
                    <title>Mnemosine - Supporto Tecnico</title>
                    <meta property="og:title" content="Mnemosine - Supporto Tecnico" />
                </Helmet>
                <Title>Supporto Tecnico</Title>

                <div className="row">
                    <div className="col-12 col-sm-6 col-md-3">
                        <article className="card-wrapper card-space m-1">
                            <div className="card-bg rounded shadow card">
                                <div className="card-body text-center">
                                    <img className="image-30"
                                        src="/img/icon/call.png"
                                        alt="Mnemosine"
                                    />
                                    <h5 className="text-dark text-center card-title">CALL CENTER</h5>
                                    <hr className="line-green" />
                                    <h6><a className="text-green" href="tel:+39092532779"><b>0925 32779</b></a> o
                                        <a className="text-green" href="tel:+393664140604"><b> 366 4140 604</b></a></h6>
                                    <hr />
                                    <p className="box-content-left card-text">
                                        Dal Lunedì al Venerdì dalle ore 9:00 alle ore 13:00 e dalle ore 15:00 alle ore 18:00</p>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className="col-12 col-sm-6 col-md-3">
                        <article className="card-wrapper card-space m-1">
                            <div className="card-bg rounded shadow card">
                                <div className="card-body  text-center">
                                    <img className="image-30"
                                        src="/img/icon/message.png"
                                        alt="Mnemosine"
                                    />
                                    <h5 className="text-dark text-center card-title">E-MAIL</h5>
                                    <hr className="line-green" />
                                    <h6><a className="text-green" href="mailto:supporto@formazionedocenti.it">supporto@formazionedocenti.it</a>
                                    </h6>
                                    <hr />
                                    <p className="box-content-left card-text">
                                        Scrivi quando vuoi e risponderemo in breve tempo</p>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className="col-12 col-sm-6 col-md-3">
                        <article className="card-wrapper card-space m-1">
                            <div className="card-bg rounded shadow card">
                                <div className="card-body  text-center">
                                    <img className="image-30"
                                        src="/img/icon/ticket.png"
                                        alt="Mnemosine"
                                    />
                                    <h5 className="text-dark  text-center card-title">TICKET</h5>
                                    <hr className="line-green" />
                                    <h6>
                                        <a className="text-green" href="/course/tickets">invia un ticket</a>
                                    </h6>
                                    <hr />
                                    <p className="box-content-left card-text">
                                        Invia una segnalazione dalla tua area riservata e risponderemo in breve tempo
                                    </p>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className="col-12 col-sm-6 col-md-3">
                        <article className="card-wrapper card-space m-1">
                            <div className="card-bg rounded shadow card">
                                <div className="card-body  text-center">
                                    <img className="image-30"
                                        src="/img/icon/wappmnemo.png"
                                        alt="Mnemosine"
                                    />
                                    <h5 className="text-dark  text-center card-title">WHATSAPP</h5>
                                    <hr className="line-green" />
                                    <h6>
                                        <a className="text-green" href="https://wa.me/+393664140604" target="_blank" rel="noopener noreferrer">invia messaggio</a>
                                    </h6>
                                    <hr />
                                    <p className="box-content-left card-text">
                                        Invia un messaggio e risponderemo in breve tempo
                                    </p>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </>
        );
    }
}

