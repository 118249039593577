/**
 * Mostra tabella delle QuizTtemptReview da revisionare
 */
import React, { Component } from "react";
import { MnemoLoading, BoxDanger } from "components/misc";
import { networkErrorHelper } from "libs";

import { MTable } from "components/table";
import { Container } from "design-react-kit";
import { ROUTES } from "const";
import { revisionService } from "libs/api/revisionService";

export class ReviewCurrent30Cfu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      rndKey: 0,
      payload: [],
    };
  }

  //--- ---

  tableConfig = {
    columns: [
      {
        id: "fullName",
        Header: "Nome e Cognome",
        accessor: "fullName",
      },
      {
        id: "registrationCode",
        Header: "Codice Registrazione",
        accessor: "registrationCode",
      },
      {
        id: "status",
        Header: "Stato",
        accessor: "status",
      },
      {
        id: "competitionClass",
        Header: "Classe Concorso",
        accessor: "competitionClass",
      },
      {
        id: "version",
        Header: "Versione",
        accessor: "version",
      },
      {
        id: "revisionProblem",
        Header: "Problemi",
        accessor: (row) => {
          return row.revisionProblem ? "SI" : "NO";
        },
      },
      {
        id: "revisionProgress",
        Header: "Revisioni",
        accessor: "revisionProgress"
      },
    ],
    actions: [
      {
        id: "detail",
        accessor: "id",
        label: "Dettagli",
        icon: "it-file",
        className: "MTable-actions",
        onClick: (e, value) => {
          window.location.href = ROUTES.EXAMREVIEW_FORM + "/" + value;
        },       
      },
      {
        id: "download",
        accessor: "id",
        label: "Download PDF",
        icon: "it-print",
        className: "MTable-actions",
        onClick: (e, value) => {
          this.downloadPDF(value);
        }
      }
    ],
  };

  //--- ---

  componentDidMount() {
    this.loadRemote();
  }

  loadRemote() {
    this.setState({ loading: true });

    revisionService
      .listAllConfimedExamByRevisorId()
      .then(({ data }) => {
        //--- start: prepare reactTable ---
        const items = [];
        Object.entries(data.payload.examReview).forEach(([key, val]) => {
          items.push(val);
        });

        this.setState({
          loading: false,
          payload: items,
          rndKey: Math.floor(Math.random() * 1000 + 1),
        });
        //--- end: prepare reactTable ---
      })
      .catch((error) => {
        if (!networkErrorHelper.is404(error)) {
          console.log(error);
          networkErrorHelper.notify(error);
        } else {
          this.setState({
            payload: [],
            rndKey: Math.floor(Math.random() * 1000 + 1),
          });
        }

        this.setState({ loading: false });
      });
  }

  downloadPDF(id) {
    console.log("downloadPDF", id);
  }

  render() {
    const { loading } = this.state;

    return (
      <>        
        <section className="my-4">
          {loading ? (
            <MnemoLoading></MnemoLoading>
          ) : (
            <Container>
              <MTable
                key={this.state.rndKey}
                tableData={this.state.payload}
                tableConfig={this.tableConfig}
              />
            </Container>
          )}

          {this.state.payload.length === 0 && (
            <BoxDanger key="nrp-1" className="my-3">
              <div className="small text-info">
                Non ci sono dati da mostrare in database.
              </div>
            </BoxDanger>
          )}
        </section>
      </>
    );
  }
}
