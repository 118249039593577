import {proxyBE} from './common';

export const enrollmentEvaluationService = {        

    evaluationList( company, competitionClass, region ) {        
        return proxyBE.get(`/admin/enrollmentEvaluation/list/${company}/${competitionClass}/${region}` );
    },

    analyzeEvaluation(currentCompany, currentCC, currentRegion) {
        return proxyBE.post(`/admin/enrollmentEvaluation/analyze/${currentCompany}/${currentCC}/${currentRegion}`);
    }

}