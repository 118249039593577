import { ROUTES } from "const";
import { courseService } from "libs";

export class IUL30 {

  static async getDashboardCards(registrationCode, idExamSession) {
    let cards = this.getFixedCards(idExamSession);

    try {

      // verify dynamic links
      const result = await courseService.perfezionamentoActiveLink(
        registrationCode
      );
      if (result.data.payload) {
      

       /*
         const { payload } = result.data;
        if (payload.SelfDeclarationEnabled === "1") {
          cards.push({
            title: "Autocertificazione d'iscrizione",
            link: ROUTES.MU_SELF_DECLARATION,
            img: "/img/icon/badge.png",
            className: "image-box",
          });
        }*/

      }
    } catch (error) { }

    return cards;
  }

  static getFixedCards(idExamSession) {
    let cards = [
      {
        title: "Messaggi",
        link: ROUTES.COURSE_TICKETS,
        img: "/img/icon/message.png",
        className: "image-box",
      },
      {
        title: "Situazione pagamenti",
        link: ROUTES.MU_COURSE_PAYMENTS,
        img: "/img/icon/payment.png",
        className: "image-box",
      },
    ];

    return cards;
  }

  static getDashboardNotices(idExamSession, aa) {

  }
}
