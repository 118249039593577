import React, { Component } from "react";
import { BoxSuccess, MnemoLoading, MSection } from "components/misc";
import { ROUTES } from "const";
import { courseService, networkErrorHelper } from "libs";
import { Helmet } from "react-helmet";
import {
    Row, Col, Container, Button,
    AccordionHeader,
    AccordionBody
} from "design-react-kit";
import { MSearch } from "components/forms";

export class CourseBrowserAdesioni60CFU extends Component {

    _class1Ref = null;

    state = {

        loading: true,
        currentRegion: '',
        currentCompetitionClass: '',

        courseCompetitionClass: [],
        courseFiltered: [],

        payloadCompetitionClass: [],
        payloadRegion: [],

        filterRegionOpen: false,
        filterRegionApplied: false,

        filterCCOpen: false,
        filterCCApplied: false,
    }

    componentDidMount() {

        this.setState({ loading: true });
        this.loadCompetionClasses();

        this._class1Ref = React.createRef();
    }

    loadCompetionClasses() {

        courseService
            .listCompetitionClassAll()
            .then(({ data }) => {

                const { courseCompetitionClass } = data.payload;

                const payloadCompetitionClass = [];
                const payloadRegion = [];

                // fill the payload for the search component
                courseCompetitionClass.forEach((item) => {

                    const foundCC = payloadCompetitionClass.find(el => el.value === item.competitionClass);
                    if (!foundCC) {
                        payloadCompetitionClass.push({ value: item.competitionClass, label: item.competitionClass + ' - ' + item.note });
                    }

                    const foundRegion = payloadRegion.find(el => el.value === item.region);
                    if (!foundRegion) {
                        payloadRegion.push({ value: item.region, label: item.region.toUpperCase() });
                    }

                    // order paylodRegion based on value
                    payloadRegion.sort((a, b) => {
                        if (a.value < b.value) return -1;
                        if (a.value > b.value) return 1;
                        return 0;
                    });
                });

                this.setState({
                    courseCompetitionClass,
                    courseFiltered: courseCompetitionClass,
                    payloadCompetitionClass, payloadRegion,
                    loading: false
                });

            })
            .catch(error => {
                console.log(error);
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
            });

    }

    toggleFilter = id => {

        this.setState({ filterRegionApplied: false, filterCCApplied: false });

        switch (id) {

            case 'filterRegionOpen':
                this.setState({ filterCCOpen: false, filterRegionOpen: true });
                this.applyFilter('', '');
                break;

            case 'filterCCOpen':
                this.setState({ filterCCOpen: true, filterRegionOpen: false });
                this.applyFilter('', '');
                break;
            default:
        };
    }

    changeCompetitionClass = (_, value) => {

        const newCompetitionClass = (value) ? value.value : '';
        this.applyFilter(newCompetitionClass, '');

        this.setState({ filterCCApplied: true });
    }

    changeRegion = (_, value) => {

        const newRegion = (value) ? value.value : '';
        this.applyFilter('', newRegion);
        this.setState({ filterRegionApplied: true });
    }

    filterReset() {

        this.applyFilter('', '');
        this.setState({ filterRegionOpen: false, filterCCOpen: false, filterRegionApplied: false, filterCCApplied: false });
        if (this._class1Ref && this._class1Ref.current) {
            this._class1Ref.current.clearValue();
        }
    }

    applyFilter(competitionClass, region) {

        const { courseCompetitionClass } = this.state;
        let courseFiltered = courseCompetitionClass;

        if (competitionClass) {
            courseFiltered = courseFiltered.filter(course => course.competitionClass === competitionClass);
        }

        if (region) {
            courseFiltered = courseFiltered.filter(course => course.region === region);
        }

        this.setState({
            currentCompetitionClass: competitionClass,
            currentRegion: region,
            courseFiltered,
        });


        return courseFiltered;
    }

    renderFilterSection() {

        const {
            payloadCompetitionClass, payloadRegion,
            filterRegionOpen, filterCCOpen
        } = this.state;

        return (
            <>
                <MSection className="mb-3">
                    <Row>
                        <Col size="12" md="6">
                            <AccordionHeader active={filterCCOpen} onToggle={() => this.toggleFilter('filterCCOpen')}>
                                <h6>Ricerca per Classe di Concorso</h6>
                            </AccordionHeader>
                            <AccordionBody active={filterCCOpen}>
                                <MSearch
                                    key="class-select"
                                    field="class-select"
                                    id="class-select"
                                    name="class-select"
                                    placeHolder="Inserisci o seleziona classe"
                                    payload={payloadCompetitionClass}
                                    onChange={this.changeCompetitionClass}
                                    ref={this._class1Ref}
                                />
                                <Button outline className="float-right mt-2"
                                    onClick={e => this.filterReset()}
                                >Cancella filtri</Button>
                            </AccordionBody>
                        </Col>
                        <Col size="12" md="6">
                            <AccordionHeader active={filterRegionOpen} onToggle={() => this.toggleFilter('filterRegionOpen')}>
                                <h6>Ricerca per Regione</h6>
                            </AccordionHeader>
                            <AccordionBody active={filterRegionOpen}>
                                <MSearch
                                    key="region2-select"
                                    field="region2-select"
                                    id="region2-select"
                                    name="region2-select"
                                    placeHolder="Inserisci o seleziona regione"
                                    payload={payloadRegion}
                                    onChange={this.changeRegion}
                                />
                                <Button outline className="float-right mt-2"
                                    onClick={e => this.filterReset()}
                                >Cancella filtri</Button>
                            </AccordionBody>
                        </Col>
                    </Row>
                </MSection>
            </>);
    }

    renderCourseList() {

        const { courseFiltered } = this.state;
        let toShow = (courseFiltered && courseFiltered.length > 0) ? true : false;


        const courseList = (
            <>
                <table className="MTable table  table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Classe</th>
                            <th>Regione</th>
                            <th>Corso</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {courseFiltered.map((course, i) => (
                            <tr key={`course-${i}`}>
                                <td>{course.competitionClass}</td>
                                <td> {course.region.toUpperCase()}</td>
                                <td>{course.note}</td>
                                <td>
                                    <a href={`${ROUTES.COURSE_LANDINGPAGE_FULL}/PERCORSI_ABILITANTI?cc=${course.competitionClass}&region=${course.region}`} target="_blank" rel="noopener noreferrer" className="text-green">
                                        <b> Pre-iscriviti</b>
                                    </a>
                                </td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
            </>
        );

        return (
            <>
                {(toShow)
                    ?
                    <>{courseList}</>
                    : <BoxSuccess>La ricerca non ho prodotto alcun risultato. Cambia i filtri di ricerca.</BoxSuccess>
                }
            </>
        );
    }

    render() {

        const { loading } = this.state;
        const title = "Percorsi di Abilitazione all'insegnamento 60 - 30 - 36 CFU";
        let courseListSection = '';
        let filterSection = '';

        if (!loading) {
            courseListSection = this.renderCourseList();
            filterSection = this.renderFilterSection();
        }

        return (
            <section className="my-4">
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <div>
                    <h1>{title}</h1>
                    <p>Iscrizioni aperte per:<br /></p>
                    <ul>
                        <li>Corsi 60 e 30 cfu all. 2 (a cui si accede con Laurea)</li>
                    </ul>
                    <p>Da Mercoledì 02 Aprile iscrizioni aperte per:</p>
                    <ul>
                        <li>Corsi abilitanti 30-36-30 post Concorso (a cui possono accedere solo i vincitori di concorso in ruolo)</li>
                    </ul>
                    <p>Esami entro il 31 Agosto 2025 - <a href="https://www.formazionedocenti.it/page/faq-60-30-36-cfu">FAQ  60, 30 e 36 CFU</a></p>
                    <p>Di seguito le pre-iscrizioni ai corsi 60 - 30 - 36 CFU che saranno attivi nelle varie Regioni</p>
                </div>
                {(loading) ? <MnemoLoading /> : (<>
                    <Container>
                        {filterSection}
                        {courseListSection}
                    </Container>
                </>)}
            </section>
        );
    }
}