import React from "react";

import {
    ValidatedForm,
} from "components/forms";

import { BoxDanger, MnemoLoading, SupportoTecnico } from "components/misc";
import { enrollmentService, networkErrorHelper, paymentService } from "libs";
import { Button } from "design-react-kit";

export class EnrollPayPagoPaForm extends ValidatedForm {

    onChangePaymentType = (_, value) => {
        this.resetFields();
        this.setState({ paymentSelected: value });
    }

    state = {
        loading: true,
        loadingError: false,
    };

    loadRemote(additionalState = {}) {

        const { orderNumber, datePayment, id } = this.props.enrollData;

        paymentService.gompPaymentRenew(orderNumber)
            .then(({ data }) => {

                const payment = data.payload;

                // update status if payment status changed
                if (payment.paymentReceipt && !datePayment) {
                    enrollmentService.endUnicamillus({ id: id })
                        .then(({ data }) => {

                        })
                        .catch(error => {
                            console.log(error);
                        })
                }

                this.setState({ loading: false, loadingError: false, payment });
            })
            .catch(error => {

                let loadingError = false;
                if (networkErrorHelper.is404) {
                    loadingError = 'Si è verificato un errore durante la lettura dell\'avviso di pagamento, se il problema persiste ';
                } else {
                    networkErrorHelper.notify(error);
                    loadingError = 'Si è verificato un errore durante il caricamento del pagamento, se il problema persiste ';
                }

                this.setState({ loading: false, loadingError });
            });
    }

    canNext(){

        let canNext=false;
        const {payment} = this.state;
        if (payment && payment.paymentReceipt){
            canNext=true;
        }

        return canNext;
    }

    goLink = (link) => {

        window.location = link;
    }

    render() {

        const { loading, loadingError, payment } = this.state;
        if (loading) return <><MnemoLoading /></>;
        if (loadingError) return <BoxDanger>{loadingError} <SupportoTecnico /></BoxDanger>;

        return (<>
            <div className="box-text-center">
                <img className="image-30 d-block mx-auto"
                    src="/img/PagoPA.png"
                    alt="Mnemosine"
                />
                {(payment && payment.paymentReceipt) ? (<>
                    <h6>Il pagamento risulta eseguito correttamente. A breve sarai avvisato dell'attivazione del corso.</h6>
                    <Button color="primary bg-dark" className="mx-auto mt-4 d-block" >
                        <a download="ricevuta_pagamento.pdf" href={`data:application/pdf;base64,${payment.paymentReceipt}`} className="link-white" title='Scarica ricevuta di pagamento' >Scarica la ricevuta</a>
                    </Button>
                </>) : (<>
                    <Button color="primary bg-dark" className="mx-auto d-block" onClick={() => this.goLink(payment.paymentLink)} >
                        Avvia il pagamento
                    </Button>
                    {(payment && payment.invoice64) && (
                        <Button color="primary bg-dark" className="mx-auto mt-4 d-block" >
                            <a download="avviso_pagamento.pdf" href={`data:application/pdf;base64,${payment.invoice64}`} className="link-white" title='Scarica avviso di pagamento' >Scarica avviso di pagamento</a>
                        </Button>
                    )}
                </>)}
            </div>
        </>);
    }
}