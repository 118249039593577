import { ErrorNotification } from "components/misc";
import { STATUS_CODES } from "const";

export class networkErrorHelper {

    static is401(error){
        
        if (error.response && error.response.status
         && error.response.status === STATUS_CODES.UNAUTH){            
             return true;
         }
 
         return false;
     }

    static is404(error){
        
       if (error.response && error.response.status
        && error.response.status === STATUS_CODES.NOT_FOUND){            
            return true;
        }

        return false;
    }

    static is422(error){
        
        if (error.response && error.response.status
         && error.response.status === STATUS_CODES.UNPROCESSABLE){            
             return true;
         }
 
         return false;
     }

     static is500(error){
        
        if (error.response && error.response.status
         && error.response.status === STATUS_CODES.INTERNAL_ERROR){            
             return true;
         }
 
         return false;
     }

     static getErrorMessage(error){
        let message ='Si è verificato un errore imprevisto';
        if (error.response 
            && error.response.data 
            && error.response.data.errors) {       
            const remoteMessage= (Array.isArray(error.response.data.errors)) ?   error.response.data.errors.join('. ') : error.response.data.errors;
            if (remoteMessage)
                message = remoteMessage;

        }  

        return message;
     }

     static notify(error) {       
         const title   = "Errore durante l'elaborazione della richiesta";  
         const message = this.getErrorMessage(error);             
         ErrorNotification.render(message, title);       
         return message;
     }  

     static clientNotify(title, message) {                   
         ErrorNotification.render(message, title);       
         return message;
     }    
}