import { MCheckbox } from "components/forms";
import { courseService } from "libs";
import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";

export class FormABILITAZIONE60CFU extends Component {

  state = {
    accept11: false,
    accept12: false,
    accept13: false,
    validationError: "",
    course: {}
  };

  componentDidMount() {
    this.loadRemote();
  }

  loadRemote() {
    //alert(this.props.courseCode)
    //const currentAA = CourseHelper.getCurrentAA(courseCode);
    courseService.getByCode(this.props.courseCode).then(
      (response) => {
        this.setState({
          course: response.data.payload.course,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getValue() {
    const pluginValue = {
      competitionClass: this.state.course.metadata.competitionClass || '',
      region: this.state.course.metadata.region || '',
      accept11: this.state.accept11,
      accept12: this.state.accept12,
      accept13: this.state.accept13,
    };

    return pluginValue;
  }

  checkValidation() {
    let validationError = "";
    let isValid = true;
    const { accept11, accept12, accept13 } = this.state;

    if (!accept11 || !accept12 || !accept13) {
      validationError +=
        "<li>E' necessario accettare tutte le dichiarazioni.</li>";
      isValid = false;
    }

    if (!isValid) validationError = "<ul>" + validationError + "</ul>";
    this.setState({ validationError });

    return isValid;
  }

  onConditionClick = (id, value) => {
    this.setState({ [id]: value });
  };

  render() {

    if (!this.state.course.metadata) { return true }

    let  region = '';
    if (this.state.course.metadata.region) {
      region = this.state.course.metadata.region.toUpperCase();
    }
    return (
      <div>
        <h3>{this.state.course.title} - {region}</h3>
        <hr />
        <h5>Dichiarazioni</h5>
        <MCheckbox onChange={this.onConditionClick} id="accept11" name="accept11">
          Dichiaro di essere in possesso dei requisiti di ammissione indicati nel bando di partecipazione.
          Tali requisiti verranno ulteriormente autocertificati in fase di completamento della domanda di partecipazione.
        </MCheckbox>
        <MCheckbox onChange={this.onConditionClick} id="accept12" name="accept12">
          Dichiaro di essere in possesso dei titoli di accesso alla classe di concorso per la quale si intende
          conseguire la nuova abilitazione. Tali requisiti verranno ulteriormente autocertificati in fase di completamento della domanda di partecipazione.
        </MCheckbox>
        <MCheckbox onChange={this.onConditionClick} id="accept13" name="accept13">
          Dichiaro di non aver presentato domanda di partecipazione per percorsi relativi alla medesima
          classe di concorso in altre istituzioni.
        </MCheckbox>

        <hr />
        {this.state.validationError && (
          <>
            <span className="form-text text-danger is-invalid">
              {ReactHtmlParser(this.state.validationError)}
            </span>
          </>
        )}
      </div>
    );
  }
}
