import React, { Component } from "react";
import { NavLink, withRouter } from 'react-router-dom';
import { BoxDanger, BoxSuccess, MnemoLoading, SupportoTecnico, Title } from "components/misc";
import { dateHelper, learningLiveService, learningService, networkErrorHelper } from "libs";
import { Icon, Row, Col } from "design-react-kit";
import { ROUTES } from "const";
import { rc30ExamService } from "libs/api/rc30ExamService";

class RC30ExamReservation extends Component {

    state = {
        loading: true,
        loadingError: false,
        course: 0,
        userCourse: 0,
        rCode: '',
        paymentsOther: [],
        payments: [],
        examSites: [],
        examReservation: false,
        analytic: {}
    };

    goBack() {
        window.history.back();
    }

    componentDidMount() {

        const { rCode } = this.props.match.params;
        this.loadRemote(rCode);
    }

    loadRemote(rCode) {

        learningService
            .courseDashboard(rCode)
            .then(({ data }) => {

                const { course, userCourse } = data.payload;
                this.setState({ course, userCourse, rCode });

                //  load the analytics form server 
                learningLiveService.myAnalyticResume(rCode)
                    .then(({ data }) => {

                        const { analytic } = data.payload;                     
                        this.setState({ analytic });
                    })
                    .catch(error => {

                        if (!networkErrorHelper.is404(error)) {
                            networkErrorHelper.notify(error);
                        }
                    });

                rc30ExamService.myExam(rCode, userCourse.pluginConfig.competitionClass)
                    .then(({ data }) => {

                        const { examSites, examReservation, payments, paymentsOther } = data.payload;
                        this.setState({ loading: false, examReservation, loadingError: false, examSites, payments, paymentsOther });
                    })
                    .catch(error => {

                        if (!networkErrorHelper.is404(error)) {
                            networkErrorHelper.notify(error);
                        }

                        this.setState({ rCode, loading: false, loadingError: true });
                    });
            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false, loadingError: true });
            });
    }

    reserveExam(site) {

        this.setState({ loading: true, loadingError: false });
        const { userCourse } = this.state;
        rc30ExamService.reserveExam(userCourse.registrationCode, site.idSite, site.idHour)
            .then(({ data }) => {

                const { examReservation } = data.payload;
                this.setState({ examReservation, loading: false });
            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false, loadingError: true });
            });
    }

    renderReservation(examReservation) {

        return (<BoxSuccess className="mt-4">
            <p>Hai già prenotato la prova finale per la classe di concorso: {examReservation.competitionClass}</p><hr />
            <p>
                <b>Data</b>: {examReservation.dayOfWeek} {dateHelper.toITDate(examReservation.date)}<br />
                <b>Città: </b> {examReservation.city}<br />
                <b>Indirizzo</b>: {examReservation.address}<br />
                {/*<b>Orario prova scritta: </b> {examReservation.hourStartWritten}<br />*/}
                <b>Inizio prova finale: </b> {examReservation.hourStartOral}<br />
                {/*<b>Posizione prenotazione: </b> {examReservation.progress}<br />*/}
            </p>
        </BoxSuccess>);
    }

    renderExamSites(examSites, competitionClass) {

        const { payments, paymentsOther, userCourse } = this.state;

        // filter for  payments to pay 
        const paymentsToPay = payments.filter(payment => payment.status === 'DaPagare');
        const paymentsOtherToPay = paymentsOther.filter(payment => payment.status === 'DaPagare');

        if (paymentsToPay.length > 0 || paymentsOtherToPay.length > 0) {
            return (<BoxDanger className="mt-4">
                <p>Per poter prenotare la prova finale devi prima effettuare i seguenti pagamenti. Se hai già effettuato i pagamenti allora devi allegarli nella <a href={`${ROUTES.COURSE_PAYMENTS}/${userCourse.registrationCode}`} >  situazione pagamenti</a>  </p>
                <hr />
                <ul>
                    {paymentsToPay.map((payment, index) => {
                        return (<li key={index}><b>Rata numero {payment.numRate}</b>  € {payment.importToPay}</li>);
                    })}
                    {paymentsOtherToPay.map((payment, index) => {
                        return (<li key={index}><b>{payment.cause}</b>  € {payment.importToPay}</li>);
                    })}
                </ul>
            </BoxDanger>);
        }

        return (<BoxSuccess className="mt-4">
            <p>Seleziona la sede della prova finale per la classe di concorso <b>{competitionClass}</b></p>
            <hr />
            {(examSites.length === 0) ? (<p>Non ci sono sedi disponibili,  <SupportoTecnico /></p>)

                : (<>{
                    examSites.map((site, index) => {
                        return (<Row key={index}>
                            <Col xs={9}>
                                <p>
                                    <b>Città: </b>{site.city}<br />
                                    <b>Giorno </b>{site.dayOfWeek} {dateHelper.toITDate(site.date)} <br />
                                    {/*<b>Orario prova scritta: </b> {site.hourStartWritten}<br />*/}
                                    <b>Inizio prova finale: </b> {site.hourStartOral}
                                </p>
                                <hr />
                            </Col>
                            <Col xs={3}>
                                <button className="btn btn-outline-primary" onClick={() => this.reserveExam(site)}>Prenota</button>
                            </Col>

                        </Row>);
                    })
                }
                </>)}
        </BoxSuccess>);
    }

    render() {

        const { loading, loadingError, rCode, examSites, examReservation, userCourse, analytic } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);

        if (loadingError) {
            return (<BoxDanger className="mt-4">
                Si è verificato un errore durante il caricamento della pagina,
                <br /><SupportoTecnico />
            </BoxDanger>);
        }

        let content = '';
        let canReserve = true;        

        if (analytic && analytic.length > 0) {

            const noGood  = analytic.filter(record => record.percent <= 0.7);
            if (noGood && noGood.length > 0) {
                content = <BoxDanger className="mt-4">
                    Non sei abilitato alla prenotazione esami  <br /><SupportoTecnico />
                </BoxDanger>;
            }else{
                canReserve = true;
            }
        } else {

            // non risultano analytics per questo utente
            content = <BoxDanger className="mt-4">
                Non sei abilitato alla prenotazione esami  <br /><SupportoTecnico />
            </BoxDanger>;
        }

        if (canReserve) {
            content = (examReservation) ? this.renderReservation(examReservation) : this.renderExamSites(examSites, userCourse.pluginConfig.competitionClass);
        }

        return (<>
            <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
            <Title >Prenotazione prova finale</Title>
            {content}
        </>)
    }
}

export default withRouter(RC30ExamReservation);