import React from "react";

import {
    MFile,
    ValidatedForm
} from "components/forms";

import {
    notNullObjectBuilder,
    payloadBuilder
} from "libs";

export class EnrollFillFormTFA extends ValidatedForm {

    emptyFields = {
        document: 0,
    };

    FIELDS_GROUP = [
        [{
            id: "document",
            field: "document",
            label: "Allegare i Moduli",
            infoText: "Caricare il Modulo opportunamente compilato e firmato",
            component: MFile,
            config: { endPoint: '/enrollment/attachment-file', fieldName: 'document', maxFiles: 4, minFiles: 3, accept: '.pdf,.doc,.docx,.jpg,.bmp,.jpeg' },
            className: "col-md-12"
        }]
    ];

    state = {
        loading: true,
        ...this.emptyFields,
        defaultValues: {
            ...this.emptyFields
        }
    };

    constructor(props) {
        super(props);

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    getPayload = () => {
        return this.payload;
    }

    loadRemote(additionalState = {}) {

        // merge not null values and format dates
        const defaultValues = notNullObjectBuilder(
            this.emptyFields,
            { document: this.props.enrollData.idEnrollment },
        );

        const newState = {
            defaultValues,
            loading: false,
            ...additionalState,
        };

        this.setState(newState);
    }

    saveRemote = () => {
        return false;
    };

    onSubmit = () => {
        //TODO: verificare se ha uploadato il file
        this.payload = payloadBuilder(this.state);

        const isValid = this.checkValidation();
        return isValid;
    }

    render() {

        const { loading, defaultValues } = this.state;
        if (loading) return <></>;

        const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

        return (<>
            <p>Allegare i Moduli A, C e D debitamente compilati e sottoscritti.
                Il Modulo B è eventuale e destinato esclusivamente ai richiedenti misure compensative ai sensi delle Leggi n. 104/1992 e n. 170/2010 e ss.mm.ii.
            </p>
            <ul>
                <li>
                    <a href="https://www.formazionedocenti.it/assets/doc/ModuloA-AutodichiarazionebandoordinarioTFA.pdf" target="_blank" rel="noopener noreferrer">Modulo A - Autodichiarazione Titoli di Accesso Soprannumerari-Idonei;</a>.
                </li>
                <li>
                    <a href="https://www.formazionedocenti.it/assets/doc/ModuloB-ModulodirichiestadistrumenticompensativitempiaggiuntiviTFA.pdf" target="_blank" rel="noopener noreferrer">Modulo B - Modulo di richiesta di strumenti compensativi;</a>.
                </li>
                <li>
                    <a href="https://www.formazionedocenti.it/assets/doc/ModuloC-NullaostasoprannumerariTFA.pdf" target="_blank" rel="noopener noreferrer">Modulo C - Istanza rilascio Nulla Osta per frequenza in Soprannumero percorsi sostegno presso altro Ateneo;</a>.
                </li>
                <li>
                    <a href="https://www.formazionedocenti.it/assets/doc/ModuloD-CertificatoidoneitTFA.pdf" target="_blank" rel="noopener noreferrer">Modulo D - Certificato di idoneità ai percorsi sostegno presso altro Ateneo.</a>.
                </li>
            </ul>
            {code}
        </>);
    }
}