import React from "react";
import { MCheckbox, ValidatedForm } from "components/forms";
import { Button } from "design-react-kit";
import { payloadBuilder } from "libs";
import { DiplomaSpecPerfStCeciliaForm } from "./DiplomaSpecPerfStCeciliaForm";
import { FormTitleStep2Configurator } from "./FormTitleStep2Configurator";

export class DiplomaSpecPerfStCecilia extends ValidatedForm {
  _formsDiplomaSpecPerfStCecilia = [];

  emptyFields = {
    checkDiplomaSpecPerfStCecilia: false,
  };

  guardParams = {
    maxForms: 2,
  };

  state = {
    defaultValues: {},
    numDiplomaSpecPerfStCeciliaMultipli: [],
    validationError: ""
  };

  constructor(props) {
    super(props);

    // bind event handler to invoke it from the child component
    this.onChange = this.onChange.bind(this);
  }

  FIELDS_GROUP = [
    [
      {
        field: "checkDiplomaSpecPerfStCecilia",
        label: "<b>Diploma di specializzazione e Diploma di perfezionamento conseguito presso l'Accademia Nazionale di Santa Cecilia</b>",
        component: MCheckbox,
        className: "col-md-12",
        onChange: (name, value) => {
          this.onClickcheckDiplomaSpecPerfStCecilia(value);
        },
      },
    ],
  ];

  ERROR_MESSAGES = {};

  componentDidMount() {
    this.setState({ defaultValues: this.emptyFields, ...this.emptyFields });
  }

  onClickcheckDiplomaSpecPerfStCecilia = (value) => {
    this.state.defaultValues.checkDiplomaSpecPerfStCecilia = value;

    if (value === false) {
      this.state.numDiplomaSpecPerfStCeciliaMultipli = [];
    }
  };

  onSubmit = () => {
    if (this.state.defaultValues.checkDiplomaSpecPerfStCecilia === false) {
      return true;
    }
    return this.onSubmitDiplomaSpecPerfStCecilia();
  };

  onSubmitDiplomaSpecPerfStCecilia = () => {

    let isValid = false;
    let validationError = '';

    if (this._formsDiplomaSpecPerfStCecilia.length > 0) {
      this._formsDiplomaSpecPerfStCecilia.forEach((form) => {
        if (form && form.current != null) {
          isValid = form.current.checkValidation();
        } else {
          isValid = false;
        }
      });
    } else {
      isValid = false;
      validationError = "Aggiungere almeno un titolo di studio o togliere la spunta di selezione";
    }

    this.setState({ validationError });

    return isValid;
  };

  getPayload = () => {
    const payloadOther = [];
    this.payload = payloadBuilder(this.state);

    if (this.payload.checkDiplomaSpecPerfStCecilia) {
      payloadOther.push({
        qualificationCode: "title21",
        classeConcorso: this.props.competitionClass,
        qualificationType: "Diploma di specializzazione e Diploma di perfezionamento conseguito presso l'Accademia Nazionale di Santa Cecilia",
        template: "DiplomaSpecPerfStCecilia",
        extCode: FormTitleStep2Configurator.getExtCode("DiplomaSpecPerfStCecilia"),
      });

      if (this._formsDiplomaSpecPerfStCecilia.length > 0) {
        this._formsDiplomaSpecPerfStCecilia.forEach((form) => {
          if (form && form.current != null) {
            // get payload
            const payloadDiplomaSpecPerfStCecilia = form.current.getPayload();
            payloadOther.push(payloadDiplomaSpecPerfStCecilia);
          }
        });
      }
    }

    return payloadOther;
  };

  addDiplomaSpecPerfStCecilia = () => {
    if (
      this.state.numDiplomaSpecPerfStCeciliaMultipli.length >= this.guardParams.maxForms
    ) {
      return false; // max forms reached
    }

    this.state.numDiplomaSpecPerfStCeciliaMultipli.push(
      this.state.numDiplomaSpecPerfStCeciliaMultipli.length + 1
    );
    this.setState({
      ...this.state,
      numDiplomaSpecPerfStCeciliaMultipli: this.state.numDiplomaSpecPerfStCeciliaMultipli,
    });
  };

  removeDiplomaSpecPerfStCecilia = (idx) => {
    if (this.state.numDiplomaSpecPerfStCeciliaMultipli.length <= 0) {
      return true;
    }

    this.state.numDiplomaSpecPerfStCeciliaMultipli.splice(idx, 1);
    this._formsDiplomaSpecPerfStCecilia.splice(idx, 1);

    this.setState({
      ...this.state,
      numDiplomaSpecPerfStCeciliaMultipli: this.state.numDiplomaSpecPerfStCeciliaMultipli,
    });
  };

  render() {

    const { defaultValues, validationError } = this.state;
    const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

    return (
      <>
        {code}
        <p>2 punti per ciascun diploma. <br /> <i>Massimo 4 punti complessivi</i>.</p>
        {(this.state.numDiplomaSpecPerfStCeciliaMultipli || []).map((_, idx) => {
          this._formsDiplomaSpecPerfStCecilia[idx] = React.createRef();

          return (
            <div
              key={`diplomaSpecPerfStCecilia=${idx}`}
              className="m-0 ml-5 mt-5 text-secondary"
            >
              <h6>N. {idx + 1}</h6>
              <Button
                className="mb-3 btn btn-sm btn-danger"
                onClick={() => {
                  this.removeDiplomaSpecPerfStCecilia(idx);
                }}
              >
                Rimuovi
              </Button>
              <div className="p-3 border border-secondary rounded-lg">
                <DiplomaSpecPerfStCeciliaForm
                  ref={this._formsDiplomaSpecPerfStCecilia[idx]}
                  key={`diplomaSpecPerfStCecilia=${idx}`}
                  idx={idx}
                />
              </div>
            </div>
          );
        })}

        {defaultValues.checkDiplomaSpecPerfStCecilia && (
          <div className="mt-3 ml-5 text-secondary">
            <Button
              className={`btn btn-sm btn ${this.state.numDiplomaSpecPerfStCeciliaMultipli.length >=
                this.guardParams.maxForms
                ? "btn-danger"
                : "btn-secondary"
                }`}
              onClick={this.addDiplomaSpecPerfStCecilia}
            >
              {this.state.numDiplomaSpecPerfStCeciliaMultipli.length >=
                this.guardParams.maxForms
                ? "puoi aggiungere Max: " +
                this.guardParams.maxForms +
                " titoli "
                : "Aggiungi"}
            </Button>
          </div>
        )}
        {(validationError) && (
          <div className="mt-3 ml-5 text-danger">
            {validationError}
          </div>
        )}
      </>
    );
  }
}
