import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import {
  BoxDanger,
  MnemoLoading,
  SupportoTecnico,
  Title,
} from "components/misc";
import {
  enrollmentAdminService,
  enrollmentService,
  networkErrorHelper,
} from "libs";
import { Button, Icon, Row } from "design-react-kit";
import { ROUTES } from "const";
import { FormEnabler, MLabel, MSelect, ValidatedForm } from "components/forms";
import ReactHtmlParser from "react-html-parser";

class RC30ConfirmExamSite extends ValidatedForm {
  emptyFields = {
    examSite: 0,
  };

  state = {
    loading: true,
    loadingError: false,
    enrollment: {},
    showExamSiteList: false,
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields,
    },
    validationSummary: "",
    newExamSite: "",
    rCode: "",
  };

  PAYLOADS = {
    examSites: [],
  };

  FIELDS_GROUP = [
    [
      {
        field: "preferExamPresence",
        label: "Sedi d'esame disponibili:",
        infoText: "",
        component: MLabel,
        className: "col-md-6 pt-3",
      },
    ],
    [
      {
        field: "examSite",
        payload: { options: this.PAYLOADS.examSites },
        component: MSelect,
        className: "col-md-6",
        onChange: (e, value) => {
          this.manageExamSite(e, value, 4);
        },
      },
    ],
  ];

  ERROR_MESSAGES = {
    examSite: "Specificare una sede esami",
  };

  validation = {
    examSite: (value) => value > "",
  };

  constructor(props) {
    super(props);
    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }

  //---

  goBack() {
    window.history.back();
  }

  onCancel = () => {
    this.setState({
      showExamSiteList: false,
      validationSummary: "",
    });

    this.resetForm();
  };

  manageExamSite(id, value) {
    this.setState({
      newExamSite: value,
      validationSummary: "",
    });
  }

  btnConfirm = () => {
    //const { rCode } = this.props.match.params;
    let payload = this.state.enrollment;
    payload.examSiteOld = payload.examSite;

    this.setState({ validationSummary: "" });
    this.resetForm();
    //console.log(this.state.enrollment);

    this.saveRemote(payload);
  };

  btnShowExamSiteList = () => {
    //const { rCode } = this.props.match.params;
    this.setState({ validationSummary: "" });
    this.setState({ showExamSiteList: !this.state.showExamSiteList });
    this.resetForm();
    this.loadRemoteExamSite();
  };

  componentDidMount() {
    const { rCode } = this.props.match.params;
    this.loadRemote(rCode);
  }

  loadRemote(rCode) {
    enrollmentService
      .myPendingDetail(rCode)
      .then(({ data }) => {
        const { enrollment } = data.payload;

        this.setState({
          enrollment,
          rCode,
          loading: false,
          loadingError: false,
        });
      })
      .catch((error) => {
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }

        this.setState({ loading: false, loadingError: true });
      });
  }

  loadRemoteExamSite() {
    const { idExamSession, courseCode } = this.state.enrollment;

    if (this.PAYLOADS.examSites.length < 1) {
      // load paylods to fill the select options
      enrollmentAdminService
        .examSites(idExamSession, courseCode)
        .then(({ data }) => {
          const { payload } = data;
          const sites = payload.map(({ value }) => ({
            label: value,
            value: value,
          }));

          this.PAYLOADS.examSites.unshift({ label: "Seleziona", value: "" });
          this.PAYLOADS.examSites.push(...sites);

          this.setState({
            examSites: { options: this.PAYLOADS.examSites },
          });
        })
        .catch((errors) => {
          console.log(errors);
          networkErrorHelper.notify(errors);
        });
    }
  }

  onSubmit = () => {
    let validationSummary = "";

    let isValidForm = this.checkValidation();
    if (!isValidForm) {
      validationSummary += "<li>Inserire i campi obbligatori in rosso</li>";
      this.setState({ validationSummary: validationSummary });
      return true;
    }

    /** save remote */
    let payload = this.state.enrollment;
    payload.examSiteOld = payload.examSite;
    payload.examSite = this.state.newExamSite;

    this.saveRemote(payload);
  };

  saveRemote(payload) {
    enrollmentService
      .updateEnrollmentConfirmExamSite(payload)
      .then(() => {
        this.setState({ showExamSiteList: false });
        this.loadRemote(payload.orderNumber);
        this.setState({ validationSummary: "" });
        this.resetForm();
      })
      .catch((errors) => {
        console.log(errors);
        networkErrorHelper.notify(errors);
      });
  }

  //---

  render() {
    const { loading, loadingError, rCode, defaultValues, validationSummary } =
      this.state;
    if (loading) return <MnemoLoading></MnemoLoading>;
    if (loadingError) {
      return (
        <BoxDanger className="mt-4">
          Si è verificato un errore durante il caricamento della pagina,
          <br />
          <SupportoTecnico />
        </BoxDanger>
      );
    }

    const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

    return this.state.enrollment.examSiteOld === null ? (
      <>
        <NavLink
          to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`}
          className="mx-5 btn btn-outline-primary float-right">
          <Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO
        </NavLink>
        <Title>Conferma o modifica la sede d'esame</Title>
        <hr />

        {this.state.showExamSiteList === false && (
          <>
            <p>
              Conferma o modifica della sede d’esame.
              <br />
              Vi informiamo che gli esami si terranno dal 27 febbraio al 12
              marzo 2025 (le date specifiche per ciascuna sede saranno
              comunicate almeno 20 giorni prima della prova).
              <br />
              La preghiamo di confermare o modificare la sede d’esame
              selezionata al momento dell’iscrizione{" "}
              <b>entro e non oltre giovedì 16 gennaio 2025</b>.<br />
              Dopo tale data la sede sarà considerata definitiva.
            </p>
          </>
        )}

        {this.state.showExamSiteList === true && (
          <>
            <p>
              La preghiamo di confermare o modificare la sede d’esame
              selezionata al momento dell’iscrizione <b>entro e non oltre giovedì 16 gennaio 2025</b>.<br />
              Dopo tale data la sede sarà considerata definitiva.
            </p>
          </>
        )}

        <p>
          La sede d’esame scelta al momento dell’iscrizione è:{" "}
          <b>{this.state.enrollment.examSite}</b>
        </p>

        <p>
          <Button color="primary bg-dark m-2" onClick={this.btnConfirm}>
            Conferma
          </Button>
          <Button
            color="primary bg-dark m-2"
            onClick={this.btnShowExamSiteList}>
            Modifica
          </Button>
        </p>

        {this.state.showExamSiteList === true && (
          <>
            <div>
              <form>
                {code}

                <Row className="mb-4">
                  <FormEnabler
                    onSubmit={this.onSubmit}
                    onCancel={this.onCancel}
                    isFormActive={true}
                  />
                </Row>
              </form>
            </div>

            <Row>
              <div className="w-100">
                {validationSummary !== "" && (
                  <div className="alert alert-danger mt-3" role="alert">
                    {ReactHtmlParser(validationSummary)}
                  </div>
                )}
              </div>
            </Row>
          </>
        )}
      </>
    ) : (
      <>
        <NavLink
          to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`}
          className="mx-5 btn btn-outline-primary float-right">
          <Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO
        </NavLink>
        <Title>Sede d'esame</Title>
        <hr />
        <p>
          La sede d'esame presso la quale svolgerà l'esame finale è :{" "}
          <b>{this.state.enrollment.examSite}</b>
        </p>
      </>
    );
  }
}

export default withRouter(RC30ConfirmExamSite);
