import React from "react";
import {
  MButton2,
  MInput,
  MSearchUniversity,
  ValidatedForm,
} from "components/forms";
import { payloadBuilder } from "libs";
import { FormTitleStep2Configurator } from "./FormTitleStep2Configurator";

export class MasterUniversitariAccademiciForm extends ValidatedForm {
  
  FIELDS_GROUP = [
    [
      {
        id: "searchUniversity003" + this.props.idx,
        component: MButton2,
        onClick: () => {
          this.searchUniversity();
        },
        label: "Cerca Ateneo",
        className: "col-md-12 mb-3",
        classElement: "btn btn-primary bg-dark",
      },
    ],
    [
      {
        id: "istitution003" + this.props.idx,
        field: "istitution",
        label: "Presso",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
      {
        id: "city003" + this.props.idx,
        field: "city",
        label: "Città",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
    ],
    [
      {
        id: "description003" + this.props.idx,
        field: "description",
        label: "Denominazione del Titolo",
        component: MInput,
        type: "text",
        className: "col-md-12",
      },
    ],
    [
      {
        id: "aa003" + this.props.idx,
        field: "aa",
        label: "Anno Accademico conseguimento Titolo",
        component: MInput,
        infoText: "inserire formato AAAA/AAAA",
        className: "col-md-4 pt-3",
      },
      {
        id: "dataConseguimento003" + this.props.idx,
        field: "dataConseguimento",
        label: "Data conseguimento titolo",
        component: MInput,
        type: "date",
        className: "col-md-4 pt-3",
      },
      {
        id: "votoTitolo003" + this.props.idx,
        field: "votoTitolo",
        label: "Votazione finale",
        component: MInput,
        type: "text",
        className: "col-md-4 pt-3",
      },
    ],
  ];

  ERROR_MESSAGES = {
    istitution: "Inserire l'Istituzione universitaria o accademica",
    city: "Inserire l'Istituzione universitaria o accademica",
    description: "Inserire la denominazione del titolo",
    aa: "Inserire l'Anno Accademico",
    dataConseguimento: "Inserire la data di conseguimento",
    votoTitolo: "Inserire la votazione finale",
  };

  validation = {
    istitution: (value) => value !== "",
    city: (value) => value !== "",
    description: (value) => value !== "",
    aa: (value) => this.validateAA(value),
    dataConseguimento: (value) => this.validateDataConseguimento(value),
    votoTitolo: (value) => this.validateVoteBaseVote(value),
  };

  validateAA = (value) => {
    let aa = /^(\d{4})\/(\d{4})$/.test(value);

    if (!aa) {
      return false;
    } else {
      aa = value.split("/");

      if (parseInt(aa[1]) > new Date().getFullYear()) {
        return false;
      }

      if (parseInt(aa[1]) === parseInt(aa[0]) + 1) {
        return true;
      } else {
        return false;
      }
    }
  }; //validateAA

  validateDataConseguimento = (value) => {
    if (value === "") {
      return false;
    }
    let regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!value.match(regex)) {
      return false;
    }

    //--- ---
    const today = new Date();
    const date = new Date(value);

    if (date > today) {
      return false;
    }

    if (Number.isNaN(date.getTime())) {
      return false;
    }
    const [year, month, day] = value.split("-").map(Number);

    if (month > 12) {
      return false;
    }
    if (day > 31) {
      return false;
    }

    const currentYear = new Date().getFullYear();
    if (year < currentYear - 90 || year > currentYear) {
      return false;
    }
    return true;
  };

  validateVoteBaseVote = (value) => {
    if (value === "") {
      return false;
    }

    if (isNaN(value)) {
      return false;
    }

    if (value > 110) {
      return false;
    }

    return true;
  };

  emptyFields = {
    description: "",
    istitution: "",
    city: "",
    aa: "",
    dataConseguimento: "",
    votoTitolo: "",
  };

  state = {
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields,
    },
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  searchUniversity = () => {
    this.setState({ showSearchUniversity: !this.state.showSearchUniversity });
  };

  onChangeUniversity = (university) => {
    this.onChange("city", university.city);
    this.onChange("istitution", university.istitution);
    this.setState({ showSearchUniversity: false, university: university });
  };

  getPayload = () => {
    this.payload = payloadBuilder(this.state);
    this.payload.qualificationType = "Master Universitari ed Accademici di secondo livello";
    this.payload.qualificationCode = "title21";
    this.payload.nation = "Italia";
    this.payload.template = "MasterUniversitariAccademiciItem";
    this.payload.extCode = FormTitleStep2Configurator.getExtCode("MasterUniversitariAccademici");

    if (this.state.university) {
      this.payload.uniCode = this.state.university.uniCode;
    }

    return this.payload;
  };

  render() {
    const { defaultValues, showSearchUniversity } = this.state;
    const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

    return (
      <>
        {showSearchUniversity ? (
          <>
            <MSearchUniversity
              onChange={this.onChangeUniversity}
              searchUniversity={this.searchUniversity}
              isForeign={false}
            />
          </>
        ) : (
          <>
          {code}
          <p><b>ATTENZIONE</b>: dichiarare solamente Master Universitari ed Accademici di <b>SECONDO LIVELLO</b> </p>
          </>

        )}
      </>
    );
  }
}
