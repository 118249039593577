import React, {Component} from 'react';
import { MCheckbox, MSelect } from 'components/forms';

export class FormCS24 extends Component {

    PAYLOAD=
    {options: [
        { label:'selezionare una condizione', value : ''},
        { label: 'non essere iscritto ad altri corsi Universitari per il medesimo Anno Accademico', value: 'NO' },
        { label: 'essere iscritto ad altro corso Universitario', value: 'SI' }
    ]}

    state = {
        courses: [],
        condition:'',
        validationError:''
    }

    getValue(){

        const pluginValue = { 
            courses: this.state.courses.join(','), 
            condition : this.state.condition 
        };

        return pluginValue;
    }

    checkValidation() {
        
        let validationError = '';

        const isValidA = this.state.courses.length === 3; // (this.state.courses.length>0 && this.state.courses.length<=3);
        if (!isValidA){            
            validationError ='Dovresti selezionare tre percorsi formativi';
        }

        const isValidB = (this.state.condition!=='');
        if (!isValidB){
            validationError +='.  Dovresti specificare una condizione';
        }

        this.setState({validationError})

        return (isValidA && isValidB);       
    }    

    onClick = (id, value) => {

        if (value) {
            this.setState({ courses: this.state.courses.concat([id]) });
        } else {
            var array = [...this.state.courses]; // make a separate copy of the array
            var index = array.indexOf(id)
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({ courses: array });
            }
        }        
    }

    onChangeCondition= (id, value) => {

        this.setState({ condition:value}); 
    }

    render() {

        return (
            <>
                <h6>Percorsi Formativi 24 CFU: seleziona tre Corsi</h6>
                <hr />               
                <MCheckbox className="invalid" onChange={this.onClick} id="AD120" name="AD120" >Psicologia dell'apprendimento (9 CFU) - M-PSI/04 - Ambito B</MCheckbox>
                <MCheckbox onChange={this.onClick} id="AD123" name="AD123">Teoria e metodi di progettazione e valutazione didattica (9 CFU) - M-PED/04 - Ambito D</MCheckbox>
                <MCheckbox onChange={this.onClick} id="AD121" name="AD121" >Antropologia culturale (9 CFU) - M-DEA/01 - Ambito C</MCheckbox>
                <MCheckbox onChange={this.onClick} id="AD122" name="AD122">Didattica speciale (9 CFU) - M-PED/03 - Ambito A</MCheckbox>      
                <hr/>
                <h6>Dichiaro di</h6>
                <MSelect onChange={this.onChangeCondition} id="condition"  payload={this.PAYLOAD }></MSelect>         
                {this.state.validationError && (<><span className="form-text text-danger is-invalid">{this.state.validationError}</span></>)}
                <hr />
            </>
        );
    }
}