import React from "react";
import { COURSE_TYPES, ENROLLMENT_STEPS, PAYMENT_TYPES } from 'const';
import {
    EnrollFillFormPerfezionamento, EnrollFillFormESB,
    EnrollFillFormICDL, EnrollFillFormCLIL,
    EnrollFillFormCVL, EnrollFillFormM24,
    EnrollFillFormABA, EnrollFillFormDS2,
    EnrollFillFormMU, EnrollFillFormBU2,
    EnrollFillForm30CFU,
    EnrollFillFormPFU,
    EnrollFillFormTFA, EnrollFillFormTFAOrdinario,
    EnrollmentFillFormRCCFU60ALL1,
    EnrollmentFillFormRCCFU30ALL2,
    EnrollFillAdesioniSostegno,
    EnrollFillForm60CFUStep1,
    EnrollFillForm30CFUStep1,    
    EnrollFillForm30All2,
    EnrollFillForm36All5,
} from "./";

import {
    networkErrorHelper,
    enrollmentService,
    CourseHelper
} from "libs";
import dayjs from "dayjs";
import { EnrollFillForm30All4 } from "./EnrollFillForm30All4";

export class EnrollFill {

    FORMS = {
        'perfezionamento': EnrollFillFormPerfezionamento,
        'ESB': EnrollFillFormESB,
        'DS2': EnrollFillFormDS2,
        'BU2': EnrollFillFormBU2,
        'ICDL': EnrollFillFormICDL,
        'CLIL': EnrollFillFormCLIL,
        'ABA': EnrollFillFormABA,
        'CVL': EnrollFillFormCVL,
        'M24': EnrollFillFormM24,
        'MU': EnrollFillFormMU,
        'PFU': EnrollFillFormPFU,
        '30CFU': EnrollFillForm30CFU,
        '30CFUStep1': EnrollFillForm30CFUStep1,
        '60CFUStep1': EnrollFillForm60CFUStep1,
        'CORSI30ALL2': EnrollFillForm30All2,
        'CORSI30ALL4': EnrollFillForm30All4,
        'CORSI36ALL5': EnrollFillForm36All5,
        'TFA': EnrollFillFormTFA,
        'RCCFU60ALL1': EnrollmentFillFormRCCFU60ALL1,
        'RCCFU30ALL2': EnrollmentFillFormRCCFU30ALL2,
        'TFAOrdinario': EnrollFillFormTFAOrdinario,
        'AdesioniSostegno': EnrollFillAdesioniSostegno
    };

    title = 'Completa dati';
    iconName = 'it-files';
    enrollment = null;
    course = null;
    payloadForm = null;
    canNext = false;
    _formRef = null;

    constructor() {
        this._formRef = React.createRef();
    }

    setPayload(payload) {
        this.enrollment = payload.enrollment;
        this.course = payload.course;
    }

    async onNextStep() {

        this.canNext = this._formRef.current.onSubmit();

        if (this.canNext) {
            this.payloadForm = this._formRef.current.getPayload();
            await this.updateRemote();
        }

        return this.canNext;
    }

    onPrevStep = () => {
        return true;
    }

    async updateRemote() {

        const isDS_II = CourseHelper.isDSII(this.course.code);
        const isBU_II = CourseHelper.isBUII(this.course.code);

        let currentStep = ENROLLMENT_STEPS.SIGN;

        const fillforms = this.course.fillform.split(',');
        if (fillforms.length > 1) {
            currentStep = ENROLLMENT_STEPS.FILL2;
        } else {

            if (isDS_II) {
                currentStep = ENROLLMENT_STEPS.PAY;
            }

            if (CourseHelper.isUnicamillus(this.course.code) || CourseHelper.isIUL(this.course.code)) {

                if (isBU_II) {
                    if (this.payloadForm.paymentType === PAYMENT_TYPES.CARTA_DEL_DOCENTE) {
                        currentStep = ENROLLMENT_STEPS.PAY;
                    } else {
                        currentStep = ENROLLMENT_STEPS.SIGN;
                    }
                } else {
                    currentStep = ENROLLMENT_STEPS.SIGN;
                }
            }
        }

        // 
        this.enrollment.currentStep = currentStep;
        this.enrollment.examSite = this.payloadForm.examSite;
        this.enrollment.examSiteOnline = this.payloadForm.examSiteOnline;
        this.enrollment.hasBonusCourse = this.payloadForm.hasBonusCourse;
        this.enrollment.idFreeCourse = this.payloadForm.idFreeCourse;
        this.enrollment.titleFreeCourse = this.payloadForm.titleFreeCourse;
        this.enrollment.idExamSession = this.payloadForm.idExamSession;
        this.enrollment.examSession = this.payloadForm.examSession;

        if (this.payloadForm.pluginConfig) {
            this.enrollment.pluginConfig = this.payloadForm.pluginConfig;
        }

        if (this.payloadForm.competitionClass) {
            this.enrollment.competitionClass = this.payloadForm.competitionClass;
        }

        if (this.payloadForm.usr) {
            this.enrollment.usr = this.payloadForm.usr;
        }

        if (isDS_II || isBU_II) {
            this.enrollment.isPaymentReferred = this.payloadForm.isPaymentReferred;
            this.enrollment.importToPay = this.payloadForm.importToPay;
            this.enrollment.officeCode = this.payloadForm.officeCode;
            this.enrollment.promoImport = this.payloadForm.promoImport;
            this.enrollment.promoDescription = this.payloadForm.promoDescription;
        }

        if (isBU_II) {
            this.enrollment.paymentType = this.payloadForm.paymentType;
        }

        if (this.course.courseType === COURSE_TYPES.REQUEST) {
            this.enrollment.currentStep = ENROLLMENT_STEPS.END;
            this.enrollment.dateEnd = dayjs();
        }

        await enrollmentService.updateEnrollment(this.enrollment)
            .then(() => {

                // if BU-II reload the wizard to update steps 
                if (CourseHelper.isBUII(this.enrollment.courseCode)) {
                    window.location.reload();
                }

                this.canNext = true;
            })
            .catch(errors => {
                console.log(errors);
                this.canNext = false;
                networkErrorHelper.notify(errors);
            });
    };

    render() {

        const enrollData =
        {
            officeCode: this.enrollment.officeCode,
            examSite: this.enrollment.examSite,
            idExamSession: this.enrollment.idExamSession,
            hasBonusCourse: this.enrollment.hasBonusCourse,
            idFreeCourse: this.enrollment.idFreeCourse,
            courseCode: this.course.code,
            registrationCode: this.enrollment.orderNumber,
            paymentType: this.enrollment.paymentType,
            idEnrollment: this.enrollment.id,
            pluginConfig: this.enrollment.pluginConfig,
            currentStep: 'fill',
        }

        const forms = this.course.fillform.split(',');
        const Component = this.FORMS[forms[0]];

        return (<>
            <div className="pt-5">
                <Component
                    ref={this._formRef}
                    onSaveRemote={this.onSaveRemote}
                    enrollData={enrollData} />
            </div>
        </>);
    }
}