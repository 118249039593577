import React from "react";
import { MCheckbox, ValidatedForm } from "components/forms";
import { Button } from "design-react-kit";
import { payloadBuilder } from "libs";
import { MasterUniversitariAccademiciForm } from "./MasterUniversitariAccademiciForm";


export class MasterUniversitariAccademici extends ValidatedForm {
  _formsMasterUniversitariAccademici = [];

  emptyFields = {
    checkMasterUnivAccademici: false,
  };

  guardParams = {
    maxForms: 2,
  };

  state = {
    defaultValues: {},
    error: "",
    numMasterUnivAccademiciMultipli: [],
    validationError: ""
  };

  constructor(props) {
    super(props);

    // bind event handler to invoke it from the child component
    this.onChange = this.onChange.bind(this);
  }

  FIELDS_GROUP = [
    [
      {
        field: "checkMasterUnivAccademici",
        label: "<b>Master Universitari ed Accademici di secondo livello.</b>",
        component: MCheckbox,
        className: "col-md-12",
        onChange: (name, value) => {
          this.onClickcheckMasterUnivAccademici(value);
        },
      },
    ],
  ];

  ERROR_MESSAGES = {};

  componentDidMount() {
    this.setState({ defaultValues: this.emptyFields, ...this.emptyFields });
  }

  onClickcheckMasterUnivAccademici = (value) => {
    this.state.defaultValues.checkMasterUnivAccademici = value;

    if (value === false) {
      this.state.numMasterUnivAccademiciMultipli = [];
    }
  };

  onSubmit = () => {
    if (this.state.defaultValues.checkMasterUnivAccademici === false) {
      return true;
    }
    return this.onSubmitMasterUnivAccademici();
  };

  onSubmitMasterUnivAccademici = () => {
    
    let isValid = false;
    let validationError = '';

    if (this._formsMasterUniversitariAccademici.length > 0) {
      this._formsMasterUniversitariAccademici.forEach((form) => {
        if (form && form.current != null) {
          isValid = form.current.checkValidation();
        } else {
          isValid = false;
        }
      });
    }else{
      isValid = false;
      validationError = "Aggiungere almeno un dottorato di ricerca o togliere la spunta di selezione";
    }

    this.setState({validationError});

    return isValid;
  };

  getPayload = () => {
    const payloadOther = [];
    this.payload = payloadBuilder(this.state);

    if (this.payload.checkMasterUnivAccademici) {
      payloadOther.push({
        qualificationCode: "title21",
        classeConcorso: this.props.competitionClass,
        qualificationType: "Master Universitari ed Accademici di secondo livello",
        template: "MasterUniversitariAccademici",
        
      });

      if (this._formsMasterUniversitariAccademici.length > 0) {
        this._formsMasterUniversitariAccademici.forEach((form) => {
          if (form && form.current != null) {
            // get payload
            const payloadmasterUnivAccademici = form.current.getPayload();
            payloadOther.push(payloadmasterUnivAccademici);
          }
        });
      }
    }

    return payloadOther;
  };

  addMasterUniversitariAccademici = () => {
    if (
      this.state.numMasterUnivAccademiciMultipli.length >= this.guardParams.maxForms
    ) {
      return false; // max forms reached
    }

    this.state.numMasterUnivAccademiciMultipli.push(
      this.state.numMasterUnivAccademiciMultipli.length + 1
    );
    this.setState({
      ...this.state,
      numMasterUnivAccademiciMultipli: this.state.numMasterUnivAccademiciMultipli,
    });
  };

  removeMasterUniversitariAccademici = (idx) => {
    if (this.state.numMasterUnivAccademiciMultipli.length <= 0) {
      return true;
    }

    this.state.numMasterUnivAccademiciMultipli.splice(idx, 1);
    this._formsMasterUniversitariAccademici.splice(idx, 1);

    this.setState({
      ...this.state,
      numMasterUnivAccademiciMultipli: this.state.numMasterUnivAccademiciMultipli,
    });
  };

  render() {
    
    const { defaultValues, validationError } = this.state;
    const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

    return (
      <>
        {code}
        <p>1 punto per ciascun master. <br /> <i>Massimo 2 punti complessivi</i>.</p>
        {(this.state.numMasterUnivAccademiciMultipli || []).map((_, idx) => {
          this._formsMasterUniversitariAccademici[idx] = React.createRef();

          return (
            <div
              key={`masterUniversitariAccademici=${idx}`}
              className="m-0 ml-5 mt-5 text-secondary"
            >
              <h6>Master Universitario ed Accademico di secondo livello n. {idx + 1}</h6>
              <Button
                className="mb-3 btn btn-sm btn-danger"
                onClick={() => {
                  this.removeMasterUniversitariAccademici(idx);
                }}
              >
                Rimuovi
              </Button>
              <div className="p-3 border border-secondary rounded-lg">
                <MasterUniversitariAccademiciForm
                  ref={this._formsMasterUniversitariAccademici[idx]}
                  key={`masterUniversitariAccademici=${idx}`}
                  idx={idx}
                />
              </div>
            </div>
          );
        })}

        {defaultValues.checkMasterUnivAccademici && (
          <div className="mt-3 ml-5 text-secondary">
            <Button
              className={`btn btn-sm btn ${this.state.numMasterUnivAccademiciMultipli.length >=
                  this.guardParams.maxForms
                  ? "btn-danger"
                  : "btn-secondary"
                }`}
              onClick={this.addMasterUniversitariAccademici}
            >
              {this.state.numMasterUnivAccademiciMultipli.length >=
                this.guardParams.maxForms
                ? "puoi aggiungere Max: " +
                this.guardParams.maxForms +
                " titoli"
                : "Aggiungi"}
            </Button>
          </div>
        )}
           {(validationError) && (
          <div className="mt-3 ml-5 text-danger">
            {validationError}
          </div>
        )}
      </>
    );
  }
}
