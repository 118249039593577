import React from "react";

import {
  MSearchSchool,
  MSearchUniversity,
  ValidatedForm,
} from "components/forms";
import { enrollmentService, networkErrorHelper, payloadBuilder } from "libs";
import { FormTitleStep2Configurator } from "./FormTitleStep2Configurator";

export class FormTitleStep2 extends ValidatedForm {
  constructor(props) {
    super(props);

    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }

  state = {
    showSearchUniversity: false,
    showSearchSchool: false,
    university: {},
    school: {},
    ...this.emptyFields,
    defaultValues: {},
    qualifications: null,
    validationError: "",
  };

  ERROR_MESSAGES = {
    istitution: "Dato obbligatorio",
    description: "Dato obbligatorio",
    dataConseguimento: "Dato non valido",
    aa: "Inserire l'anno accademico in formato AAAA/AAAA",
    votoTitolo: "Dato obbligatorio e numerico",
    city: "Dato obbligatorio",
  };

  emptyFields = {
    description: "",
    istitution: "",
    aa: "",
    dataConseguimento: "",
    votoTitolo: "",
    city: "",
  };

  componentDidMount() {
    const defaultValues = FormTitleStep2Configurator.getDefaultValues(
      this.props.template
    );
    this.setState({ defaultValues: defaultValues });

    if(['LAU_03', 'LAU_VO', 'LAU_02_509', 'LAU_02_270', 'LAU_CU_270'].includes(this.props.extCode)) {
      this.loadRemote();
    }
  }

  loadRemote = () => {
    const { registrationCode, idEnrollment } = this.props.enrollData;

    enrollmentService
      .getEnrollmentQualificationByQCode(
        "title11",
        registrationCode,
        idEnrollment
      )
      .then(({ data }) => {
        if (data) {
          this.setState({
            qualifications: data.payload?.qualifications,
          });
        }
      })
      .catch((errors) => {
        console.log(errors);
        networkErrorHelper.notify(errors);
      });
  };

  validation = {
    istitution: (value) => value !== "",
    description: (value) => value !== "",
    city: (value) => value !== "",
    dataConseguimento: (value) => this.validateDataConseguimento(value),
    aa: (value) => this.validateAA(value),
    votoTitolo: (value) => this.validateVotoTitolo(value),
  };

  validateAA = (value) => {
    this.setState({ validationError: "" });

    //--- reset dataConseguimento ---
    this.setState({ dataConseguimento: "" });

    let aa = /^(\d{4})\/(\d{4})$/.test(value);

    if (!aa) {
      return false;
    } else {
      aa = value.split("/");

      const currentYear = new Date().getFullYear();
      console.log(parseInt(aa[1]), currentYear);
      if (parseInt(aa[1]) > currentYear) {
        return false;
      }

      if (parseInt(aa[1]) === parseInt(aa[0]) + 1) {
        return true;
      } else {
        return false;
      }
    }
  }; //validateAA

  validateDataConseguimento = (value) => {
    this.setState({ validationError: "" });

    let regex = /^\d{4}-\d{2}-\d{2}$/;
    if (value === "" || !value.match(regex)) {
      return false;
    }

    //--- ---
    const today = new Date();
    const formDate = new Date(value);

    //in the future not valid
    if (formDate > today) {
      return false;
    }

    //--- ---
    if (Number.isNaN(formDate.getTime())) {
      return false;
    }
    const [year, month, day] = value.split("-").map(Number);

    if (month > 12 || day > 31) {
      return false;
    }

    const currentYear = new Date().getFullYear();
    if (year < currentYear - 90 || year > currentYear) {
      return false;
    }

    //--- guard on compliant AA --
    if (this.state.aa?.value) {
      const aa = this.state.aa.value.split("/");
      const firstDate = new Date(`${parseInt(aa[1])}-03-01`);
      const secondDate = new Date(`${parseInt(aa[1]) + 1}-04-30`);

      if (formDate < firstDate || formDate > secondDate) {
        return false;
      }
    }

    //--- guard on dataConseguimentoTitoloDiAccesso ---
    if (this.state.qualifications && ['LAU_03', 'LAU_VO', 'LAU_02_509', 'LAU_02_270', 'LAU_CU_270'].includes(this.props.extCode)) {
      for (const qualification of this.state.qualifications) {
        if (qualification.qualificationCode === "title11") {
          console.log(qualification.dataConseguimento);
          const dataConseguimentoTitoloDiAccesso = new Date(
            qualification.dataConseguimento
          );

          // estraggo solo mese e anno per la verifica
          const formMonth = formDate.getMonth();
          const formYear = formDate.getFullYear();
          const accessMonth = dataConseguimentoTitoloDiAccesso.getMonth();
          const accessYear = dataConseguimentoTitoloDiAccesso.getFullYear();

          if (formMonth === accessMonth && formYear === accessYear) {
            this.setState({
              validationError:
                "Titolo di studio utilizzato già come Titolo di accesso.",
            });

            return false;
          }
        }
      }
    }

    return true;
  };

  validateVotoTitolo = (value) => {
    if (parseInt(value) < 60 || parseInt(value) > 110) {
      return false;
    }

    if (isNaN(value)) {
      return false;
    }

    return true;
  };

  getPayload = () => {
    this.payload = payloadBuilder(this.state);
    this.payload.qualificationType = this.props.qualificationType;
    this.payload.template = this.props.template;
    this.payload.qualificationCode = "title21";
    this.payload.extCode = this.props.extCode;
    if (!this.payload.nation) {
      this.payload.nation = "Italia";
    }

    // eccezione St Cecilia
    if (this.payload.istitution === "Accademia Nazionale di Santa Cecilia") {
      this.payload.uniCode = "T50";
      return this.payload;
    }

    if (this.state.university && this.state.university.uniCode) {
      this.payload.uniCode = this.state.university.uniCode;
    }
    if (this.state.school && this.state.school.schoolCode) {
      this.payload.uniCode = this.state.school.schoolCode;
    }

    return this.payload;
  };

  searchSchool = () => {
    this.setState({ showSearchSchool: !this.state.showSearchSchool });
  };

  onChangeSchool = (school) => {
    this.onChange("city", school.city);
    this.onChange("istitution", school.istitution);
    this.onChange("province", school.province);
    this.onChange("address", school.address);
    this.setState({ showSearchSchool: false, school: school, university: {} });
  };

  searchUniversity = () => {
    this.setState({ showSearchUniversity: !this.state.showSearchUniversity });
  };

  onChangeUniversity = (university) => {
    this.onChange("city", university.city);
    this.onChange("istitution", university.istitution);
    this.setState({
      showSearchUniversity: false,
      university: university,
      school: {},
    });
  };

  render() {
    const {
      defaultValues,
      showSearchUniversity,
      showSearchSchool,
      validationError,
    } = this.state;

    const fields = FormTitleStep2Configurator.getFields(
      this.props.template,
      this.searchUniversity,
      this.searchSchool
    );

    const { code } = this.renderFields(fields, defaultValues, true);

    return (
      <>
        {showSearchUniversity && (
          <>
            <MSearchUniversity
              onChange={this.onChangeUniversity}
              searchUniversity={this.searchUniversity}
              isForeign={false}
            />
          </>
        )}
        {showSearchSchool && (
          <>
            <MSearchSchool
              onChange={this.onChangeSchool}
              searchSchool={this.searchSchool}
            />
          </>
        )}
        {!(showSearchUniversity || showSearchSchool) && (
          <form className="user mt-5">{code}</form>
        )}

        {validationError !== "" && (
          <span className="text-danger">{this.state.validationError}</span>
        )}
      </>
    );
  }
}
