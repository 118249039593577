import React from "react";

import {
  FormEnabler,
  ValidatedForm,
  MInput,
  MTextArea,
} from "components/forms";

import {
  payloadBuilder,
  invalidFieldsMapper,
  networkErrorHelper,
  notNullObjectBuilder,
  adminService,
} from "libs";

import { Card, CardBody } from "design-react-kit";

export class AdminSurvey extends ValidatedForm {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      ...this.emptyFields,
      defaultValues: { ...this.emptyFields },
      formActive: parseInt(this.props.idSurvey) > 0 ? false : true,
    };

    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }

  //--- ---
  emptyFields = {
    title: "",
    code: "",
    description: "",
    aa: "",
    footer: "",
  };

  //--- ---
  FIELDS_GROUP = [
    [
      {
        id: "title",
        field: "title",
        label: "titolo",
        component: MInput,
        type: "text",
        className: "col-md-12",
      },
    ],
    [
      {
        id: "code",
        field: "code",
        label: "Codice",
        component: MInput,
        type: "text",
        className: "col-md-5 mt-4",
      },
      {
        id: "aa",
        field: "aa",
        label: "Anno Accademico",
        component: MInput,
        type: "text",
        className: "col-md-3 mt-4",        
        infoText: "inserire formato AAAA",
      },
    ],
    [
      {
        id: "description",
        field: "description",
        label: "Descrizione",
        component: MTextArea,
        className: "col-md-12",
        infoText: "",
      },
      {
        id: "footer",
        field: "footer",
        label: "Footer",
        component: MTextArea,
        className: "col-md-12",
        infoText: "",
      },
      {
        id: "metadata",
        field: "metadata",
        label: "Metadata - Configurazione Survey",
        component: MTextArea,
        rows: 6,
        className: "col-md-12 mb-5",
      },
      /*{
        id: "content",
        field: "content",
        label: "Page editor",
        component: MCE,
        className: "col-md-12 mt-4",
        infoText: "Specificare il testo",
      },*/
    ],
  ];

  ERROR_MESSAGES = {
    title: "Titolo non valido",
    aa: "Anno Accademico obbligatorio (AAAA)",
    code: "Codice obbligatorio"
  };

  validation = {
    title: (value) => value.length > 0,
    code: (value) => value.length > 0,
    aa: (value) => /^\d{4}$/i.test(value) && value !== null,
  };

  //--- ---

  componentDidMount() {
    this.loadRemote();
  }

  loadRemote(additionalState = {}) {
    //--- prevent loadRemote when not required ---
    if (parseInt(this.props.idSurvey) <= 0) {
      return false;
    }

    adminService
      .loadPage(parseInt(this.props.idSurvey))
      .then(({ data }) => {
        const defaultValues = notNullObjectBuilder(
          this.emptyFields,
          data.payload.page
        );

        const newState = {
          defaultValues,
          ...additionalState,
          loading: false,
        };

        this.setState(newState);
      })
      .catch((error) => {
        networkErrorHelper.notify(error);
        console.log(error);
      });
  } //loadRemote

  onBackPage = () => {
    window.history.back();
  }; //onBackPage

  saveRemote = () => {
    let payload = payloadBuilder(this.state);

    if (parseInt(this.props.idSurvey) > 0) {
      adminService
        .updatePage(payload)
        .then((result) => {
          this.onBackPage();
        })
        .catch((errors) => {
          if (networkErrorHelper.is422(errors)) {
            const newState = invalidFieldsMapper(
              errors,
              this.state,
              this.ERROR_MESSAGES
            );
            this.setState({ ...newState });
          } else {
            networkErrorHelper.notify(errors);
          }
        });
    } else {
      adminService
        .insertSurvey(payload)
        .then((result) => {
          this.onBackPage();
        })
        .catch((errors) => {
          if (networkErrorHelper.is422(errors)) {
            const newState = invalidFieldsMapper(
              errors,
              this.state,
              this.ERROR_MESSAGES
            );
            this.setState({ ...newState });
          } else {
            networkErrorHelper.notify(errors);
          }
        });
    }
  }; //saveRemote

  //--- ---

  render() {
    if (this.loading) {
      return <></>;
    }

    let { formActive, defaultValues } = this.state;

    if (parseInt(this.props.idSurvey) === 0) {
      formActive = true;
    }

    const { formValid, code } = this.renderFields(
      this.FIELDS_GROUP,
      defaultValues,
      formActive
    );

    return (
      <>
        <Card
          tag="div"
          className="card-bg card-big rounded shadow border-bottom-card">
          <div className="flag-icon" />
          <CardBody tag="div">
            <form className="user mt-5" id="courseform">
              {code}
            </form>

            <div className="mt-4">
              <FormEnabler
                onSubmit={this.onSubmit}
                onCancel={
                  parseInt(this.props.idSurvey) > 0
                    ? this.onCancel
                    : this.onBackPage
                }
                onToggle={this.toggleForm}
                isFormActive={formActive}
                disableSave={!formValid}
              />
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
