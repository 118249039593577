import React from "react";
import { ENROLLMENT_STEPS } from 'const';
import {
   EnrollFillForm30CFUStep2,
   EnrollFillForm60CFUStep2
} from "./";

import {
    networkErrorHelper,
    enrollmentService,
} from "libs";

export class EnrollFill2 {

    FORMS = {       
        '30CFUStep2': EnrollFillForm30CFUStep2,
        '60CFUStep2': EnrollFillForm60CFUStep2,
    };

    // TODO: anche il title va settato dinamicamente
    title = 'TITOLI VALUTABILI';
    iconName = 'it-files';
    enrollment = null;
    course = null;
    payloadForm = null;
    canNext = false;
    _formRef = null;

    constructor() {
        this._formRef = React.createRef();
    }

    setPayload(payload) {
        this.enrollment = payload.enrollment;
        this.course = payload.course;
    }

    async onNextStep() {       

        this.canNext = this._formRef.current.onSubmit();

        if (this.canNext) {
            this.payloadForm = this._formRef.current.getPayload();
            await this.updateRemote();
        }

        return this.canNext;
    }

    onPrevStep = () => {
        return true;
    }

    async updateRemote() {
        
        let currentStep = ENROLLMENT_STEPS.SIGN;
        
        const fillforms =  this.course.fillform.split(',');
        if (fillforms.length > 2) {
            currentStep = ENROLLMENT_STEPS.FILL3;
        }

        // 
        this.enrollment.currentStep = currentStep;
       
        if (this.payloadForm.pluginConfig) {
            this.enrollment.pluginConfig = this.payloadForm.pluginConfig;
        }

        if (this.payloadForm.competitionClass) {
            this.enrollment.competitionClass = this.payloadForm.competitionClass;
        }             

        await enrollmentService.updateEnrollment(this.enrollment)
            .then(() => {                

                this.canNext = true;
            })
            .catch(errors => {
                console.log(errors);
                this.canNext = false;
                networkErrorHelper.notify(errors);
            });
    };

    render() {

        const enrollData =
        {
            officeCode: this.enrollment.officeCode,
            examSite: this.enrollment.examSite,
            idExamSession: this.enrollment.idExamSession,
            hasBonusCourse: this.enrollment.hasBonusCourse,
            idFreeCourse: this.enrollment.idFreeCourse,
            courseCode: this.course.code,
            registrationCode : this.enrollment.orderNumber,
            paymentType: this.enrollment.paymentType,
            idEnrollment: this.enrollment.id,
            pluginConfig: this.enrollment.pluginConfig,
            currentStep: 'fill2',  
            
        }

        const fillforms = this.course.fillform.split(',');
        const Component = this.FORMS[fillforms[1]];

        return (<>
            <div className="pt-5">
                <Component
                    ref={this._formRef}
                    onSaveRemote={this.onSaveRemote}
                    enrollData={enrollData} />
            </div>
        </>);
    }
}