import React from "react";
import {
  MButton2,
  MInput,
  MSearchUniversity,
  MSelect,
  ValidatedForm,
} from "components/forms";
import { payloadBuilder } from "libs";

export class DichiarazioneLaureando extends ValidatedForm {
  emptyFields = {
    istitution: "",
    city: "",
    aa: "",
    description: "",
    votoTitolo: "",
    baseVote: "30",
    dataConseguimento: "",
  };

  state = {
    showSearchUniversity: false,
    loading: true,
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields,
    },
  };

  PAYLOADS = {
    qualificationTitle: [
      { label: "Seleziona un Titolo", value: "" },
      ...this.props.payloadTitle,
    ],
  };

  constructor(props) {
    super(props);

    // bind event handler to invoke it from the child component
    this.onChange = this.onChange.bind(this);
  }

  FIELDS_GROUP = [
    [
      {
        id: "searchUniversity011",
        component: MButton2,
        onClick: (e) => {
          this.searchUniversity(e);
        },
        label: "Cerca Ateneo",
        className: "col-md-12 mb-3",
        classElement: "btn btn-primary bg-dark",
      },
    ],
    [
      {
        id: "istitution011",
        field: "istitution",
        label: "Presso Ateneo",
        component: MInput,
        type: "text",
        className: "col-md-6 mt-4",
        readOnly: true,
      },
      {
        id: "city011",
        field: "city",
        label: "Città",
        component: MInput,
        type: "text",
        className: "col-md-6 mt-4",
        readOnly: true,
      },
    ],
    [
      {
        id: "description011",
        field: "description",
        label: "Titolo di studio",
        payload: { options: this.PAYLOADS.qualificationTitle },
        component: MSelect,
        className: "col-md-12",
      },
    ],
    [
      {
        id: "aa011",
        field: "aa",
        label: "Anno Accademico prima immatricolazione",
        component: MInput,
        infoText: "inserire formato AAAA/AAAA",
        className: "col-md-6",
      },
      {
        id: "dataLaureaPrevista011",
        field: "dataConseguimento",
        label: "Data di Laurea prevista",
        component: MInput,
        type: "date",
        className: "col-md-6",
        infoText: "limite data massima prevista il 31/12/2025",
      },
    ],
    [
      {
        id: "votoTitolo011",
        field: "votoTitolo",
        label: "Votazione media ponderata conseguita",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
      {
        id: "baseVote011",
        field: "baseVote",
        label: "Base Voto",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
    ],
  ];

  ERROR_MESSAGES = {
    istitution: "Dato obbligatorio",
    city: "",
    aa: "Dato obbligatorio",
    description: "Dato obbligatorio",
    votoTitolo:
      "Dato non valido. Inserire un numero tra 18 e 30. Valori decimali devono essere inseriti utilizzando un punto come separatore (Esempio: 28.52)",
    dataConseguimento: "Dato obbligatorio. Specificare una data futura",
  };

  validation = {
    istitution: (value) => value !== "",
    city: (value) => value !== "",
    aa: (value) => this.validateAA(value),
    description: (value) => value !== "",
    votoTitolo: (value) =>
      value !== "" && !isNaN(value) && value >= 18 && value <= 30,
    dataConseguimento: (value) => this.validateDataConseguimento(value),
  };

  validateDataConseguimento = (value) => {
    if (value === "") {
      return false;
    }
    let regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!value.match(regex)) {
      return false;
    }

    //--- --- not in the past
    const today = new Date();
    const formDate = new Date(value);

    if (formDate < today) {
      return false;
    }
    
    const maxDate = new Date("2025-12-31");
    if (formDate > maxDate) {
      return false;
    }

    return true;
  };

  validateAA = (value) => {
    //--- reset dataConseguimento ---
    this.setState({ dataConseguimento: "" });

    let aa = /^(\d{4})\/(\d{4})$/.test(value);

    if (!aa) {
      return false;
    } else {
      aa = value.split("/");

      const currentYear = new Date().getFullYear();
      console.log(parseInt(aa[1]), currentYear);
      if (parseInt(aa[1]) > currentYear) {
        return false;
      }

      if (parseInt(aa[1]) === parseInt(aa[0]) + 1) {
        return true;
      } else {
        return false;
      }
    }
  }; //validateAA

  componentDidMount() {
    this.setState({ defaultValues: this.emptyFields, ...this.emptyFields });
  }

  searchUniversity = () => {
    this.setState({ showSearchUniversity: !this.state.showSearchUniversity });
  };

  onChangeUniversity = (university) => {
    this.onChange("city", university.city);
    this.onChange("istitution", university.istitution);
    this.setState({
      showSearchUniversity: false,
      university: university,
    });
  };

  onSubmit = () => {
    const retCode = this.checkValidation();
    return retCode;
  };

  getFormLabel = () => {
    return "Laureando";
  };
  getPayload = () => {
    this.payload = payloadBuilder(this.state);
    this.payload.qualificationCode = "title11";
    this.payload.qualificationType = "title11Laureando";
    this.payload.classeConcorso = this.props.competitionClass;
    this.payload.template = "LAUR_RIS_LAU_MAG";

    this.payload.uniCode = this.state.university.uniCode;
    
    return this.payload;
  };

  //--- ---

  render() {
    const { defaultValues, showSearchUniversity } = this.state;
    const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

    return (
      <>
        <p>
          Iscritto all'ultimo anno della Laurea Magistrale o Magistrale a ciclo
          unico o Diploma Accademico di II livello. L’accesso, per gli iscritti
          ai corsi di studio per il conseguimento della Laurea Magistrale a
          ciclo unico, è subordinato al conseguimento di almeno 180 CFU.{" "}
        </p>
        <p>
          Consapevole che l'accesso alla prova finale del Percorso è consentito
          solo a coloro che hanno già conseguito il titolo di studio per
          l'accesso alla relativa classe di concorso e che integrerò la
          documentazione al momento del conseguimento titolo.
        </p>
        {showSearchUniversity && (
          <>
            <MSearchUniversity
              onChange={this.onChangeUniversity}
              searchUniversity={this.searchUniversity}
            />
          </>
        )}

        {!showSearchUniversity && <form className="user mt-4">{code}</form>}
      </>
    );
  }
}
