import React from "react";
import { BoxDanger, BoxSuccess, MSection } from "components/misc";
import { Row, Col } from "design-react-kit";
import { networkErrorHelper, pageService, payloadBuilder, qualificationService, utilsService } from "libs";

import {
  FormEnabler,
  ValidatedForm,
  MInput,
  MSelect,
  MCheckbox,
  MRadioButton,
} from "components/forms";
import { MSeparator } from "components/forms/shared/MSeparator";

export class Form60Cfu extends ValidatedForm {

  constructor(props) {
    super(props);
    this.state = {
      ...this.emptyFields,
      defaultValues: { ...this.emptyFields },
      formActive: true,
      regions: [],
      cities: [],
      citiesUpdated: 0,
      competitionClass: [],
      isChecked: false,
      isSuccess: 0,
      loading: true,
      competitionClassPayload: [
        { p30: '', p36: '', p60: '' },
        { p30: '', p36: '', p60: '' },
        { p30: '', p36: '', p60: '' },
        { p30: '', p36: '', p60: '' },
        { p30: '', p36: '', p60: '' },
      ]
    };
  }

  PAYLOADS = {
    regions: [],
    competitionClass: [],
    cities: [],
  }

  onChangeRegion = (_, region) => {

    const cities = this.state.cities.options.filter(item => (item.denReg === region));
    this.PAYLOADS.cities.length = 0;
    this.PAYLOADS.cities.unshift({ label: 'Seleziona', value: '' });
    cities.forEach(item => {
      this.PAYLOADS.cities.push({ label: item.denUts, value: item.denUts });
    });

    this.setState({
      citiesUpdated: this.state.citiesUpdated++,
    });
  }

  emptyFields = {
    name: "",
    surname: "",
    email: "",
    emailRepeat: "",
    mobile: "",
    competitionClass1: "",
    competitionClass2: "",
    competitionClass3: "",
    competitionClass4: "",
    competitionClass5: "",
    competitionClassRadio1: "",
    competitionClassRadio2: "",
    competitionClassRadio3: "",
    competitionClassRadio4: "",
    competitionClassRadio5: "",
    competitionClass130: "",
    competitionClass160: "",
    competitionClass136: "",
    competitionClass230: "",
    competitionClass260: "",
    competitionClass236: "",
    competitionClass330: "",
    competitionClass360: "",
    competitionClass336: "",
    competitionClass430: "",
    competitionClass460: "",
    competitionClass436: "",
    competitionClass530: "",
    competitionClass560: "",
    competitionClass536: "",
    region: "",
    city: "",
    accept: false,
    type: "60cfu",
  };

  //-- START FORM OBJ ---
  COMPETITION_CLASS1 = [
    { value: "competitionClass160", label: "Corso abilitante 60 CFU" },
    { value: "competitionClass130", label: "Corso abilitante 30 CFU" },
    { value: "competitionClass136", label: "Corso abilitante 36 CFU" },
  ];

  COMPETITION_CLASS2 = [
    { value: "competitionClass260", label: "Corso abilitante 60 CFU" },
    { value: "competitionClass230", label: "Corso abilitante 30 CFU" },
    { value: "competitionClass236", label: "Corso abilitante 36 CFU" },
  ];

  COMPETITION_CLASS3 = [
    { value: "competitionClass360", label: "Corso abilitante 60 CFU" },
    { value: "competitionClass330", label: "Corso abilitante 30 CFU" },
    { value: "competitionClass336", label: "Corso abilitante 36 CFU" },
  ];

  COMPETITION_CLASS4 = [
    { value: "competitionClass460", label: "Corso abilitante 60 CFU" },
    { value: "competitionClass430", label: "Corso abilitante 30 CFU" },
    { value: "competitionClass436", label: "Corso abilitante 36 CFU" },
  ];

  COMPETITION_CLASS5 = [
    { value: "competitionClass560", label: "Corso abilitante 60 CFU" },
    { value: "competitionClass530", label: "Corso abilitante 30 CFU" },
    { value: "competitionClass536", label: "Corso abilitante 36 CFU" },
  ];

  FIELDS_GROUP = [
    [
      {
        id: "name",
        field: "name",
        label: "Nome",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
      {
        id: "surname",
        field: "surname",
        label: "Cognome",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
    ],
    [
      {
        id: "email",
        field: "email",
        label: "Email",
        component: MInput,
        type: "email",
        className: "col-md-6",
      },
      {
        id: "emailRepeat",
        field: "emailRepeat",
        label: "Ripeti Email",
        component: MInput,
        type: "email",
        className: "col-md-6",
      },
    ],
    [
      {
        id: "mobile",
        field: "mobile",
        label: "Numero di telefono",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
    ],
    [
      {
        id: "competitionClass1",
        name: "competitionClass1",
        field: "competitionClass1",
        label: "Classe di concorso  Insegnamento  ",
        payload: { options: this.PAYLOADS.competitionClass },
        component: MSelect,
        className: "col-md-12",
      }
    ],
    [
      {
        id: "competitionClassRadio1",
        name: "competitionClassRadio1",
        field: "competitionClassRadio1",
        payload: { options: this.COMPETITION_CLASS1 },
        component: MRadioButton,
        type: "radio",
        className: "resourceType",
        label: "",
        onChange: (e, value) => {
          this.manageCompetition(e, value, 0)
        }
      }
    ],
    [{
      id: "hr1",
      className: "col-md-12 form-group",
      component: MSeparator,
    }],
    [
      {
        id: "competitionClass2",
        name: "competitionClass2",
        field: "competitionClass2",
        label: "Classe di concorso  Insegnamento  ",
        payload: { options: this.PAYLOADS.competitionClass },
        component: MSelect,
        className: "col-md-12",
      }
    ], [
      {
        id: "competitionClassRadio2",
        name: "competitionClassRadio2",
        field: "competitionClassRadio2",
        payload: { options: this.COMPETITION_CLASS2 },
        component: MRadioButton,
        type: "radio",
        className: "resourceType",
        label: "",
        onChange: (e, value) => {
          this.manageCompetition(e, value, 1)
        }
      }
    ],
    [{
      id: "hr2",
      className: "col-md-12 form-group",
      component: MSeparator,
    }],
    [
      {
        id: "competitionClass3",
        name: "competitionClass3",
        field: "competitionClass3",
        label: "Classe di concorso  Insegnamento  ",
        payload: { options: this.PAYLOADS.competitionClass },
        component: MSelect,
        className: "col-md-12",
      }
    ], [
      {
        id: "competitionClassRadio3",
        name: "competitionClassRadio3",
        field: "competitionClassRadio3",
        payload: { options: this.COMPETITION_CLASS3 },
        component: MRadioButton,
        type: "radio",
        className: "resourceType",
        label: "",
        onChange: (e, value) => {
          this.manageCompetition(e, value, 2)
        }
      }
    ],
    [{
      id: "hr3",
      className: "col-md-12 form-group",
      component: MSeparator,
    }],
    [
      {
        id: "competitionClass4",
        name: "competitionClass4",
        field: "competitionClass4",
        label: "Classe di concorso  Insegnamento  ",
        payload: { options: this.PAYLOADS.competitionClass },
        component: MSelect,
        className: "col-md-12",
      }
    ], [
      {
        id: "competitionClassRadio4",
        name: "competitionClassRadio4",
        field: "competitionClassRadio4",
        payload: { options: this.COMPETITION_CLASS4 },
        component: MRadioButton,
        type: "radio",
        className: "resourceType",
        label: "",
        onChange: (e, value) => {
          this.manageCompetition(e, value, 3)
        }
      }
    ],
    [{
      id: "hr4",
      className: "col-md-12  form-group",
      component: MSeparator,
    }],
    [
      {
        id: "competitionClass5",
        name: "competitionClass5",
        field: "competitionClass5",
        label: "Classe di concorso  Insegnamento  ",
        payload: { options: this.PAYLOADS.competitionClass },
        component: MSelect,
        className: "col-md-12",
      }
    ], [
      {
        id: "competitionClassRadio5",
        name: "competitionClassRadio5",
        field: "competitionClassRadio5",
        payload: { options: this.COMPETITION_CLASS5 },
        component: MRadioButton,
        type: "radio",
        className: "resourceType",
        label: "",
        onChange: (e, value) => {
          this.manageCompetition(e, value, 4)
        }
      }
    ],
    [{
      id: "hr5",
      className: "col-md-12 form-group",
      component: MSeparator,
    }],
    [
      {
        id: "region",
        field: "region",
        label: "Regione dove desidera svolgere l'esame finale",
        payload: { options: this.PAYLOADS.regions },
        component: MSelect,
        onChange: this.onChangeRegion,
        className: "col-md-6",
      },
      {
        id: "city",
        field: "city",
        label: "Città dove desidera svolgere l'esame finale (scelta modificabile anche successivamente)",
        payload: { options: this.PAYLOADS.cities },
        component: MSelect,
        className: "col-md-6",
      },
    ],
    [
      {
        id: "accept",
        field: "accept",
        label:
          "Dichiaro di aver letto la Privacy Policy ai sensi del Regolamento UE 2016/679 (GDPR)",
        component: MCheckbox,
        className: "col-md-12",
      },
    ],
  ];

  // TODO SAVERIO: è possibile selezionare da 1 a 5 classi di concorso e opzionale 30cfu o 60cfu (almeno una delle due)
  ERROR_MESSAGES = {
    name: "Inserisci il tuo nome",
    surname: "Inserisci il tuo cognome",
    email: "Inserisci la tua email",
    region: "Seleziona la regione",
    city: "Seleziona città",
    accept: "Accetta la privacy policy per continuare",
    mobile: "Inserisci il tuo numero di telefono",
    emailRepeat: "Le due email non coincidono",
    competitionClass1: "Devi selezionare i CFU ",
    competitionClassRadio1: "Devi selezionare una classe di concorso ",
    competitionClass2: "Devi selezionare i CFU",
    competitionClassRadio2: "Devi selezionare una classe di concorso ",
    competitionClass3: "Devi selezionare i CFU",
    competitionClassRadio3: "Devi selezionare una classe di concorso ",
    competitionClass4: "Devi selezionare i CFU",
    competitionClassRadio4: "Devi selezionare una classe di concorso ",
    competitionClass5: "Devi selezionare i CFU",
    competitionClassRadio5: "Devi selezionare una classe di concorso ",
  };

  validation = {
    name: (value) => value !== "",
    surname: (value) => value !== "",
    region: (value) => value !== "",
    city: (value) => value !== "",
    email: (value) => /\S+@\S+\.\S+/.test(value),
    accept: (value) => value !== false,
    mobile: (value) => value !== "",
    emailRepeat: (value, { email }) => email && value === email.value,
    competitionClass1: (value) => this.verifyCompetitionClass(value, 0),
    competitionClassRadio1: (value) => this.verifyCompetitionRadio(value, 0),
    competitionClass2: (value) => this.verifyCompetitionClass(value, 1),
    competitionClassRadio2: (value) => this.verifyCompetitionRadio(value, 1),
    competitionClass3: (value) => this.verifyCompetitionClass(value, 2),
    competitionClassRadio3: (value) => this.verifyCompetitionRadio(value, 2),
    competitionClass4: (value) => this.verifyCompetitionClass(value, 3),
    competitionClassRadio4: (value) => this.verifyCompetitionRadio(value, 3),
    competitionClass5: (value) => this.verifyCompetitionClass(value, 4),
    competitionClassRadio5: (value) => this.verifyCompetitionRadio(value, 4),
  };

  verifyCompetitionClass(value, position) {

    let isValid = true;
    let { competitionClassPayload } = this.state;

    if (value === '') {
      // se la select è vuota ma c'è un figlio selezionato allora non valida
      if (competitionClassPayload[position].p30 ||
        competitionClassPayload[position].p36 ||
        competitionClassPayload[position].p60) {
        isValid = false;
      }
    } else {
      if (!(competitionClassPayload[position].p30 ||
        competitionClassPayload[position].p36 ||
        competitionClassPayload[position].p60)) {
        isValid = false;
      }
    }

    return isValid;
  }

  verifyCompetitionRadio(value, position) {

    let isValid = true;
    const field = "competitionClass" + (position + 1);

    let valueSelected = (this.state[field]) ? (this.state[field].value ? this.state[field].value : '') : '';
    if (value !== '' && valueSelected === '') {
      isValid = false;
    }
    return isValid;
  }

  manageCompetition = (e, value, position) => {

    let { competitionClassPayload } = this.state;
    const field = "competitionClass" + (position + 1);
    const case1 = "competitionClass" + (position + 1) + "60";
    const case2 = "competitionClass" + (position + 1) + "30";
    const case3 = "competitionClass" + (position + 1) + "36";

    let valueSelected = (this.state[field]) ? (this.state[field].value ? this.state[field].value : '*') : '*';

    switch (value) {
      case (case1):
        competitionClassPayload[position].p30 = '';
        competitionClassPayload[position].p36 = '';
        competitionClassPayload[position].p60 = valueSelected;
        break;

      case (case2):
        competitionClassPayload[position].p30 = valueSelected;
        competitionClassPayload[position].p36 = '';
        competitionClassPayload[position].p60 = '';
        break;

      case (case3):
        competitionClassPayload[position].p30 = '';
        competitionClassPayload[position].p36 = valueSelected;
        competitionClassPayload[position].p60 = '';
        break;

      default:
        break;
    }

    this.setState(competitionClassPayload);
  }

  loadRemote(additionalState = {}) {

    if (this.PAYLOADS.competitionClass.length < 1) {
      // load paylods to fill the select options 
      qualificationService.loadAllCompetitionClass()
        .then(({ data }) => {

          const { payload } = data;
          const sites = payload.competitionClasses.map((obj) => ({ label: obj.code + ' - ' + obj.description, value: obj.code }));

          this.PAYLOADS.competitionClass.unshift({ label: 'Seleziona', value: '' });
          this.PAYLOADS.competitionClass.push(...sites);

          this.setState({
            competitionClass: { options: this.PAYLOADS.competitionClass }
          });

        })
        .catch(errors => {
          console.log(errors);
          networkErrorHelper.notify(errors);
        });
    }

    if (this.PAYLOADS.regions.length < 1) {
      // load paylods to fill the select options 
      utilsService.regions()
        .then(({ data }) => {

          const { payload } = data;
          const regions = payload.regions.map((obj) => ({ label: obj.denReg, value: obj.denReg }));

          this.PAYLOADS.regions.unshift({ label: 'Seleziona', value: '' });
          this.PAYLOADS.regions.push(...regions);

          this.setState({
            regions: { options: this.PAYLOADS.regions }
          });

        })
        .catch(errors => {
          console.log(errors);
          networkErrorHelper.notify(errors);
        });
    }

    if (this.PAYLOADS.cities.length < 1) {
      // load paylods to fill the select options 
      utilsService.provinces()
        .then(({ data }) => {

          const { payload } = data;

          this.setState({
            cities: { options: payload }
          });

        })
        .catch(errors => {
          console.log(errors);
          networkErrorHelper.notify(errors);
        });
    }

    this.setState({ loading: false });
  }

  getCompetitionClassPayload(payload) {

    let { competitionClassPayload } = this.state;
    for (let i = 0; i < competitionClassPayload.length; i++) {
      let field = "competitionClass" + (i + 1);
      let valueSelected = payload[field];

      if (valueSelected) {
        if (competitionClassPayload[i].p30 === '*') {
          competitionClassPayload[i].p30 = valueSelected;
        } else if (competitionClassPayload[i].p36 === '*') {
          competitionClassPayload[i].p36 = valueSelected;
        } else if (competitionClassPayload[i].p60 === '*') {
          competitionClassPayload[i].p60 = valueSelected;
        }
      }
    }

    return competitionClassPayload;
  }

  saveRemote = () => {

    let payload = payloadBuilder(this.state);
    payload.competitionClassPayload = this.getCompetitionClassPayload(payload);
    //console.log(payload); return false;
    pageService
      .saveContactRequest60Cfu(payload)
      .then((result) => {
        this.setState({ isSuccess: 200 });
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          this.setState({ isSuccess: 422 });
        }
      });
  };

  onCancel = () => {
    this.resetForm();
  };

  render() {

    let { formActive, defaultValues, loading } = this.state;
    if (loading) return <></>;

    const { code } = this.renderFields(
      this.FIELDS_GROUP,
      defaultValues,
      formActive
    );

    return (
      <>
        
        <MSection>
          <Row>
            <Col>

              {this.state.isSuccess === 0 ? (
                <>
                  <form className="user" id="5cfuform">
                    <h3>Inserisci i tuoi dati per aderire alla newsletter “Nuovi Corsi Abilitanti 60, 30 e 36 CFU</h3>
                    {code}
                  </form>
                  <div>
                    <p className="form-check-label mt-2">
                      Leggi la{" "}
                      <a
                        href="/page/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        privacy policy
                      </a>
                    </p>{" "}
                  </div>
                  <div className="mt-2">
                    <FormEnabler
                      onSubmit={this.onSubmit}
                      onCancel={this.onCancel}
                      onToggle={this.toggleForm}
                      isFormActive={formActive}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
              {this.state.isSuccess === 200 ? (
                <div>
                  <BoxSuccess>
                    La sua adesione alla newsletter sui Corsi Abilitanti è andata a buon fine.<br />
                    Nelle prossime settimane, solo dopo la pubblicazione del decreto definitivo,
                    la contatteremo per fornirle tutte le info e permetterle qualora voglia di procedere con l'iscrizione.

                  </BoxSuccess>{" "}
                </div>
              ) : (
                ""
              )}
              {this.state.isSuccess === 422 ? (
                <div>
                  <BoxDanger>
                    Gentile Insegnante, <br />è già presente un'adesione con
                    questa email utilizzata
                  </BoxDanger>{" "}
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </MSection>
      </>
    );
  }
}