import React from "react";

import {
  MButton2,
  MCheckbox,
  MInput,
  MLabel,
  MSearchUniversity,
  ValidatedForm,
} from "components/forms";
import { payloadBuilder } from "libs";
import { FormTitleStep2Configurator } from "./FormTitleStep2Configurator";

export class FormRef2TitleStep1 extends ValidatedForm {
  constructor(props) {
    super(props);

    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }

  emptyFields = {
    istitution: "",
    city: "",
    aa: "",
    description: "",
    dataConseguimento: "",
    baseVote: 110,
    votoTitolo: "",
    lode: false,
    nation: "",
    contactName: "",
    contactPhone: "",
    contactFax: "",
    contactEmail: "",
    contactPec: "",
  };

  state = {
    showSearchUniversity: false,
    university: {},
    ...this.emptyFields,
    defaultValues: [],
  };

  ERROR_MESSAGES = {
    istitution: "Dato obbligatorio",
    description: "Dato obbligatorio",
    dataConseguimento: "Dato non valido",
    aa: "Inserire l'anno accademico in formato AAAA/AAAA",
    baseVote: "Dato obbligatorio e numerico",
    votoTitolo: "Dato obbligatorio e numerico (min. 60, max. 110)",
    city: "Dato obbligatorio",
  };

  validation = {
    istitution: (value) => value !== "",
    description: (value) => value !== "",
    city: (value) => value !== "",
    dataConseguimento: (value) => this.validateDataConseguimento(value),
    aa: (value) => this.validateAA(value),
    votoTitolo: (value) => this.validateVoteBaseVote(value),
    baseVote: (value) => this.validateVoteBaseVote(value),
    //lode: () => this.onChangeLode(),
  };

  FormRef2TitleStep1Configurator = [
    [
      {
        field: "searchUniversity",
        component: MButton2,
        onClick: (e) => {
          this.searchUniversity(e);
        },
        label: "Cerca Ateneo",
        className: "col-md-12 mb-5 ",
        classElement: "btn btn-primary bg-dark",
      },
    ],
    [
      {
        field: "city",
        label: "Città Università",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
      {
        field: "istitution",
        label: "Università",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
    ],
    [
      {
        id: "description2-a",
        field: "description",
        label: "Denominazione titolo",
        component: MInput,
        type: "text",
        className: "col-md-12",
      }
    ],
    [
      {
        id: "votoTitolo2-a",
        field: "votoTitolo",
        label: "Voto",
        component: MInput,
        type: "text",
        className: "col-md-4",
      },
      {
        id: "baseVote2-a",
        field: "baseVote",
        label: "Base Voto",
        component: MInput,
        type: "text",
        className: "col-md-4",
        readOnly: true,
      },
      {
        id: "lode2-a",
        name: "lode",
        field: "lode",
        label: "Lode",
        component: MCheckbox,
        className: "col-md-4",
        infoText:
          "puoi selezionare la lode quando Voto e Base Voto sono uguali",
        onChange: () => this.onChangeLode(),
      },
    ],
    [
      {
        id: "aa2-a",
        field: "aa",
        label: "Anno Accademico conseguimento Titolo",
        component: MInput,
        infoText: "inserire formato AAAA/AAAA",
        className: "col-md-6",
      },
      {
        id: "dataConseguimento2-a",
        field: "dataConseguimento",
        label: "Data conseguimento titolo",
        component: MInput,
        type: "date",
        className: "col-md-6",
      },
    ],
    [
      {
        id: "preferExamPresence2-a",
        field: "preferExamPresence",
        label: "Informazioni facoltative:",
        infoText:
          "Informazioni per la verifica dei dati ex articolo 15, comma 1, punto e della legge 183/2011. Recapiti della segreteria competente.",
        component: MLabel,
        className: "col-md-12",
      },
    ],
    [
      {
        id: "contactName2-a",
        field: "contactName",
        label: "Denominazione",
        component: MInput,
        type: "text",
        className: "col-md-12",
      },
    ],
    [
      {
        id: "contactPhone2-a",
        field: "contactPhone",
        label: "Telefono",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
      {
        id: "contactFax2",
        field: "contactFax",
        label: "Fax",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
    ],
    [
      {
        id: "contactEmail2-a",
        field: "contactEmail",
        label: "Email",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
      {
        id: "contactPec2-a",
        field: "contactPec",
        label: "Pec",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
    ],
  ];

  validateAA = (value) => {
    //--- reset dataConseguimento ---
    this.setState({ dataConseguimento: "" });

    let aa = /^(\d{4})\/(\d{4})$/.test(value);

    if (!aa) {
      return false;
    } else {
      aa = value.split("/");

      const currentYear = new Date().getFullYear();
      if (parseInt(aa[1]) > currentYear) {
        return false;
      }

      if (parseInt(aa[1]) === parseInt(aa[0]) + 1) {
        return true;
      } else {
        return false;
      }
    }
  }; //validateAA

  validateDataConseguimento = (value) => {
    let regex = /^\d{4}-\d{2}-\d{2}$/;
    if (value === "" || !value.match(regex)) {
      return false;
    }

    //--- ---
    const today = new Date();
    const formDate = new Date(value);

    //in the future not valid
    if (formDate > today) {
      return false;
    }

    //--- ---
    if (Number.isNaN(formDate.getTime())) {
      return false;
    }
    const [year, month, day] = value.split("-").map(Number);

    if (month > 12 || day > 31) {
      return false;
    }

    const currentYear = new Date().getFullYear();
    if (year < currentYear - 90 || year > currentYear) {
      return false;
    }

    //--- guard on compliant AA --
    if (this.state.aa?.value) {
      const aa = this.state.aa.value.split("/");
      const firstDate = new Date(`${parseInt(aa[1])}-03-01`);
      const secondDate = new Date(`${parseInt(aa[1]) + 1}-04-30`);

      if (formDate < firstDate || formDate > secondDate) {
        return false;
      }
    }

    return true;
  };

  //--- ---

  validateVoteBaseVote = (value) => {
    this.onChangeLode();

    if (value === "") {
      return false;
    }

    if (isNaN(value)) {
      return false;
    }

    if (value < 60 || value > 110) {
      return false;
    }

    return true;
  };

  onChangeLode = () => {
    let { votoTitolo, baseVote } = this.state;

    if (typeof baseVote === "object") {
      baseVote = baseVote.value;
    }

    if (
      isNaN(votoTitolo.value) ||
      isNaN(baseVote) ||
      votoTitolo.value === "" ||
      baseVote === "" ||
      parseInt(votoTitolo.value) !== parseInt(baseVote)
    ) {
      this.setState({ lode: { value: false, isValid: true } });
      return false;
    }

    return true;
  };

  componentDidMount() {
    const defaultValues = this.emptyFields;
    this.setState({ defaultValues: defaultValues });
  }

  getPayload = () => {
    const template = "LaureaTriennale";
    this.payload = payloadBuilder(this.state);
    this.payload.qualificationType = this.payload.description;
    this.payload.qualificationCode = "title11";
    this.payload.template = template;
    this.payload.extCode = FormTitleStep2Configurator.getExtCode(template);

    if (!this.payload.nation) {
      this.payload.nation = "Italia";
    }

    this.payload.uniCode = this.state.university.uniCode;

    return this.payload;
  };

  searchUniversity = () => {
    this.setState({ showSearchUniversity: !this.state.showSearchUniversity });
  };

  onChangeUniversity = (university) => {
    this.onChange("city", university.city);
    this.onChange("istitution", university.istitution);
    this.setState({
      showSearchUniversity: false,
      university: university,
    });
  };

  //--- ---

  render() {
    const { defaultValues, showSearchUniversity } = this.state;

    const fields = this.FormRef2TitleStep1Configurator;
    const { code } = this.renderFields(fields, defaultValues, true);

    return (
      <>
        {showSearchUniversity && (
          <>
            <MSearchUniversity
              onChange={this.onChangeUniversity}
              searchUniversity={this.searchUniversity}
              isForeign={false}
            />
          </>
        )}

        {!showSearchUniversity && <form className="user mt-5">{code}</form>}
      </>
    );
  }
}
