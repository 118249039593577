export const COURSE_TYPES = {
  REQUEST: "REQUEST"
}

export const ROUTES = {

  // public
  ROOT: '/',
  LOGIN: '/login',
  LOGIN_OLD: '/login-to-telematic-university',
  PAGE: '/page',
  REGISTER: '/register',
  PASSWORD_RESET: '/password-reset',
  PASSWORD_RESET_TOKEN: '/password-reset-token',
  PASSWORD_RESET_UNTOKEN: '/password-reset-untoken',
  PRIVACY_POLICY: '/page/privacy-policy',
  INFORMATIVA: '/page/informativa-dati-personali',
  PAGE_SUPPORT: '/supporto-tecnico',
  WORK_WITH_US: '/page/lavora-con-noi',

  PRIVACY_POLICY_REV2022: '/page/privacy-policy',
  COOKIE_POLICY_REV2022: '/page/cookie-policy',
  PRINCIPI_ETICI_REV2022: '/page/principi-etici',
  INFORMATIVA_REV2022: '/page/informativa-dati-personali',

  PAGE_WHOARE_OLD: '/page/chi-siamo',
  PAGE_WHOARE_OLD2: '/associazione-mnemosine',
  PAGE_WHOARE: '/chi-siamo',
  PAGE_404: '/404',

  COURSE_BROWSE_OLD: '/corsi-online',
  COURSE_BROWSE: '/corsi-mnemosine',
  COURSE_BROWSE_60CFU: '/percorsi-abilitanti-insegnanti-60-30-36-cfu',
  COURSE_BROWSE_3036CFU: '/percorsi-abilitanti-insegnanti-vincitori-concorso-30-36-cfu',

  COURSE_BROWSE_ABILITAZIONI_60CFU: '/adesione-percorsi-abilitanti-insegnanti-60-30-36-cfu',
  COURSE_SSD_BROWSE_OLD: '/trova-master-ssd',
  COURSE_SSD_BROWSE: '/corsi-mnemosine/master-universitari',
  COURSE_MUPA_BROWSE: '/corsi-mnemosine/mupa',
  COURSE_LANDINGPAGE: '/corso-online',
  COURSE_LANDINGPAGE_FULL: '/corso-online-mnemosine',
  COURSE_LANDINGPAGE_HS: '/corso-online-hs',
  COURSE_FULLLAYOUTPAGE: '/corso-online-formazione',
  COURSE_LANDINGPAGE_IFRAME: '/iscrizione-iframe',

  COURSE_SURVEY_MAIN: '/indagine-conoscitiva',

  // blog
  BLOG_HOME: '/mnemosine-news',
  BLOG_ARTICLE: '/mnemosine',

  // public promo
  PROMO_AMICI: '/promo-amici',
  PROMO_FAMIGLIA: '/promo-famiglia',
  PROMO_ISTITUTO: '/promo-istituto',
  PROMO_CORSO_GRATUITO: '/progetto-corso-gratuito',
  PROMO_TABLET: '/tablet-omaggio',
  PROMO_50: '/promo-50',

  //public Regolamento
  PAGE_RULE: '/regolamento',

  //public impegno e solidarietà
  PAGE_SOLIDARITY: '/solidarieta',
  PAGE_SOLIDARITY_2: '/solidarieta-mnemosine-per-il-sociale',
  PAGE_LEONI_SICANI: '/associazione-a-s-d-leoni-sicani-onlus',
  PAGE_UNICEF: '/unicef-istruzione-per-i-bambini-colpiti-dalla-crisi-in-siria',

  //public 24cfu
  PAGE_CONCESSIONS: '/contratto-collettivo-nazionale-integrativo',
  PAGE_BENEFITSC24: '/vantaggi-corsi-24cfu',
  PAGE_BENEFITSM24: '/vantaggi-master-24cfu',
  PAGE_REFERENCELEGISLATION: '/24-cfu-accesso',
  //PAGE_REQUESTCERTIFICATION: '/riconoscimenti-cfu',

  //public clil 
  PAGE_BENEFITSCLIL: '/clil-agevolazioni',

  //public ATA 
  PAGE_ACCESSTITLEATA: '/titoli-accesso-ai-profili-ata',

  //public perfezionamento PLU - PDU
  PAGE_PERFEZIONAMENTOPDUPLU: '/post-laurea-post-diploma',

  //public  CSU - RCSU
  PAGE_CORSISINGOLICSURCSU: '/corsi-singoli-universitari',

  //public perfezionamento MUPA - MSLU
  PAGE_MASTERMUPAMSLU: '/master-pubblica-amministrazione',

  //public English
  PAGE_ENGLISHCERTIFICATION: '/certificazione-lingua-inglese-b2-c1',

  //public Bonus carta docente
  PAGE_TEACHERCARD: '/come-generare-il-bonus-scuola-per-i-corsi-mnemosine',

  //public Dottorato
  PAGE_DOCTORATE: '/dottorati-di-ricerca',

  //public Gsuite details
  PAGE_GSUITEDETAILS: '/dettaglio-corso-google',

  //public 24cfu details
  PAGE_24CFUDETAILS: '/U24CFU',

  //public 5cfu insight
  PAGE_5CFUINSIGHT: '/approfondimenti-5cfu',
  PAGE_DECRETOMINISTERIALE108: '/decreto-ministeriale-n-108',

  //public page
  PAGE_NEWSCANCEL: '/newscancel',

  // my
  DASHBOARD: '/dashboard',
  MY_PROFILE: '/my/profile',
  MY_ENROLLMENT: '/my/enrollment',
  MY_ENROLLMENT_PENDING: '/my/enrollment-pending',
  MY_ENROLLMENT_PREREQUISITES: '/my/enrollment-prerequisites',
  PAYMENT_RESULT_OK: '/bankpayment-result/ok',
  PAYMENT_RESULT_KO: '/bankpayment-result/ko',
  PAYMENT_RESULT_NOTIFY: '/bankpayment-notify',
  PAYMENT_GOMP_RESULT_NOTIFY: '/pagopa-notify',
  MY_QUALIFICATION: '/my/qualifications',
  MY_DOCUMENT: '/my/document',
  MY_INVOICEDATA: '/my/invoice-data',
  MY_QUIZREVIEW: '/my/quizreview',

  // course
  COURSE_DASHBOARD: '/course/dashboard',
  COURSE_LIST: '/course/list',
  COURSE_TICKETS: '/course/tickets',
  COURSE_PAYMENTS: '/course/payments',
  COURSE_INVOICES: '/course/invoices',
  COURSE_TICKET_DETAIL: '/course/ticket',
  COURSE_PAYMENT_CD: '/course/payment/carta_del_docente',
  COURSE_TITLE_SELF_DECLARATION: '/course/title-self-declaratio',
  COURSE_BOOKING_CLASS: '/course/booking-class',

  COURSE_LESSONLIVE_SCHEDULE_ONLINE: '/course/live-lesson/schedule',
  COURSE_LESSONLIVE_SCHEDULE_OFFLINE: '/course/live-lesson/schedule-offline',
  COURSE_LESSONLIVE_DETAIL: '/course/live-lesson/detail',
  COURSE_LESSONLIVE_CONFIRM_REGISTRATION: '/course/live-lesson/confirm-registration',
  COURSE_SHOW_FILE: '/course/show-file',

  COURSE_RC30_EXAM_RESERVATION: '/course/rc30-exam-reservation',
  COURSE_RC30_EXAM_SUBJECT: '/course/rc30-exam-subject',
  COURSE_RC30_CERT_REQUEST: '/course/rc30-cert-request',
  COURSE_RC30_CONFIRM_EXAMSITE: '/course/rc30-confirm-examsite',
  COURSE_RC30_UDA_EXAMWRITTEN: '/course/rc30-examwritten',

  COURSE_RC60CFUALL1_UDA_EXAMWRITTEN: '/course/rc60cfuall1-examwritten',
  COURSE_RC60CFUALL1_EXAM_SUBJECT: '/course/rc60cfuall1-exam-subject',
  COURSE_RC60CFUALL1_CERT_REQUEST: '/course/rc30-cert-request',

  COURSE_RC30CFUALL2_UDA_EXAMWRITTEN: '/course/rc30cfuall2-examwritten',
  COURSE_RC30CFUALL2_EXAM_SUBJECT: '/course/rc30cfuall2-exam-subject',
  COURSE_RC30CFUALL2_CERT_REQUEST: '/course/rc30-cert-request',

  COURSE_EXAM_WRITTEN_RESERVATION: '/course/exam-written-reservation',
  COURSE_EXAMFINAL_ONSITE_RESERVATION: '/course/exam-written-onsite-reservation',
  COURSE_EXAM_LIVE_RESERVATION: '/course/exam-live-reservation',
  COURSE_EXAM_DONE: '/course/exam-done',
  COURSE_DOCS: '/course/docs',
  COURSE_ISTRUCTION: '/course/istruction',
  COURSE_TUTOR: '/course/tutor',
  COURSE_PROVIDER: '/course/provider',
  COURSE_TITLE_REQUEST: '/course/title-request',
  PERFEZIONAMENTO_EXAM_WRITTEN: '/course/perfezionamento-exam-written',
  PERFEZIONAMENTO_EXAM_LIVE: '/course/perfezionamento-exam-live',

  // learning
  LEARNING_MAIN: '/learning/courseMain',
  LEARNING_LESSON: '/learning/lesson',
  LEARNING_FINAL_PAPER: '/learning/final-paper',
  LEARNING_QUIZ_MAIN: '/learning/quizMain',
  LEARNING_QUIZ_FEEDBACK: '/learning/quiz-feedback-final',

  // MU
  MU_COURSE_PAYMENTS: '/course/mu-payments',
  MU_COURSE_DOCS: '/course/mu-docs',
  MU_SELF_DECLARATION: '/course/mu-self-declaration',
  MU_COURSE_TITLE_REQUEST: '/course/mu-title-request',
  MU_C24_CERT_REQUEST: '/course/mu-c24-cert-request',
  MU_UM_TITLE_DONWLOAD: '/course/mu-mu-title-download',

  MU_PAYMENT_REQUEST: '/course/mu-paymentother-request',

  // M24
  M24_LESSON: '/learning/m24-lesson',

  // UCSU
  UCSU_LESSON: '/learning/ucsu-lesson',
  USCU_CERT_REQUEST: '/course/uscu-cert-request',

  // RCSU
  RCSU_LESSON: '/learning/rcsu-lesson',

  // MNE
  MNE_SIMULATION_EXAM: '/learning/mne-simulation-exam',

  // C24     
  C24_EXAMWRITTEN_RESERVATION: '/course/c24-examwritten-reservartion',
  C24_EXAMWRITTEN_ONSITE_RESERVATION: '/course/c24-examwritten-onsite-reservartion',
  C24_EXAMLIVE_RESERVATION: '/course/c24-examlive-reservartion',
  C24_EXAMWRITTEN: '/course/c24-exam-written',
  C24_CERT_REQUEST: '/course/c24-cert-request',

  C24_EXAMLIVE: '/course/c24-examlive',

  COURSE_CFU_SELF_DECLARATION: '/course/cfu-self-declaration',

  //GOOGLE_MEET: '/meet-google',

  // ICDL
  ICDL_LESSON: '/learning/icdl-lesson',
  ICDL_CERTIFICATION: '/course/icdl-certification',
  ICDL_EXAM_RESERVATION: '/course/icdl-exam-reservartion',

  COURSE_MANIFESTO: '/course/manifesto',

  // CV
  CV_DOCS: '/course/cv-docs',

  // ESB
  ESB_LESSON: '/learning/esb-lesson',
  ESB_EXAM_RESERVATION: '/course/esb-exam-reservartion',

  //MNE
  COURSE_TUTOR_MNE: '/course/tutor-mne',
  ASSISTANCE_TELEPHONE_MNE: '/course/assistance-telephone-mne',
  ASSISTANCE_ONLINE_MNE: '/course/assistance-online-mne',

  // tools 
  MOBILE_CONFIRM: '/tools/mobile-confirm',

  // services
  QUALIFICATION_EVALUATION: '/valutazione-titoli',
  QUALIFICATION_MAP: '/corrispondenza-titoli',
  TITLE_DELIVERY: '/prenotazione-ritiro-titoli',
  TITLE_DELIVERY_ESB: '/prenotazione-ritiro-titoli-inglese',

  // agenda
  AGENDA: '/agenda',

  // career
  CAREER: '/career',
  CONFIRM: '/confirm/:key',

  //steward
  STEWARD_VERIFY_IDENTITY: '/steward-verify-identity',

  // admin
  COURSE_MANAGER: '/course-manager',
  COURSE_TYPE_MANAGER: '/course-type-manager',
  COURSE_FORM: '/course-form',
  COURSE_TYPE_FORM: '/course-type-form',
  LESSON_FORM: '/lesson-form',
  QUIZ_FORM: '/quiz-form',
  EXAMPLES: '/examples',
  USER_LIST: '/users',
  COURSE_REPOQUIZ_MANAGER: '/repoquiz',
  REPOQUIZ_FORM: '/repoquiz-form',
  PROMOTIONAL_MANAGER: '/promotional-manager',
  PROMOTIONAL_FORM: '/promotional-form',
  USER_SYNC_IN: '/user/syncin',
  SITECONFIG_MANAGER: '/siteconfig-manager',
  SITECONFIG_FORM: '/siteconfig-form',
  LESSON_LIVE_SCHEDULE: '/lessonlive-schedule',
  LESSON_LIVE_SCHEDULE_FORM: '/lessonlive-schedule-form',
  LESSON_LIVE_SCHEDULE_ANALYTICS: '/lessonlive-analytics',
  LESSON_LIVE_PROCESS_ANALYTICS: '/lessonlive-analytics-process',
  LESSON_LIVE_CLASS: '/lessonlive-class',
  LESSON_LIVE_CLASS_FORM: '/lessonlive-class-form',
  LESSON_LIVE_EDITION: '/lessonlive-edition',
  LESSON_LIVE_EDITION_FORM: '/lessonlive-edition-form',
  LESSON_LIVE_CALENDAR_WEBEX: '/lessonlive-calendar-webex',
  LESSON_LIVE_CLASS_UPLOAD_CALENDAR: '/lessonlive-class-upload-calendar',

  PAGE_CONTACT: '/contatti',
  PAGE_CONTACT_REQUEST: '/richiesta-contatto',
  PAGE_CONTACT_INFO: '/raggiungere-sede',

  BLOG_CATEGORY_MANAGER: '/cms/blog-category',
  BLOG_CATEGORY_FORM: '/cms/blog-category-form',
  BLOG_ARTICLE_MANAGER: '/cms/blog-article',
  BLOG_ARTICLE_FORM: '/cms/blog-article-form',

  LIBRARY_CATEGORY_MANAGER: '/cms/library-category',
  LIBRARY_CATEGORY_FORM: '/cms/library-category-form',
  LIBRARY_BOOK_MANAGER: '/cms/library-book',
  LIBRARY_BOOK_FORM: '/cms/library-book-form',
  LIBRARY_AUTHOR_MANAGER: '/cms/library-author',
  LIBRARY_AUTHOR_FORM: '/cms/library-author-form',

  PAGE_MANAGER: '/cms/page-list',
  PAGE_FORM: '/cms/page-form',
  PAGE_BIBLIOTECA: "/biblioteca",
  PAGE_CORSIDILAUREA: "/corsi-di-laurea",
  PAGE_SEDI_ESAMI: "/sedi-esami",

  SURVEY_MANAGER: '/cms/survey-list',
  SURVEY_FORM: '/cms/survey-form',

  QUALIFICATION_DATA_ANS_MANAGER: '/cms/ans-manager',

  ASSETS_AUDIO_CATEGORY_MANAGER: '/cms/assets-audio-category',
  ASSETS_AUDIO_CATEGORY_FORM: '/cms/assets-audio-category-form',
  ASSETS_AUDIO_FILE_MANAGER: '/cms/assets-audio-file',
  ASSETS_AUDIO_FILE_FORM: '/cms/assets-audio-file-form',

  CURRENT_REVISION: "/current-revision",
  HISTORY_REVISION: "/history-revision",
  PENDING_REVISION: "/pending-revision",
  PENDING_REVISION_ADMIN_30CFU: "/pending-revision-30cfu",
  VIEWER_UDA: "/viewer-uda",

  QUIZREVIEW_FORM: '/quizreview-form',
  EXAMREVIEW_FORM: '/examreview-form',

  CONTACT_REQUEST: "/contact-request",

  ACTIONS_OVER_DB: '/cms/dbactions',

  //--- ---

  FORM_5CFU: "/adesioni-5cfu",
  FORM_30CFU_UNICAMILLUS: "/30cfu-unicamillus",
  FORM_60CFU: "/60cfu-30cfu-abilitazione-insegnamento",
  ENROLL_30CFU: "/30cfu",
  ENROLL_TFA_IX_CICLO: "/tfa",
  ENROLL_TFA_IX_CICLO_TEMP: "/tfa-iscrizioni-temporanea",
  FORM_60CFU_EMBEDDED: "/60cfu-30cfu-embedded",

  ANALYSIS_60CFU: '/analysis/60cfu',
  ANALYSIS_30CFU: '/analysis/30cfu',

  //----
  USER_SELF_DECLARATION: '/course/user-self-declaration',


  CFUALL_EXAM_RESERVATION: '/course/cfuall-exam-reservartion',

};

export const STRINGS = {
  TITLE: "Corsi Mnemosine",
  COMPANY: "Associazione Mnemosine",
};

export const CACHE_KEYS = {
  SESSION: "session",
};

export const USER_QUALIFICATIONS = {
  DIPLOMA: "diploma",
  LAUREA: "laurea",
  IMMATRICOLAZIONE: "immatricolazione",
};

export const QUIZ_QUESTION_RENDER_MODE = [
  { value: "classic", label: "Classico" },
  { value: "fillFree", label: "a Completamento libero (auto correzione)" },
  { value: "fillFreeMulti", label: "a Completamento libero Multiplo (auto correzione)" },
  { value: "fillFromSelect", label: "a Completamento da Select (solo 1 è vera)" }
];

export const ADDRESS_TYPES = {
  BORN: "bornAddressId",
  DELIVERY: "deliveryAddressId",
  RESIDENCE: "addressId",
};

export const ADDRESS_TYPES_ACCESSORS = {
  [ADDRESS_TYPES.RESIDENCE]: "residenceAddress",
  [ADDRESS_TYPES.BORN]: "bornAddress",
  [ADDRESS_TYPES.DELIVERY]: "deliveryAddress",
};

export const GENDERS = [
  { value: null, label: "-" },
  { value: "m", label: "M" },
  { value: "f", label: "F" },
];

export const DOC_TYPES = [
  { value: null, label: "Tipo di documento" },
  { value: "identityCard", label: "Carta d'identità" },
  { value: "drivingLicence", label: "Patente di guida" },
  { value: "passport", label: "Passaporto" },
];

export const DOC_TYPES_MAP = {
  identityCard: "Carta d'identità",
  drivingLicence: "Patente di guida",
  passport: "Passaporto",
};

export const VIEWS = {
  LEARNING: "Learning",
  MY: "My",
  COURSE: "Course",
  CAREER: "Career",
  AGENDA: "Agenda",
  PAGE: "Page",
};

export const STATUS_CODES = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTH: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE: 422,
  INTERNAL_ERROR: 500,
};

export const ENROLLMENT_STEPS = {
  START: "start",
  FILL: "fill",
  FILL2: "fill2",
  FILL3: "fill3",
  SIGN: "sign",
  PAY: "pay",
  END: "end",
  VIRTUALSTAMP: "virtualstamp"
};

export const TITLEREQUEST_STEPS = {
  START: "start",
  SIGN: "sign",
  PAY: "pay",
  DOWNLOAD: "download",
};

export const COMPANY = {
  SRL: "srl",
  ASSOCIAZIONE: "associazione",
  UNICAMILLUS: "unicamillus",
  REGGIO: "reggio",
  TCHAIKOVSKY: 'tchaikovsky',
  IUL: 'iul'
};

export const PAYMENT_TYPES = {
  BONIFICO: "Bonifico",
  CARTA_DI_CREDITO: "Carta di credito",
  CARTA_DEL_DOCENTE: "Carta del docente",
  PAGOPA: "PagoPA",
  MAV: "MAV",
};

export const PAYMENT_STATUS = {
  STARTED: "started",
  APPROVED: "approved",
  SELFAPPROVED: "selfApproved",
  TOAPPROVE: "toApprove",
  ANNULLED: "annulled",
  REJECTED: "rejected",
};

export const PAYMENT_STATUS_LABEL = {
  started: "da completare",
  approved: "approvato",
  selfApproved: "approvato",
  toApprove: "da approvare",
  annulled: "annullato",
  rejected: "rifiutato",
};

export const WORKINGSTATUS = [
  { value: null, label: "-" },
  { value: "precario", label: "Precario" },
  { value: "non docente", label: "non Docente" },
  { value: "tempo indeterminato", label: "tempo Indeterminato" },
];

export const RESULTQUIZ = [
  { value: "", label: "selezionare" },
  { value: "superato", label: "superato" },
  { value: "non superato", label: "non superato" },
];

export const EXAMVOTE_30CFU = [
  { value: "", label: "selezionare" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "non idoneo", label: "non idoneo" }
];

export const TAG_AREA = {
  AR: "Artistica",
  SG: "Storico Geografica",
  SC: "Scientifica",
  TE: "Tecnico Pratica",
  GE: "Giuridico Economica",
  EC: "Economica",
  EM: "Educazione Motoria",
  UM: "Umanistica",
  PS: "Psicomotoria",
  LI: "Linguistica",
  AM: "Artistico Musicale",
  TR: "Trasversale",
  GI: "Giuridica",
};

export const RCALL_EDITION = {
  first_rc30: 51,
  first_rc60: 50,
};

export const RC30_EDITION = {
  first: 35,
  second: 38,
  third: 42,
  fourth: 48,
  first_2024: 22,
  second_2024: 31
};

export const UM30_EDITION = {
  second: 49,
  first_2024: 20,
  second_2024: 29,
  second_2_2024: 44
};

export const CM30_EDITION = {
  second: 54,
  first_2024: 21,
  second_2024: 30
};

export const COMPETITION_CLASS_TITLE = {
  A001: 'Arte e immagine nella scuola secondaria di I grado',
  A011: 'Discipline Letterarie e Latino',
  A012: 'Discipline Letterarie negli Istituti di Istruzione Secondaria di II Grado',
  A015: 'Discipline sanitarie',
  A017: 'Disegno e storia dell’arte negli istituti di istruzione secondaria di II grado',
  A018: 'Filosofia e Scienze umane',
  A019: 'Filosofia e Storia ',
  A020: 'Fisica',
  A021: 'Geografia',
  A022: 'Italiano, Storia, Geografia nella Scuola Secondaria di I Grado',
  A026: 'Matematica',
  A027: 'Matematica e Fisica',
  A028: 'Matematica e Scienze',
  A030: 'Musica nella scuola secondaria di I grado',
  A031: 'Scienze degli alimenti',
  A045: 'Scienze Economico-Aziendali',
  A046: 'Scienze Giuridico-Economiche',
  A047: 'Scienze matematiche applicate',
  A048: 'Scienze Motorie e Sportive negli Istituti di Istruzione Secondaria di II Grado',
  A049: 'Scienze Motorie e Sportive nella Scuola Secondaria di I Grado',
  A050: 'Scienze naturali, chimiche e biologiche',
  A060: 'Tecnologia scuola secondaria di primo grado',
  AB24: 'Lingue e Culture Straniere negli Istituti di Istruzione di II Grado (Inglese)',
  AB25: 'Lingua Inglese e Seconda Lingua Comunitaria nella Scuola Secondaria I Grado (Inglese)',
  A054: 'Storia dell\'Arte',
  AB56: 'Strumento musicale nella scuola secondaria di I grado(Chitarra)',
  AN55: 'Violoncello',
  AA24: 'Lingue e Culture Straniere negli Istituti di Istruzione di II Grado (Francese)',
  B012: 'Laboratori di scienze e tecnologie chimiche e microbiologiche',
  AA25: 'Seconda Lingua Comunitaria nella Scuola Secondaria I Grado (Francese)'
};

export const SITE_CONFIG = {
  EXAM_WRITTEN_HOUR: "EXAM_WRITTEN_HOUR",
};

export const COURSE_STATUS = {
  ACTIVE: "active",
  COMPLETED: 'completed',
  RETIRED: 'retired',
  DELETED: 'deleted'
};

export const USER_ROLES = {
  STUDENT: 'student',
  ADMIN: 'admin',
  EDITOR: 'editor',
  REVISOR: 'revisor',
  MANAGER: 'manager',
  SEGRETERIA: 'segreteria'
};

export const PRESET_METADATA_QUIZ = [
  {
    label: "Test Intermedio",
    preset: `{
            "repeat": true, 
            "attempts": 0, 
            "mandatory": true,
            "storable": false,
            "dnd": false,
            "toMergeQuestions": false,
            "constraints": {
                "duration": 0,
                "isPaymentOK": false
            },
            "feedbackFinal": {
              "brief": false, 
              "detail": true,
              "onlyAccepted": false,
              "hideOutComeAnswer": false,
              "isWordCount": false,
              "autoCorrection": false,
              "reloadLastAttempt": false,
              "showAnsOnAttempt": 0
            }, 
            "feedbackAnswer": true
        }`,
  },
  {
    label: "Test Intermedio da Deposito Domande",
    preset: `{
            "repeat": true, 
            "attempts": 0, 
            "mandatory": true,
            "storable": false,
            "dnd": false,
            "toMergeQuestions": false,
            "repository": [
                {
                    "name": "[inserisci_il_nome_della_repo]",
                    "questions": '[insersci_quante_domande_(int)]'
                }
            ],
            "constraints": {
                "duration": 0,
                "isPaymentOK": false
            },
            "feedbackFinal": {
                "brief": false, 
                "detail": true,
                "onlyAccepted": false,
                "hideOutComeAnswer": false,
                "isWordCount": false,
                "autoCorrection": false,
                "reloadLastAttempt": false,
                "showAnsOnAttempt": 0
            }, 
            "feedbackAnswer": true
        }`,
  },
  {
    label: "Prova Finale",
    preset: `{
            "repeat": false, 
            "attempts": 0, 
            "mandatory": false,
            "storable": true,
            "dnd": false,
            "toMergeQuestions": false,
            "constraints": {
                "duration": 0,
                "isPaymentOK": false
            },
            "feedbackFinal": {
                "brief": true, 
                "detail": false,
                "onlyAccepted": false,
                "hideOutComeAnswer": false,
                "isWordCount": false,
                "autoCorrection": false,
                "reloadLastAttempt": false,
                "showAnsOnAttempt": 0
            }, 
            "feedbackAnswer": false
        }`,
  },
  {
    label: "Prove di Profitto da Deposito Domande",
    preset: `{
            "repeat": false,
            "attempts": 1,
            "duration": 20,
            "mandatory": false,
            "storable": true,
            "dnd": false,
            "toMergeQuestions": false,
            "loseFocus": true,
            "code": "[inserisci_il_codice_insegnamento]",
            "repository": [
                {
                    "name": "[inserisci_il_nome_della_repo]",
                    "questions": '[insersci_quante_domande_(int)]'
                },
                {
                    "name": "[inserisci_il_nome_della_repo]",
                    "questions": '[insersci_quante_domande_(int)]'
                }
            ],
            "constraints": {
                "duration": 0,
                "isPaymentOK": true
            },
            "feedbackFinal": {
                "brief": false,
                "detail": false,
                "onlyAccepted": true,
                "hideOutComeAnswer": false,
                "isWordCount": false,
                "autoCorrection": false,
                "reloadLastAttempt": false,
                "showAnsOnAttempt": 0
            },
            "feedbackAnswer": false
        }`,
  },
  {
    label: "Test Inglese",
    preset: `{
            "repeat": true, 
            "attempts": 0, 
            "mandatory": true,
            "storable": true,
            "dnd": false,
            "toMergeQuestions": false,
            "constraints": {
                "duration": -1,
                "isPaymentOK": false
            },
            "lang": "en",
            "hideLabel": true,
            "feedbackFinal": {
                "brief": false, 
                "detail": true,
                "onlyAccepted": false,
                "hideOutComeAnswer": true,
                "isWordCount": true,
                "autoCorrection": true,
                "reloadLastAttempt": true,
                "showAnsOnAttempt": 2
            }, 
            "feedbackAnswer": true
        }`,
  },
  {
    label: "Test Inglese (DnD)",
    preset: `{
            "repeat": true, 
            "attempts": 0, 
            "mandatory": true,
            "storable": true,
            "dnd": true,
            "toMergeQuestions": false,
            "constraints": {
                "duration": -1,
                "isPaymentOK": false
            },
            "lang": "en",
            "hideLabel": true,
            "feedbackFinal": {
                "brief": false, 
                "detail": true,
                "onlyAccepted": false,
                "hideOutComeAnswer": true,
                "isWordCount": true,
                "autoCorrection": true,
                "reloadLastAttempt": true,
                "showAnsOnAttempt": 2
            }, 
            "feedbackAnswer": true
        }`,
  }
];
