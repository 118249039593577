import React from "react";
import {
  MInput,
  ValidatedForm,
} from "components/forms";
import { payloadBuilder } from "libs";
import { FormTitleStep2Configurator } from "./FormTitleStep2Configurator";

export class ContrattoDocenza extends ValidatedForm {
  
  FIELDS_GROUP = [
    [
      {
        id: "istitution_CD" + this.props.idx,
        field: "istitution",
        label: "Ente sede del corso",
        component: MInput,
        className: "col-md-12",
      },
      {
        id: "percorso_CD" + this.props.idx,
        field: "description",
        label: "Denominazione percorso regionale",
        component: MInput,
        className: "col-md-6",
      },
      {
        id: "contactEmail_CD" + this.props.idx,
        field: "contactEmail",
        label: "E-mail dell’istituto",
        component: MInput,
        className: "col-md-6",
      }
    ],
    [
      {
        field: "contactFax",
        label: "Fax",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
      {
        field: "contactPec",
        label: "Pec",
        component: MInput,
        type: "text",
        className: "col-md-6",
      }
    ],
    [
      {
        id: "city_CD" + this.props.idx,
        field: "city",
        label: "Comune",
        component: MInput,
        className: "col-md-10",
      },
      {
        id: "province_CD" + this.props.idx,
        field: "province",
        label: "Provincia (sigla)",
        component: MInput,
        className: "col-md-2",
      },
      {
        id: "address_CD" + this.props.idx,
        field: "address",
        label: "Via/Piazza",
        component: MInput,
        className: "col-md-12",
      },
    ],
    [
      {
        id: "dateFrom_CD" + this.props.idx,
        field: "dateFrom",
        label: "dal",
        component: MInput,
        type: "date",
        className: "col-md-4",
      },
      {
        id: "dateTo_CD" + this.props.idx,
        field: "dateTo",
        label: "al",
        component: MInput,
        type: "date",
        className: "col-md-4",
      },
    ],
  ];

  ERROR_MESSAGES = {
    istitution: "dato obbligatorio",
    city: "dato obbligatorio",
    contactEmail: "Inserire l'email dell'istituto",
    province: "Inserire la provincia",
    address: "Inserire l'indirizzo",
    dateFrom: "Inserire la data di inizio",
    dateTo: "Inserire la data di fine",
    description: "Inserire il percorso regionale",
  };

  validation = {
    istitution: (value) => value !== "",
    city: (value) => value !== "",
    contactEmail: (value) => value !== "",
    province: (value) => value !== "" && value.length < 4,
    address: (value) => value !== "",
    dateFrom: (value) => this.validateData(value),
    dateTo: (value) => this.validateData(value),
    description: (value) => value !== "",
  };

  validateData = (value) => {
    if (value === "") {
      return false;
    }
    let regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!value.match(regex)) {
      return false;
    }

    //--- ---
    const today = new Date();
    const date = new Date(value);

    if (date > today) {
      return false;
    }

    if (Number.isNaN(date.getTime())) {
      return false;
    }
    const [year, month, day] = value.split("-").map(Number);

    if (month > 12) {
      return false;
    }
    if (day > 31) {
      return false;
    }

    const currentYear = new Date().getFullYear();
    if (year < currentYear - 90 || year > currentYear) {
      return false;
    }
    return true;
  };

  emptyFields = {
    istitution: "",
    city: "",
    contactEmail: "",
    contactFax: "",
    contactPec: "",
    province: "",
    address: "",
    dateFrom: "",
    dateTo: "",
    description: "",
  };

  state = {
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields,
    },
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  getPayload = () => {
    this.payload = payloadBuilder(this.state);
    this.payload.qualificationType = "title14";
    this.payload.qualificationCode = "title14_RISERVISTI_Item"; 
    this.payload.template = "DichiarazioneRiservistiItem";
    this.payload.extCode=FormTitleStep2Configurator.getExtCode("DichiarazioneRiservisti");

    return this.payload;
  };

  render() {
    const { defaultValues } = this.state;
    const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

    return <>{code}</>;
  }
}
