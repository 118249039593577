import React, { Component } from "react";
import { courseService, networkErrorHelper } from "libs";
import { ROUTES, TAG_AREA } from "const";
import { dateHelper } from "libs/helpers";
import ReactHtmlParser from 'react-html-parser';


import {
    BoxSuccess, MnemoLoading
} from "components/misc";

import {
    Container, Icon, Chip, ChipLabel,
    Row, Button, Col, Card, CardBody, CardFooter
} from 'design-react-kit';
import { Helmet } from "react-helmet";

export class CourseBrowserPLUA extends Component {

    state = {

        loading: true,
        courses: [],
    };

    componentDidMount() {

        this.loadCourses();
    }

    loadCourses() {

        courseService
            .listPLUA()
            .then(({ data }) => {

                const { courses } = data.payload;

                if (courses) {

                    this.setState({ courses, loading: false });
                }
            })
            .catch(error => {
                console.log(error);
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false });
            });
    }

    openCourse(code) {

        window.open(`${ROUTES.COURSE_LANDINGPAGE}\\${code}`, '_blank');
    }

    getReqTitle() {

        let reqTitle = '';
        if (this.state.courses) {
            const course = this.state.courses[0];
            if (course) {
                if (course.laurea) {
                    reqTitle = 'Laurea';
                } else if (course.diploma) {
                    reqTitle = 'Diploma';
                } else {
                    reqTitle = 'Nessuno';
                }
            }
        }

        return reqTitle;
    }

    renderHeader() {

        const { courses } = this.state;

        return (
            <>
                <div className="image-banner">
                    <img className="w-100" src={`/img/courses/banner_plu.webp`} alt="" />
                </div>
                <h3>Diplomi di Perfezionamento Annuali Post Laurea Specifici</h3>
                {(courses.length > 0) &&
                    <>
                        <div className="m-3 mt-5 m-lg-5 m-sm-3">
                            <p>{ReactHtmlParser(courses[0].summary)}</p>
                        </div>
                    </>}
            </>);
    }

    renderCourseList() {

        const { courses } = this.state;

        return (<>
            {(courses.length > 0)
                ?
                (<>
                    <Row>
                        {courses.map((course, i) => (
                            <Col md={6} lg={4} key={`course-${i}`} >
                                <a className="no-pointer" href={`${ROUTES.COURSE_LANDINGPAGE}\\${course.code}`} rel="noopener noreferrer" target="_blank">
                                    <article
                                        className='card-wrapper card-space pointer'  >
                                        <Card noWrapper className="card-bg card-img shadow">
                                            <CardBody>
                                                <div className="box-300">
                                                    <p className="width-full text-center text-green m-0"><b>{course.label}</b></p>
                                                    <p className="text-green">
                                                        {ReactHtmlParser(course.title)}</p>
                                                    <hr />
                                                    <Icon icon="it-chart-line" className="icon-sm mr-2" />{course.subTitle} <br />
                                                    <Icon icon="it-clock" className="icon-sm mr-2" /><b>Scadenza</b>: {dateHelper.toITDate(course.enrollDeadline)}<br />
                                                    {(course.price) && (<><Icon icon="it-card" className="icon-sm mr-2" /><b>Prezzo</b>: {course.price}<br /></>)}
                                                    {course.tagSchool && (<Chip className="m-1"><ChipLabel>Scuola {course.tagSchool}</ChipLabel></Chip>)}
                                                    {course.tagArea && (
                                                        <>
                                                            {course.tagArea.split(',').map((tag, j) => (
                                                                <Chip key={`tagArea-${j}`} className="m-1" color="primary" ><ChipLabel>{TAG_AREA[tag]}</ChipLabel></Chip>
                                                            ))}
                                                        </>
                                                    )}
                                                </div>
                                                <CardFooter >
                                                    <Button className="float-right" color="primary" outline>
                                                        Iscriviti
                                                    </Button>
                                                </CardFooter>
                                            </CardBody>
                                        </Card>
                                    </article>
                                </a>
                            </Col>
                        ))}
                    </Row>
                </>)
                : <BoxSuccess>La ricerca non ho prodotto alcun risultato. Cambia i filtri di ricerca.</BoxSuccess>
            }

        </>);
    }

    render() {

        const { loading } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);

        const courseListSection = this.renderCourseList();
        const header = this.renderHeader();

        const title = 'Diplomi di perfezionamento universitari annuali post laurea specifici';
        const image = '/img/courses/banner_plu.webp';

        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                    <meta property="og:image" content={image} />
                </Helmet>
                <section className="my-4">
                    <Container>
                        {header}
                        {courseListSection}
                    </Container>
                </section>
            </>
        );
    }
}
