import { MCheckbox, ValidatedForm } from "components/forms";
import { enrollmentService, networkErrorHelper, payloadBuilder } from "libs";
import React from "react";
import { Col, Row } from "design-react-kit";
import { FormTitleStep2 } from "./enroll60CFU/FormTitleStep2";
import { FormTitleStep2Configurator } from "./enroll60CFU/FormTitleStep2Configurator";
import { CertLingueStraniere2012 } from "./enroll60CFU/CertLingueStraniere2012";
import ReactHtmlParser from "react-html-parser";
import { DottoratoRicerca } from "./enroll60CFU/DottoratoRicerca";
import { MasterUniversitariAccademici } from "./enroll60CFU/MasterUniversitariAccademici";
import { DiplomaSpecPerfStCecilia } from "./enroll60CFU/DiplomaSpecPerfStCecilia";
import { ENROLLMENT_STEPS } from "const";

export class EnrollFillForm30CFUStep2 extends ValidatedForm {
  state = {
    _formRefTitleStep2: null,

    _formCertLingueStraniere2012: React.createRef(),
    _formDottoratoRicerca: React.createRef(),
    _formMasterUniversitariAccademici: React.createRef(),
    _formDiplomaSpecPerfStCecilia: React.createRef(),

    loading: false,
    titleStep2: "",
    titleStep2Active: [],
    validationSummary: "",
    currentCompetitionClass:
      this.props.enrollData.pluginConfig.competitionClass || "",
  };

  componentDidMount() {
    this.init();
  }

  init = () => {
    // prepare refs to form
    const formRefTitleStep2 = {
      LaureaTriennale: React.createRef(),
      DiplomaAccademicoPrimolivello: React.createRef(),
      LaureaVO: React.createRef(),
      LaureaLS: React.createRef(),
      LaureaLM_02: React.createRef(),
      LaureaLM_CU: React.createRef(),
      DiplomaAccademicoVecchioOrdinamentoO2lvl: React.createRef(),
    };

    const newState = {
      loading: false,
      _formRefTitleStep2: formRefTitleStep2,
    };

    this.setState(newState);
  };

  onChangeTitleStep2Active = (id, value) => {
    const { titleStep2Active } = this.state;

    if (value) {
      if (!titleStep2Active.includes(id)) {
        titleStep2Active.push(id);
      }
    } else {
      const index = titleStep2Active.indexOf(id);
      if (index > -1) {
        titleStep2Active.splice(index, 1);
      }
    }

    this.setState({ titleStep2Active: titleStep2Active });
  };

  onSubmit = () => {
    let validationList = [];
    let isValid = true;

    let qualifications = [];
    this.payload = payloadBuilder(this.state);

    //--- titleStep2 ---
    const {
      _formRefTitleStep2,
      _formCertLingueStraniere2012,
      _formDottoratoRicerca,
      _formMasterUniversitariAccademici,
      _formDiplomaSpecPerfStCecilia,
    } = this.state;

    // validate titleStep2
    for (let key in _formRefTitleStep2) {
      if (_formRefTitleStep2[key].current) {
        const checkValidation =
          _formRefTitleStep2[key].current.checkValidation();

        if (checkValidation === true) {
          const payload = _formRefTitleStep2[key].current.getPayload();
          qualifications.push(payload);
        } else {
          validationList.push(
            key + " : Verificare i dati evidenziati in rosso."
          );
          isValid = false;
        }
      }
    }

    // validate CertLingueStraniere2012
    if (_formCertLingueStraniere2012) {
      const checkValidation = _formCertLingueStraniere2012.current.onSubmit();
      if (!checkValidation) {
        validationList.push(
          "CERTIFICAZIONI LINGUISTICHE: Verificare i dati evidenziati in rosso."
        );
        isValid = false;
      } else {
        const payload = _formCertLingueStraniere2012.current.getPayload();
        qualifications.push(...payload);
      }
    }

    //validate DottoratoRicerca
    if (_formDottoratoRicerca) {
      const checkValidation = _formDottoratoRicerca.current.onSubmit();

      if (!checkValidation) {
        validationList.push(
          "Dottorato di ricerca: verificare i dati evidenziati in rosso."
        );
        isValid = false;
      } else {
        const payload = _formDottoratoRicerca.current.getPayload();
        qualifications.push(...payload);
      }
    }

    //validate MasterUniversitariAccademici
    if (_formMasterUniversitariAccademici) {
      const checkValidation =
        _formMasterUniversitariAccademici.current.onSubmit();
      const payload = _formMasterUniversitariAccademici.current.getPayload();

      if (!checkValidation) {
        validationList.push(
          "Master universitari: Verificare i dati evidenziati in rosso."
        );
        isValid = false;
      } else {
        qualifications.push(...payload);
      }
    }

    //validate DiplomaSpecPerfStCecilia
    if (_formDiplomaSpecPerfStCecilia) {
      const checkValidation = _formDiplomaSpecPerfStCecilia.current.onSubmit();
      const payload = _formDiplomaSpecPerfStCecilia.current.getPayload();

      if (!checkValidation) {
        validationList.push(
          "Accademia Nazionale Santa Cecilia: Verificare i dati evidenziati in rosso."
        );
        isValid = false;
      } else {
        qualifications.push(...payload);
      }
    }

    this.payload.qualifications = qualifications;

    if (isValid) {
      const payloadQualification = {
        idEnrollment: this.props.enrollData.idEnrollment,
        currentStep: ENROLLMENT_STEPS.FILL2,
        qualifications: qualifications,
      };

      console.log("payloadQualification", payloadQualification);

      //  invia al server (array di userEnrollmentQualification)
      function myFunction() {
        return new Promise((resolve, reject) => {
          enrollmentService
            .updateEnrollmentQualification(payloadQualification)
            .then((response) => {
              console.log("response", response);
              resolve();
            })
            .catch((errors) => {
              validationList.push(
                "Errore imprevisto nell'invio dei dati. Se il problema persiste contattare il supporto tecnico."
              );
              networkErrorHelper.notify(errors);
              isValid = false;
            });
        });
      }

      // Call the function and await the result
      myFunction()
        .then((response) => {
          console.log("response", response);
        })
        .catch((errors) => {
          validationList.push(
            "Errore imprevisto nell'invio dei dati. Se il problema persiste contattare il supporto tecnico."
          );
          networkErrorHelper.notify(errors);
          isValid = false;
        });
    }

    let validationSummary = "";

    if (validationList.length > 0) {
      validationSummary =
        "<ul><li>" + validationList.join("</li><li>") + "</li></ul>";
    }

    this.setState({ validationSummary });
    return isValid;
  };

  getPayload = () => {
    return this.payload;
  };

  //--- ---

  render() {
    const {
      loading,
      _formRefTitleStep2,
      _formCertLingueStraniere2012,
      _formDottoratoRicerca,
      _formMasterUniversitariAccademici,
      _formDiplomaSpecPerfStCecilia,
      validationSummary,
    } = this.state;
    if (loading) return <></>;

    return (
      <>
        <Row>
          <Col>
            <h6>TITOLI VALUTABILI</h6>
            <p>
              <span className="font-weight-bold">
                <b>
                  Ulteriori titoli di studio diversi dal titolo di accesso o da
                  quello utilizzato per conseguire il titolo di accesso.
                </b>
              </span>
              Laurea triennale o diploma accademico di primo livello: punti 2
              per ciascun titolo. Laurea di vecchio ordinamento, laurea
              specialistica, laurea magistrale, diploma accademico di vecchio
              ordinamento o diploma accademico di secondo livello: punti 3 per
              ciascun titolo.
              <br /> <i>Massimo 5 punti complessivi</i>
            </p>
            <p>
              <b>Clicca sul titolo e inserisci i dati</b>
            </p>

            {(FormTitleStep2Configurator.forms || []).map((item, idx) => {
              return (
                <div key={`titleStep2=${item.template}`}>
                  <MCheckbox
                    onChange={this.onChangeTitleStep2Active}
                    id={item.template}
                    name={item.template}
                    readOnly={false}
                    disabled={false}
                  >
                    <p>
                      <b> {item.label}</b>
                    </p>
                  </MCheckbox>
                  {this.state.titleStep2Active.includes(item.template) && (
                    <div className="m-4 p-3 border border-secondary rounded-lg">
                      <FormTitleStep2
                        key={`titleStep2=${item.template}`}
                        qualificationType={item.label}
                        template={item.template}
                        extCode={item.extCode}
                        ref={_formRefTitleStep2[item.template]}
                        idx={idx}
                        enrollData={this.props.enrollData}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <DottoratoRicerca
              ref={_formDottoratoRicerca}
              competitionClass={
                this.props.enrollData.pluginConfig.competitionClass
              }
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <MasterUniversitariAccademici
              ref={_formMasterUniversitariAccademici}
              competitionClass={
                this.props.enrollData.pluginConfig.competitionClass
              }
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <DiplomaSpecPerfStCecilia
              ref={_formDiplomaSpecPerfStCecilia}
              competitionClass={
                this.props.enrollData.pluginConfig.competitionClass
              }
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <CertLingueStraniere2012
              ref={_formCertLingueStraniere2012}
              competitionClass={
                this.props.enrollData.pluginConfig.competitionClass
              }
            />
          </Col>
        </Row>
        {/*
        <hr />
        <Row>
          <Col>
            <CertFrequenzaPercorsoFormazIniziale
              ref={_formCertFrequenzaPercorsoFormazIniziale}
              competitionClass={
                this.props.enrollData.pluginConfig.competitionClass
              }
            />
          </Col>
        </Row>
*/}
        <Row>
          <div className="w-100">
            {validationSummary && (
              <div className="alert alert-danger mt-3" role="alert">
                {ReactHtmlParser(validationSummary)}
              </div>
            )}
          </div>
        </Row>
      </>
    );
  }
}
