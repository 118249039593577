import React from "react";
import { MInput, ValidatedForm } from "components/forms";
import { payloadBuilder } from "libs";

export class VincitoreProvaBis extends ValidatedForm {

    emptyFields = {
        description: "",
        ufficioScolastico: "",
        dataConseguimento: "",
    };

    state = {
        ...this.emptyFields,
        defaultValues: {
            ...this.emptyFields,
        },
    };

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    ERROR_MESSAGES = {
        description: "Valore obbligatorio",
        dataConseguimento: "Valore obbligatorio",
        ufficioScolastico: "Valore obbligatorio",
    };

    FIELDS = [
        [
            {
                id: "description",
                field: "description",
                label: "Riferimento concorso",
                component: MInput,
                type: "text",
                className: "col-md-12",
            },
        ],
        [{
            id: "ufficioScolastico",
            field: "ufficioScolastico",
            label: "Ufficio Scolastico Regionale/Provinciale di competenza",
            component: MInput,
            type: "text",
            className: "col-md-12",
        }],
        [
            {
                id: "dataConseguimento11",
                field: "dataConseguimento",
                label: "Data concorso",
                component: MInput,
                type: "date",
                className: "col-md-4 pt-3",
            }
        ]
    ]

    validation = {
        description: (value) => value !== "",
        ufficioScolastico: (value) => value !== "",
        dateConseguimento: (value) => value !== "",
    };

    getPayload = () => {
        this.payload = payloadBuilder(this.state);
        this.payload.qualificationType = this.props.qualificationType;
        this.payload.qualificationCode = this.props.qualificationCode;
        this.payload.template = "Title14Item_CONCORSUALE_BIS";
        this.payload.extCode ="PROVA_CONCORSUALE_BIS";
          
        return this.payload;
      };
    
      render(){
        const { defaultValues } = this.state;
        const { code } = this.renderFields(this.FIELDS, defaultValues, true);

        return <>{code}</>;
      }
}
