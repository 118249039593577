import React, { Component } from "react";
import { courseService, dateHelper, networkErrorHelper } from "libs";

import {
    Row, Col, Container, CardBody, CardFooter,
    AccordionHeader, AccordionBody, Button, Icon, Card, Chip, ChipLabel
} from "design-react-kit";

import { BoxSuccess, MnemoLoading, MSection } from "components/misc";
import { ROUTES } from "const";
import { MSearch } from "components/forms";
import ReactHtmlParser from 'react-html-parser';
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";


class CourseBrowserSSD extends Component {

    state = {

        loading: true,

        currentSSD: [-1],
        currentWord: [-1],
        currentTagClass: null,

        payloadSSD: [],
        payloadWord: [],
        payloadClasses: [],

        courses: [],
        courseFilter: false,
        courseFiltered: [],
        courseTypes: [],

        tagClass: null,
        mapSSD: [],
        classSSD: [],
        mapWord: [],

        filterSSDOpen: false,
        filterWordOpen: false,
    };

    componentDidMount() {

        // verify if a url param exists            
        this.loadTags();
        this.loadCourses();

        const { classCode } = this.props.match.params;
        if (classCode) {
            const value = { value: classCode };
            setTimeout(() => {
                this.changeTagClass(1, value);
                this.setState({ filterClassOpen: true, selectedClass: classCode });
            }, 1500);
        }
    }

    toggleFilter = id => {

        switch (id) {

            case 'filterSSDOpen':
                this.setState({ filterClassOpen: false, filterSSDOpen: true, filterWordOpen: false });
                this.resetAll();
                break;

            case 'filterWordOpen':
                this.setState({ filterClassOpen: false, filterSSDOpen: false, filterWordOpen: true });
                this.resetAll();
                break;

            case 'filterClassOpen':
                this.setState({ filterClassOpen: true, filterSSDOpen: false, filterWordOpen: false });
                this.resetAll();
                break;

            default:
        };
    }

    loadTags() {

        courseService
            .lisTags('MU')
            .then(({ data }) => {

                const tagClass = [];
                const payloadClasses = [];

                data.payload.classes.forEach(itemClass => {

                    if (!tagClass.hasOwnProperty(itemClass.codeClass)) {
                        tagClass[itemClass.codeClass] = [];
                        payloadClasses.push({ label: itemClass.codeClass + ' - ' + itemClass.description, value: itemClass.codeClass });
                    }

                    tagClass[itemClass.codeClass].push(itemClass.codeCourse);
                });

                this.setState({
                    payloadClasses,
                    tagClass,
                    courseTypes: data.payload.types
                });
            })
            .catch(error => {
                console.log(error);
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
            });

        // load all SSD 
        courseService.listSSD('MU%')
            .then(({ data }) => {

                const payloadSSD = [];
                const mapSSD = [];
                const classSSD = [];

                data.payload.forEach(element => {
                    mapSSD[element.ssd] = element.courseCode;
                    payloadSSD.push({ label: element.ssd, value: element.ssd });

                    const courseCodes = element.courseCode.split(',');
                    courseCodes.forEach(code => {
                        if (!classSSD[code]) {
                            classSSD[code] = [];
                        }
                        classSSD[code].push(element.ssd);
                    });
                });

                this.setState({
                    payloadSSD,
                    mapSSD,
                    classSSD
                });
            })
            .catch(error => {
                console.log(error);
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
            });

        // load all SSD 
        courseService.listWord('MU%')
            .then(({ data }) => {

                const payloadWord = [];
                const mapWord = [];

                data.payload.forEach(element => {
                    mapWord[element.moduleTitle] = element.courseCode;
                    payloadWord.push({ label: element.moduleTitle, value: element.moduleTitle });
                });

                this.setState({
                    payloadWord,
                    mapWord
                });
            })
            .catch(error => {
                console.log(error);
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
            });
    }

    loadCourses() {

        courseService
            .listAllMaster()
            .then(({ data }) => {

                const { courses } = data.payload;

                if (courses) {
                    this.setState({ courses, loading: false });
                }
            })
            .catch(error => {
                console.log(error);
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false });
            });
    }

    openCourse(code) {

        window.open(`${ROUTES.COURSE_LANDINGPAGE}\\${code}`, '_blank');
    }

    changeTagClass = (_, value) => {

        const newTagClass = (value) ? value.value : '';
        let courseFiltered = this.state.courseFiltered;
        courseFiltered = [];

        if (newTagClass) {

            const courseClasses = this.state.tagClass[newTagClass];
            courseFiltered = this.state.courses.filter(item => {
                return (courseClasses.includes(item.code));
            });

            this.setState({ courseFilter: true, courseFiltered, currentTagClass: newTagClass });

        } else {

            courseFiltered = [];
            this.setState({ courseFilter: false, courseFiltered, currentTagClass: newTagClass });

        }
    }

    changeSSD = (i, value) => {

        let currentSSD = []
        if (value && value.length > 0) {
            currentSSD = value.map((item, i) => {
                return item.value;
            });
        }

        this.setState({ currentSSD, selectedSSD: value });
    }

    filterResetSSD() {

        let { currentSSD } = this.state;
        currentSSD = [-1];
        this.setState({ currentSSD, selectedSSD: null, courseFilter: false });
    }

    resetAll() {
        let { currentSSD, currentWord, currentTagClass } = this.state;
        currentSSD = [-1];
        currentWord = [-1];
        currentTagClass = '';

        this.setState({
            currentSSD, currentWord, currentTagClass,
            selectedSSD: null, selectedWord: null,
            courseFilter: false
        });
    }

    filterCourseSSD() {
        const { currentSSD, mapSSD, courses } = this.state;

        let courseFiltered = this.state.courseFiltered;
        let courseFilter = false;

        courseFiltered = [];
        currentSSD.forEach(ssd => {
            if (ssd !== -1) {
                courseFilter = true;
                const codes = mapSSD[ssd];
                const courseCodes = codes.split(',');
                courseCodes.forEach(code => {

                    const coursesFilteredFound = courseFiltered.filter(item => (item.code === code));
                    if (coursesFilteredFound.length > 0) {
                        coursesFilteredFound[0].tagSSD.push(ssd);
                    } else {
                        const coursesFound = courses.filter(item => (item.code === code));
                        if (coursesFound.length > 0) {
                            let course = coursesFound[0];
                            course.tagSSD = [];
                            course.tagWord = [];
                            course.tagSSD.push(ssd);
                            courseFiltered.push(course);
                        }
                    }
                })

            }
        });

        courseFiltered = courseFiltered.sort((a, b) => { return (b.tagSSD.length - a.tagSSD.length) });
        this.setState({ courseFilter, courseFiltered });
    }

    renderSSDFilter = () => {

        const { payloadSSD, selectedSSD } = this.state;

        return (<>
            <MSearch
                key="ssd-select"
                field="ssd-select"
                id="ssd-select"
                name="ssd-select"
                placeHolder="Inserisci o seleziona SSD"
                payload={payloadSSD}
                isMulti="true"
                className="mx-n4"
                selectedOption={selectedSSD}
                onChange={this.changeSSD}
            />
            <footer className="blockquote-footer mt-2">I Master proposti da questo filtro sono solo consigliati!</footer>
            <hr />
            <Button className="bg-dark float-right"
                onClick={e => this.filterCourseSSD()}
            >Avvia ricerca</Button>
            <Button outline className="float-right mr-2"
                onClick={e => this.filterResetSSD()}
            >Cancella filtro</Button>
        </>);
    }

    changeWord = (i, value) => {

        let currentWord = []
        if (value && value.length > 0) {
            currentWord = value.map((item, i) => {
                return item.value;
            });
        }

        this.setState({ currentWord, selectedWord: value });
    }


    filterResetWord() {

        let { currentWord } = this.state;
        currentWord = [-1];
        this.setState({ currentWord, selectedWord: null, courseFilter: false });
    }

    filterCourseWord() {

        const { currentWord, mapWord, courses } = this.state;
        let courseFiltered = this.state.courseFiltered;
        let courseFilter = false;

        courseFiltered = [];

        currentWord.forEach(word => {
            if (word !== -1) {
                const codes = mapWord[word];
                const courseCodes = codes.split(',');
                if (courseCodes && courseCodes.length > 0) {
                    courseFilter = true;

                    courseCodes.forEach(code => {

                        const coursesFilteredFound = courseFiltered.filter(item => (item.code === code));
                        if (coursesFilteredFound.length > 0) {
                            coursesFilteredFound[0].tagWord.push(word);
                        } else {
                            const coursesFound = courses.filter(item => (item.code === code));
                            if (coursesFound.length > 0) {
                                let course = coursesFound[0];
                                course.tagWord = [];
                                course.tagSSD = [];
                                course.tagWord.push(word);
                                courseFiltered.push(course);
                            }
                        }
                    })
                }

            }
        });

        courseFiltered = courseFiltered.sort((a, b) => { return (b.tagWord.length - a.tagWord.length) });
        this.setState({ courseFilter, courseFiltered });
    }

    renderWordFilter = () => {

        const { payloadWord, selectedWord } = this.state;

        return (<>
            <MSearch
                key="word-select"
                field="word-select"
                id="word-select"
                placeHolder="Inserisci o seleziona Materia"
                name="word-select"
                payload={payloadWord}
                className="mx-n4"
                isMulti="true"
                selectedOption={selectedWord}
                onChange={this.changeWord}
            />
            <footer className="blockquote-footer mt-2">I Master proposti da questo filtro sono solo consigliati!</footer>
            <hr />
            <Button className="bg-dark float-right"
                onClick={e => this.filterCourseWord()}
            >Avvia ricerca</Button>
            <Button outline className="float-right mr-2"
                onClick={e => this.filterResetWord()}
            >Cancella filtro</Button>
        </>);
    }

    renderClassFilter = () => {

        const { payloadClasses, selectedClass } = this.state;
        const placeHolder = selectedClass ? selectedClass : "Inserisci o seleziona classe";

        return (<>
            <MSearch
                key="class-select"
                field="class-select"
                id="class-select"
                name="class-select"
                placeHolder={placeHolder}
                payload={payloadClasses}
                className="mx-n4"
                onChange={this.changeTagClass}
            />
            <footer className="blockquote-footer mt-2">I Master proposti da questo filtro sono solo consigliati!</footer>
        </>);
    }

    renderFilterSection() {

        const { filterSSDOpen, filterWordOpen, filterClassOpen } = this.state;

        const ssd = filterSSDOpen ? this.renderSSDFilter() : <></>;
        const word = filterWordOpen ? this.renderWordFilter() : <></>;
        const classes = filterClassOpen ? this.renderClassFilter() : <></>;

        return (
            <>
                <MSection className="mb-3">
                    <Row>
                        <Col size="12" >
                            <AccordionHeader active={filterClassOpen} onToggle={() => this.toggleFilter('filterClassOpen')}>
                                <h6>Ricerca Master consigliati per Classe di Concorso</h6>
                            </AccordionHeader>
                            <AccordionBody active={filterClassOpen}>
                                {classes}
                            </AccordionBody>
                        </Col>
                    </Row>
                    <Row>
                        <Col size="12" md="6" >
                            <AccordionHeader active={filterSSDOpen} onToggle={() => this.toggleFilter('filterSSDOpen')}>
                                <h6>Ricerca Master per SSD</h6>
                                <small className="text-muted" >Inserisci uno o più SSD e poi avvia la ricerca</small>
                            </AccordionHeader>
                            <AccordionBody active={filterSSDOpen}>
                                {ssd}
                            </AccordionBody>
                        </Col>
                        <Col size="12" md="6">
                            <AccordionHeader active={filterWordOpen} onToggle={() => this.toggleFilter('filterWordOpen')}>
                                <h6>Ricerca Master per Materia</h6>
                                <small className="text-muted" >Inserisci uno o più Materie e poi avvia la ricerca</small>
                            </AccordionHeader>
                            <AccordionBody active={filterWordOpen}>
                                {word}
                            </AccordionBody>
                        </Col>
                    </Row>
                </MSection>
            </>);
    }

    renderHeader(courseType) {
        if (courseType.length == 0) { return; }
        return (
            <>
                <div className="image-banner">
                    <img className="w-100" src="/img/courses/banner_mr2.webp"
                        alt="Corsi Singoli Universitari strutturati in Master " />
                </div>
                {/* <div className="m-5"> */}
                <div className="m-3 mt-5 m-lg-5 m-sm-3">
                    {ReactHtmlParser(courseType[0].summary)}
                </div>
            </>);
    }

    renderCourseList() {

        const { courses, courseFiltered, courseFilter, currentTagClass, classSSD } = this.state;
        let toShow = (courseFilter) ? courseFiltered : courses;

        return (
            <>
                {(toShow.length > 0)
                    ?
                    (<Row>
                        {toShow.map((course, i) => (
                            <Col md={6} lg={4} key={`course-${i}`} >
                                <a className="no-pointer" href={`${ROUTES.COURSE_LANDINGPAGE}\\${course.code}`} target="_blank" rel="noopener noreferrer">
                                    <article
                                        className='card-wrapper card-space pointer'  >
                                        <Card noWrapper className="card-bg card-img shadow">
                                            <CardBody>
                                                <div className="box-250">
                                                    <p className="width-full text-center text-green m-0"><b>{course.label}</b></p>
                                                    <p className="text-green">
                                                        {ReactHtmlParser(course.title)}</p>
                                                    <hr />
                                                    <Icon icon="it-chart-line" className="icon-sm mr-2" />{course.subTitle} <br />
                                                    {course.enrollDeadline && (<><Icon icon="it-clock" className="icon-sm mr-2" /><b>Scadenza</b>: {dateHelper.toITDate(course.enrollDeadline)} <br /></>)}
                                                    {(course.price) && (<><Icon icon="it-card" className="icon-sm mr-2" /><b>Prezzo</b>: {course.price}<br /></>)}
                                                    {(course.tagSSD && courseFilter) && (
                                                        <>
                                                            {course.tagSSD.map((tag, j) => (
                                                                <Chip key={`tagSSD-${j}`} className="m-1" color="primary" ><ChipLabel>{tag}</ChipLabel></Chip>
                                                            ))}
                                                        </>
                                                    )}
                                                    {(course.tagWord && courseFilter) && (
                                                        <>
                                                            {course.tagWord.map((tag, j) => (
                                                                <Chip key={`tagWord-${j}`} className="m-1" color="primary" ><ChipLabel>{tag}</ChipLabel></Chip>
                                                            ))}
                                                        </>
                                                    )}
                                                    {(currentTagClass) && (<>
                                                        {classSSD[course.code].map((tag, j) => (
                                                            <Chip key={`tagWord-${j}`} className="m-1" color="primary" ><ChipLabel>{tag}</ChipLabel></Chip>
                                                        ))}
                                                    </>)
                                                    }
                                                </div>
                                                <CardFooter >
                                                    <Button className="float-right" color="primary" outline>
                                                        Iscriviti
                                                    </Button>
                                                </CardFooter>
                                            </CardBody>
                                        </Card>
                                    </article>
                                </a>
                            </Col>
                        ))}
                    </Row>)
                    : <BoxSuccess>La ricerca non ho prodotto alcun risultato. Cambia i filtri di ricerca.</BoxSuccess>
                }
            </>
        );
    }

    render() {

        const { loading } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);

        const title = "Corsi singoli strutturati in master di primo livello";
        const courseListSection = this.renderCourseList();
        const filterSidebar = this.renderFilterSection();
        const header = this.renderHeader(this.state.courseTypes);

        return (
            <section className="my-4">
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                    <meta property="og:image" content='/img/courses/banner_mr2.webp' />
                </Helmet>

                <Container>
                    {header}
                    {filterSidebar}
                    {courseListSection}
                </Container>
            </section>
        );
    }
}

export default withRouter(CourseBrowserSSD);