import React from "react";
import {
  MButton2,
  MInput,
  MSearchUniversity,
  MSelect,
  ValidatedForm,
} from "components/forms";
import { payloadBuilder } from "libs";
import { FormTitleStep2Configurator } from "./FormTitleStep2Configurator";

export class DottoratoRicercaForm extends ValidatedForm {
  FIELDS_GROUP = [
    [
      {
        id: "searchUniversity" + this.props.idx,
        component: MButton2,
        onClick: () => {
          this.searchUniversity();
        },
        label: "Cerca Ateneo",
        className: "col-md-12 mb-3",
        classElement: "btn btn-primary bg-dark",
      },
    ],
    [
      {
        id: "searchSchool" + this.props.idx,
        field: "istitution",
        label: "Presso",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
      {
        id: "searchSchool" + this.props.idx,
        field: "city",
        label: "Città",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
    ],
    [
      {
        id: "description002" + this.props.idx,
        field: "description",
        label: "Denominazione del Dottorato",
        component: MInput,
        type: "text",
        className: "col-md-12",
      },
    ],
    [
      {
        id: "dataConseguimento002" + this.props.idx,
        field: "dataConseguimento",
        label: "Data conseguimento titolo",
        component: MInput,
        type: "date",
        className: "col-md-6 pt-3",
      },
      {
        id: "aa002" + this.props.idx,
        field: "aa",
        label: "Anno Accademico conseguimento Titolo",
        component: MInput,
        infoText: "inserire formato AAAA/AAAA",
        className: "col-md-6 pt-3",
      },
    ],
    [
      {
        id: "ciclo002" + this.props.idx,
        field: "ciclo",
        label: "Ciclo",
        payload: {
          options: [
            { label: "Seleziona un ciclo", value: "" },
            { label: "1°", value: "1°" },
            { label: "2°", value: "2°" },
            { label: "3°", value: "3°" },
            { label: "4°", value: "4°" },
            { label: "5°", value: "5°" },
            { label: "6°", value: "6°" },
            { label: "7°", value: "7°" },
            { label: "8°", value: "8°" },
            { label: "9°", value: "9°" },
            { label: "10°", value: "10°" },
            { label: "11°", value: "11°" },
            { label: "12°", value: "12°" },
            { label: "13°", value: "13°" },
            { label: "14°", value: "14°" },
            { label: "15°", value: "15°" },
            { label: "16°", value: "16°" },
            { label: "17°", value: "17°" },
            { label: "18°", value: "18°" },
            { label: "19°", value: "19°" },
            { label: "20°", value: "20°" },
            { label: "21°", value: "21°" },
            { label: "22°", value: "22°" },
            { label: "23°", value: "23°" },
            { label: "24°", value: "24°" },
            { label: "25°", value: "25°" },
            { label: "26°", value: "26°" },
            { label: "27°", value: "27°" },
            { label: "28°", value: "28°" },
            { label: "29°", value: "29°" },
            { label: "30°", value: "30°" },
            { label: "31°", value: "31°" },
            { label: "32°", value: "32°" },
            { label: "33°", value: "33°" },
            { label: "34°", value: "34°" },
            { label: "35°", value: "35°" },
            { label: "36°", value: "36°" },
            { label: "37°", value: "37°" },
            { label: "38°", value: "38°" }
          ],
        },
        component: MSelect,
        className: "col-md-4 pt-3",
      },
    ],
  ];

  ERROR_MESSAGES = {
    istitution: "Inserire l'Istituzione universitaria o accademica",
    city: "Inserire l'Istituzione universitaria o accademica",
    description: "Inserire la denominazione del Dottorato",
    dataConseguimento: "Inserire la data di conseguimento del titolo",
    aa: "Inserire l'anno accademico di conseguimento del titolo",
    ciclo: "Selezionare il Ciclo",
  };

  validation = {
    istitution: (value) => value !== "",
    city: (value) => value !== "",
    description: (value) => value !== "",
    dataConseguimento: (value) => this.validateDataConseguimento(value),
    aa: (value) => this.validateAA(value),
    ciclo: (value) => value !== "",
  };

  validateAA = (value) => {
    let aa = /^(\d{4})\/(\d{4})$/.test(value);

    if (!aa) {
      return false;
    } else {
      aa = value.split("/");

      if (parseInt(aa[1]) > new Date().getFullYear()) {
        return false;
      }

      if (parseInt(aa[1]) === parseInt(aa[0]) + 1) {
        return true;
      } else {
        return false;
      }
    }
  }; //validateAA

  validateDataConseguimento = (value) => {
    if (value === "") {
      return false;
    }
    let regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!value.match(regex)) {
      return false;
    }

    //--- ---
    const today = new Date();
    const date = new Date(value);

    if (date > today) {
      return false;
    }

    if (Number.isNaN(date.getTime())) {
      return false;
    }
    const [year, month, day] = value.split("-").map(Number);

    if (month > 12) {
      return false;
    }
    if (day > 31) {
      return false;
    }

    const currentYear = new Date().getFullYear();
    if (year < currentYear - 90 || year > currentYear) {
      return false;
    }
    return true;
  };

  emptyFields = {
    istitution: "",
    city: "",
    description: "",    
    dataConseguimento: "",
    aa: "",
    ciclo: "",
  };

  state = {
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields,
    },
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  searchUniversity = () => {
    this.setState({ showSearchUniversity: !this.state.showSearchUniversity });
  };

  onChangeUniversity = (university) => {
    this.onChange("city", university.city);
    this.onChange("istitution", university.istitution);
    this.setState({ showSearchUniversity: false, university: university });
  };

  getPayload = () => {

    this.payload = payloadBuilder(this.state);
    this.payload.qualificationType = "Dottorato di ricerca";
    this.payload.qualificationCode = "title21";
    this.payload.nation = "Italia";
    this.payload.template = "DottoratoRicercaItem";
    this.payload.extCode =
      FormTitleStep2Configurator.getExtCode("DottoratoRicerca");

    if (this.state.university) {
      this.payload.uniCode = this.state.university.uniCode;
    }

    return this.payload;
  };

  render() {
    const { defaultValues, showSearchUniversity } = this.state;
    const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

    return (
      <>
        {showSearchUniversity ? (
          <>
            <MSearchUniversity
              onChange={this.onChangeUniversity}
              searchUniversity={this.searchUniversity}
              isForeign={false}
            />
          </>
        ) : (
          <>{code}</>
        )}
      </>
    );
  }
}
