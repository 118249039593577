import React, { Component } from "react";
import { adminService, networkErrorHelper } from "libs";
import { MTable } from "components/table";
import { Container } from 'design-react-kit';
import { BoxDanger } from 'components/misc';

import { withRouter } from 'react-router-dom';

import {
  MToggle,
} from 'components/forms';

import { NavLink } from 'react-router-dom';
import { ROUTES } from 'const';

import {
  MnemoLoading,
  ModalHandler
} from "components/misc";

import ReactHtmlParser from 'react-html-parser';

class LibraryBookManager extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      payload: [],
      payloadToRender: [],
      isVisible: true,
      searchFilter: {
        searchFilter: {
          isVisible: true,
          mainFilter: this.props.match.params.mainFilter,
          mainFilterId: parseInt(this.props.match.params.idLibraryBook) || 0
        }
      },
      rndKey: 0,
    }
  }

  //--- ---
  tableConfig = {
    columns: [
      {
        id: 'id', // identifier of columns
        Header: 'Column 0',
        isVisible: false, // IMPORTANT: hidden columns
        accessor: 'id', // accessor is the "key" in the data

      },
      {
        id: 'title',
        Header: 'Titolo',
        accessor: 'title',
      }
    ],
    actions: [
      {
        id: 'detail',
        accessor: 'id',
        label: 'Dettagli',
        icon: 'it-file',
        className: 'MTable-actions',
        onClick: (e, value) => {
          window.location.href = ROUTES.LIBRARY_BOOK_FORM + '/' + value;
        }
      },
      {
        id: 'delete',
        accessor: 'id', // field value received in the onclick event
        label: 'Elimina',
        icon: 'it-delete',
        className: 'MTable-actions',
        onClick: (e, value) => { this.deleteRecord(e, value) }
      }
    ]
  }


  //--- ---


  componentDidMount() {
    this.loadAllLibraryBook(this.state.searchFilter);
  }

  //--- start functions to delete procedure ---
  deleteRecord(e, value) {
    ModalHandler.show(value, 'Eliminazione definitiva del dato',
      'vuoi davvero eliminare?', null, this.deleteConfirm);
  }


  deleteConfirm = (value) => {
    adminService.deleteLibraryBook(value).then(({ data }) => {
      this.setState({ loading: true });
      this.loadAllLibraryBook(this.state.searchFilter);
    })
      .catch(error => {
        console.log(error);
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }
      });
  }
  //--- end functions to delete procedure ---


  loadAllLibraryBook(filter) {

    // check allowed filter by idparent
    if (parseInt(this.props.idLibraryBook) > 0) {
      filter = { searchFilter: { ...filter.searchFilter } };
    }

    adminService.loadAllLibraryBook(filter).then(({ data }) => {

      //--- start: prepare reactTable ---
      const items = [];
      Object.entries(data.payload.libraryBooks).forEach(([key, val]) => {

        val.isVisible = (val.isVisible) ? 'visibile' : 'non visibile';

        items.push(val);
      });
      //--- end: prepare reactTable ---

      const parseItems = items.map((item) => {
        return { ...item, name: ReactHtmlParser(item.fullName) }
      })

      this.setState({
        loading: false,
        payload: items,
        payloadToRender: parseItems,
        rndKey: Math.floor((Math.random() * 1000) + 1)
      });
    })
      .catch(error => {
        //console.log(error);
        this.setState({ loading: false });
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        } else {
          this.setState({
            payload: [],
            payloadToRender: [],
            rndKey: Math.floor((Math.random() * 1000) + 1)
          })
        }
      });
  }

  onChangeFilterisVisible = () => {
    let isVisible = !this.state.isVisible;

    this.setState({
      isVisible,
      searchFilter: {
        searchFilter: {
          isVisible
        }
      }
    }, () => {
      this.loadAllLibraryBook(this.state.searchFilter);
    });
  }//onChangeFilterisVisible

  //--- ---

  render() {

    const { loading } = this.state;

    return (
      <>
        <div className="container mt-8 mb-4 mt-4">
          {(parseInt(this.props.idLibraryBook) === 0 || isNaN(parseInt(this.props.idLibraryBook))) && (
            <section className="m-0">
              <div className="form-row m-0 p-0">
                <div className="col-md-10">
                  <div className="col-md-5">
                    <MToggle
                      id='isVisible'
                      name='isVisible'
                      label="Non Attivi/Attivi"
                      value={this.state.isVisible}
                      disabled={false}
                      onChange={this.onChangeFilterisVisible}
                    />
                  </div>
                </div>

                <div className="col-md-2 text-right">
                  <NavLink to={ROUTES.LIBRARY_BOOK_FORM} className="btn btn-primary bg-dark pb-2">Aggiungi Libro</NavLink>
                </div>
              </div>
            </section>
          )}

          <hr />

          <section className="my-4">
            {loading ? (<MnemoLoading></MnemoLoading>) : (
              <Container>
                <MTable
                  key={this.state.rndKey}
                  tableData={this.state.payloadToRender}
                  tableConfig={this.state.searchFilter.searchFilter.deleted ? this.tableConfigDelete : this.tableConfig}
                />
              </Container>
            )}

            {(this.state.payload.length === 0) && (<BoxDanger key="nrp-1" className="my-3">
              Libri presenti in Libreria.
              <div className="small text-info">
                Nessun dato presente.
              </div>
            </BoxDanger>)}
          </section>
        </div>
      </>
    );
  }
}

export default withRouter(LibraryBookManager);